import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import trimChars from "lodash/fp/trimChars";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import { FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL, FOLDER_TYPE_CUSTOM, FOLDER_TYPE_RECYCLE_BIN } from "utils/enums/DocumentFolderTypes";
import { sortTextFieldCaseInsensitive, sortBooleanField } from "utils/sort";
import Breadcrumbs from "atlas/components/Breadcrumbs/Breadcrumbs";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import { resetPageConfigs, updatePageConfigs, updateLeftNav } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { endsWithAllowedExtension, getAllowedAccept } from "utils/allowedExtensions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { getScrollParent } from "utils/scrollToElement";
import DocumentsTopBar from "./components/DocumentsTopBar";
import DocumentList from "./components/DocumentList";
import UploadDialog from "./components/UploadDialog";
import AddFolderDialog from "./components/AddFolderDialog";
import RenameDialog from "./components/RenameDialog";
import DocumentDeleteDialog from "./components/DocumentDeleteDialog";
import DocumentRestoreDialog from "./components/DocumentRestoreDialog";
import DocumentPermanentlyDeleteDialog from "./components/DocumentPermanentlyDeleteDialog";
import getPathType from "./utils/pathType";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { getPathForEditPage } from "../Documents/utils/getPathForEditPage";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import AddNewDocumentDialog from "./components/AddNewDocumentDialog";

const useStyles = makeStyles({
	breadcrumbs: {
		height: "40px",
		padding: "0 8px",
	},
	fileInput: {
		display: "none",
	},
	overlayLoader: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100vw",
		height: "100vh",
		background: "rgb(111,111,111,0.5)",
		zIndex: 9,
	},
	loader: {
		zIndex: 10,
	},
});

const telemetryPage = "Document List";

const sortDefault = (a, b) => {
	const folderSort = sortBooleanField(a, b, "folder", true);

	return folderSort !== 0 ? folderSort : sortTextFieldCaseInsensitive(a, b, "title");
};
const getSortMethod = (field, descending) => {
	let method = sortDefault;
	switch (field) {
		case "title":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "title", descending);
			break;

		case "dateModified":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "dateModified", descending);
			break;

		case "modifiedBy":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "modifiedBy", descending);
			break;

		case "dateDeleted":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "dateDeleted", descending);
			break;

		case "deletedBy":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "deletedBy", descending);
			break;
	}

	return method;
};

const ROOT_ID = "root";

const DocumentsModule = (props) => {
	const { type = FOLDER_TYPE_PUBLIC, showSignIn } = props;
	const { params: { id: publicId } = {} } = useMatch({ path: "/documents/public/:id", end: true }) || {};
	const { params: { id: internalId } = {} } = useMatch({ path: "/documents/internal/:id", end: true }) || {};
	const { params: { id: customId } = {} } = useMatch({ path: "/documents/custom/:id", end: true }) || {};
	const { params: { id: recycleBinId } = {} } = useMatch({ path: "/documents/recyclebin/:id", end: true }) || {};
	const id = publicId || internalId || customId || recycleBinId;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [documents, setDocuments] = useState(null);
	const [path, setPath] = useState(null);
	const [permissions, setPermissions] = useState(null);
	const [order, setOrder] = useState({});
	const [dialogs, setDialogs] = useState({});
	const [focusGuid, setFocusGuid] = useState(null);
	const [enableRecycleTopBar, setEnableRecycleTopBar] = useState(false);
	const [navigateNewDocument, setNavigateNewDocument] = useState(false);
	const input = useRef(null);
	const dispatch = useDispatch();
	const classes = useStyles();
	const isRecycleBin = type === FOLDER_TYPE_RECYCLE_BIN;

	const [isNewDocumentModalOpen, setDocumentModalStatus] = useState(false);
	const [documentDetails, setDocumentDetails] = useState({
		name: "",
	});
	const toggleNewDocumentModal = () => setDocumentModalStatus(!isNewDocumentModalOpen);

	const backToParent = useCallback(() => {
		if (path && path.length > 1 && path[path.length - 2].url) {
			navigate(path[path.length - 2].url);
		}

		return true;
	}, [path]);

	const setTitle = useCallback(
		(title) => {
			dispatch(
				updatePageConfigs({
					title,
					telemetryPage,
					back: path && path.length > 1 ? { action: backToParent } : undefined,
				}),
			);
		},
		[path],
	);

	const loadDocuments = (parentId, root, parent) => {
		request
			.get(`${API_HOST}/api/documents`)
			.query({ id: parentId, type })
			.then((res) => {
				// Set selections
				if (type === FOLDER_TYPE_RECYCLE_BIN && parent) {
					res.body.forEach((document) => {
						document.selected = parent.selected;
					});
				}

				setDocuments((prev) => {
					// Check if the page has changed. If it has make no changes to the documents
					if (prev.folder.id !== id || prev.folder.type !== type) {
						return prev;
					}

					const newDocuments = root ? { folder: prev.folder } : { ...prev };
					newDocuments[root || !parentId ? "root" : parentId] = res.body.sort(getSortMethod(order.field, order.descending));

					return newDocuments;
				});
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadDocuments(parentId, root, parent);
				});
			});
	};

	const loadPath = () => {
		const pathType = getPathType(type);
		const rootName = t(`title.${type === FOLDER_TYPE_PUBLIC ? "public" : type === FOLDER_TYPE_INTERNAL ? "internal" : "recycleBin"}`);
		if (!id) {
			// Root folder
			setPath([
				{
					name: rootName,
				},
			]);
		} else {
			request
				.get(`${API_HOST}/api/document/${id}/path`)
				.then((res) => {
					setPath(
						res.body.map((path, index) =>
							index === 0 && type !== FOLDER_TYPE_CUSTOM
								? {
										name: rootName,
										url: `/documents/${pathType}`,
									}
								: {
										name: path.title,
										url: index < res.body.length - 1 ? `/documents/${pathType}/${path.guid}` : undefined,
									},
						),
					);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadPath();
					});
				});
		}
	};

	const loadPermissions = () => {
		request
			.get(`${API_HOST}/api/document/${id || 0}/permissions`)
			.query({ type })
			.then((res) => {
				setPermissions(res.body);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadPermissions();
				});
			});
	};

	const addDocument = useCallback(
		(document, parentGuid) => {
			setDocuments((prev) => {
				const parent = document.parentGuid || parentGuid;
				const folderGuid = !parent || parent === id ? ROOT_ID : typeof prev[parent] !== "undefined" ? parent : null;

				if (folderGuid) {
					// Add new document or folder to list without needing full reload
					prev[folderGuid].push(document);
					prev[folderGuid].sort(getSortMethod(order.field, order.descending));
				}

				return folderGuid ? { ...prev } : prev; // Only update state if the list changed
			});
		},
		[id, order],
	);
	const getIdForNewDoc = () => {
		if (!id && (type === FOLDER_TYPE_INTERNAL || type === FOLDER_TYPE_PUBLIC)) return 0;
		else return id;
	};

	const onClickNewDocument = () => {
		setNavigateNewDocument(true);
		request
			.post(`${API_HOST}/api/document/${getIdForNewDoc()}/create`)
			.query({ type })
			.withCredentials()
			.send({ ...documentDetails })
			.then((res) => {
				if (res.body.guid !== null) {
					setNavigateNewDocument(false);
					navigate(`/document/edit/${res.body.guid}`);
				} else {
					throw res;
				}
			})
			.catch((err) => {
				if (err?.guid === null) {
					displayErrorNotification(t("newDocument.createDocument"), dispatch);
				} else {
					displayErrorNotification(t("newDocument.commonMsg"), dispatch);
				}
				setNavigateNewDocument(false);
			});
	};
	const removeDocuments = (documents) => {
		setDocuments((prev) => {
			Object.keys(prev).forEach((key) => {
				if (Array.isArray(prev[key])) {
					documents.forEach((document) => {
						const index = prev[key].indexOf(document);
						if (index >= 0) {
							prev[key].splice(index, 1);
						}
					});
				}
			});

			return { ...prev };
		});
	};

	const getSelected = useCallback(() => {
		const selected = [];

		Object.keys(documents).forEach((key) => {
			if (Array.isArray(documents[key])) {
				documents[key]
					.filter((document) => document.selected)
					.forEach((document) => {
						selected.push(document);
					});
			}
		});

		return selected;
	}, [id, documents]);

	const uploadClick = () => {
		input.current.click();
	};

	const uploadInputChange = (e) => {
		const { target: { files = [] } = {} } = e;

		// Only get files with allowed extensions
		const filteredFiles = [];
		const invalidFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			if (endsWithAllowedExtension(file.name)) {
				filteredFiles.push(file);
			} else {
				invalidFiles.push(file);
			}
		}

		if (filteredFiles.length > 0) {
			setDialogs({
				upload: {
					files: filteredFiles
						.sort((first, second) =>
							// eslint-disable-next-line no-nested-ternary
							first.webkitRelativePath < second.webkitRelativePath ? -1 : first.webkitRelativePath > second.webkitRelativePath ? 1 : 0,
						)
						.map((file) => ({
							file,
							name: trimChars("\\/", file.webkitRelativePath || file.name),
							path: trimChars("\\/", file.webkitRelativePath.replace(file.name, "")),
						})),
					invalidFiles,
				},
			});
		} else if (invalidFiles.length > 0) {
			setDialogs({
				failedUploads: invalidFiles,
			});
		}
	};

	const afterUploadFile = (document) => addDocument(document);

	const addFolderClick = () => {
		setDialogs({ addFolder: true });
	};

	const afterAddFolder = (folder) => {
		addDocument(folder);

		setFocusGuid(folder.guid);
	};

	const permanentlyDeleteClick = () => {
		setDialogs({ permanentlyDelete: true });
	};

	const restoreClick = () => {
		setDialogs({ restore: true });
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const closeUpload = (failedUploads = []) => {
		if (failedUploads.length === 0) {
			closeDialogs();
		} else {
			setDialogs({
				failedUploads,
			});
		}
	};

	const handleDocumentEvent = ({ eventName, document, expand, keyboard }) => {
		switch (eventName) {
			case "expand":
				document.expanded = typeof expand !== "undefined" ? expand : !document.expanded;
				document.focusChild = document.expanded;
				document.focusKeyboard = keyboard;

				// Trigger re-render
				setDocuments((prev) => {
					// Load children if needed
					if (document.expanded && !prev[document.guid]) {
						loadDocuments(document.guid, false, document);
					}

					return { ...prev };
				});
				break;

			case "rename":
				setDialogs({ rename: document });
				break;

			case "delete":
				setDialogs({ delete: document });
				break;

			case "permanently-delete":
				setDialogs({ permanentlyDelete: [document] });
				break;

			case "restore":
				setDialogs({ restore: [document] });
				break;
		}
	};

	const handleSort = (field) => {
		// If sorting by the same field switch between ascending, descending, and default order. New sort fields start sorted ascending.
		setOrder((prev) => ({
			field: prev.field !== field || !prev.descending ? field : undefined,
			descending: Boolean(prev.field === field && !prev.descending),
		}));
	};

	const cascadeSelections = (documents, parentSelections) => {
		if (parentSelections.length > 0) {
			let updated = [];
			parentSelections.forEach((parent) => {
				if (Array.isArray(documents[parent.guid])) {
					documents[parent.guid].forEach((document) => {
						document.selected = parent.selected;

						if (document.folder) {
							updated.push(document);
						}
					});
				}
			});

			cascadeSelections(documents, updated);
		}
	};

	const checkChildSelections = (documents, childDocuments = []) => {
		const selection = {
			selected: false,
			notSelected: false,
			hasChildren: childDocuments.length > 0,
		};
		childDocuments.forEach((document) => {
			// Check if children are selected
			if (document.folder) {
				const childSelection = checkChildSelections(documents, documents[document.guid]);
				if (childSelection.hasChildren) {
					document.selected = childSelection.selected && !childSelection.notSelected;
					document.childrenSelected = childSelection.selected;
				}
			}

			// Set if there are selected or not selected documents on the current level
			selection.selected = selection.selected || document.selected;
			selection.notSelected = selection.notSelected || !document.selected;
		});

		return selection;
	};

	const handleCheck = (guids = [], checked = false) => {
		setDocuments((prev) => {
			let updatedFolders = [];
			Object.keys(prev).forEach((key) => {
				if (Array.isArray(prev[key])) {
					const updatedDocuments = prev[key].filter((document) => guids.includes(document.guid));
					updatedDocuments.forEach((document) => {
						document.selected = checked;

						if (document.folder) {
							updatedFolders.push(document);
						}
					});
				}
			});

			cascadeSelections(prev, updatedFolders);
			checkChildSelections(prev, prev[ROOT_ID]);

			return { ...prev };
		});
	};

	useEffect(() => {
		if (documents && documents.root?.length > 0 && isRecycleBin) {
			let rowSelected = documents.root.some((document) => document.selected || document.childrenSelected);
			rowSelected ? setEnableRecycleTopBar(true) : setEnableRecycleTopBar(false);
		}
	}, [documents]);

	const handleSearch = (keywords) => {
		telemetryAddEvent(`${telemetryPage} - Library - Search initiated`, { area: "documents" });

		if (keywords && keywords.length > 0) {
			navigate(
				`/documents/search/${getPathType(type)}${type === FOLDER_TYPE_CUSTOM ? `/${id}` : ""}?keywords=${encodeURIComponent(keywords)}`,
			);
		}
	};

	const afterRename = () => {
		setDocuments((prev) => ({ ...prev }));
	};

	const afterDelete = (document, parentGuid) => {
		telemetryAddEvent(`${telemetryPage} - Library - Deleted document`, { area: "documents" });

		setDocuments((prev) => {
			if (prev[parentGuid]) {
				const index = prev[parentGuid].indexOf(document);
				if (index >= 0) {
					prev[parentGuid].splice(index, 1);
				}
			}

			return { ...prev };
		});
	};

	const undoDelete = (document, parentGuid) => {
		request
			.post(`${API_HOST}/api/document/${document.guid}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteDocumentDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				addDocument(document, parentGuid);
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(document, parentGuid);
				});
			});
	};

	const afterRestore = (documents) => {
		telemetryAddEvent(`${telemetryPage} - Library - Restored document`, { area: "documents" });

		removeDocuments(documents);

		if (documents.find((document) => document.folderType === FOLDER_TYPE_CUSTOM)) {
			dispatch(
				updateLeftNav({
					reloadLeftNav: { customFolders: Date.now() },
				}),
			);
		}
	};

	const afterPermanentlyDelete = (documents) => {
		telemetryAddEvent(`${telemetryPage} - Library - Permanently deleted document`, { area: "documents" });

		removeDocuments(documents);
	};

	useEffect(() => {
		const sortMethod = getSortMethod(order.field, order.descending);

		setDocuments((prev) => {
			if (prev) {
				Object.keys(prev).forEach((key) => {
					if (Array.isArray(prev[key])) {
						prev[key].sort(sortMethod);
					}
				});

				return { ...prev };
			} else {
				return prev;
			}
		});
	}, [order]);

	useEffect(() => {
		if (path && path.length > 0) {
			setTitle(path[path.length - 1].name);
		}
		dispatch(
			updatePageHeader({
				leftMenuOptions:
					path && widthDownSm
						? path
								.filter((folder) => folder.url)
								.map((folder) => ({
									label: folder.name,
									actionFunction: () => navigate(folder.url),
								}))
						: [],
			}),
		);
	}, [path]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));

		setDocuments({ folder: { id, type } });

		loadPermissions();
		loadDocuments(id, true);
		loadPath();
	}, [id, type]);

	useEffect(() => {
		// Scroll a row into view and then focus on the link
		if (focusGuid) {
			const link = document.getElementById(`link-${focusGuid}`);
			if (link) {
				const scrollParent = getScrollParent(link);
				if (scrollParent) {
					const yOffset = -60; // Give space for the table header (40px) plus some extra
					const y = link.getBoundingClientRect().top - scrollParent.getBoundingClientRect().top + yOffset;
					scrollParent.scrollTo({ top: y, behavior: "smooth" });
				}
				link.focus({ preventScroll: true });
			}
		}
	}, [focusGuid]);

	const getChildDocuments = useCallback(
		(list, childDocuments, depth = 0) => {
			let updatedList = list;

			if (childDocuments) {
				for (let index = 0; index < childDocuments.length; index++) {
					const childDocument = childDocuments[index];

					childDocument.depth = depth;

					updatedList.push(childDocument);

					if (childDocument.folder && childDocument.expanded) {
						updatedList = getChildDocuments(updatedList, documents[childDocument.guid], depth + 1);
					}
				}
			} else {
				updatedList.push({ loading: true });
			}

			return updatedList;
		},
		[documents],
	);

	return !navigateNewDocument ? (
		<ComponentContainer padding="0">
			{permissions && documents && (
				<DocumentsTopBar
					id={id}
					type={type}
					permissions={permissions}
					uploadClick={uploadClick}
					addFolderClick={addFolderClick}
					permanentlyDeleteClick={permanentlyDeleteClick}
					restoreClick={restoreClick}
					handleSearch={handleSearch}
					enableRecycleTopBar={enableRecycleTopBar}
					onClickNewDocument={toggleNewDocumentModal}
				></DocumentsTopBar>
			)}
			{documents && !widthDownSm && <Breadcrumbs className={classes.breadcrumbs} showMenu items={path} separator="/"></Breadcrumbs>}
			<DocumentList
				label={path?.[0]?.name || ""}
				type={type}
				permissions={permissions}
				order={order}
				documents={documents}
				handleEvent={handleDocumentEvent}
				handleSort={handleSort}
				handleCheck={handleCheck}
				telemetryPage={telemetryPage}
			></DocumentList>
			<input
				className={classes.fileInput}
				id="upload-input"
				type="file"
				ref={input}
				onChange={uploadInputChange}
				multiple
				accept={getAllowedAccept()}
				tabIndex={-1}
				aria-label={t("app:buttons.upload")}
				aria-hidden="true"
			/>
			{dialogs.upload && (
				<UploadDialog
					id={id}
					type={type}
					files={dialogs.upload.files}
					invalidFiles={dialogs.upload.invalidFiles}
					onClose={closeUpload}
					afterUploadFile={afterUploadFile}
					showSignIn={showSignIn}
					telemetryPage={telemetryPage}
				/>
			)}
			{dialogs.failedUploads && <UploadErrorDialog failedUploads={dialogs.failedUploads} onClose={closeDialogs} />}
			{dialogs.addFolder && (
				<AddFolderDialog id={id} type={type} onClose={closeDialogs} afterAddFolder={afterAddFolder} showSignIn={showSignIn} />
			)}
			{dialogs.rename && (
				<RenameDialog document={dialogs.rename} onClose={closeDialogs} afterRename={afterRename} showSignIn={showSignIn} isEllipsed />
			)}
			{dialogs.delete && (
				<DocumentDeleteDialog
					document={dialogs.delete}
					parentGuid={Object.keys(documents).find((key) => Array.isArray(documents[key]) && documents[key].includes(dialogs.delete))}
					onClose={closeDialogs}
					afterDelete={afterDelete}
					undoDelete={undoDelete}
				/>
			)}
			{dialogs.restore && (
				<DocumentRestoreDialog
					documents={Array.isArray(dialogs.restore) ? dialogs.restore : getSelected().sort(sortDefault)}
					onClose={closeDialogs}
					afterRestore={afterRestore}
					showSignIn={showSignIn}
				/>
			)}
			{dialogs.permanentlyDelete && (
				<DocumentPermanentlyDeleteDialog
					documents={Array.isArray(dialogs.permanentlyDelete) ? dialogs.permanentlyDelete : getSelected().sort(sortDefault)}
					onClose={closeDialogs}
					afterPermanentlyDelete={afterPermanentlyDelete}
					showSignIn={showSignIn}
				/>
			)}
			{isNewDocumentModalOpen && (
				<AddNewDocumentDialog
					show
					onClose={toggleNewDocumentModal}
					onSubmit={onClickNewDocument}
					setDocumentDetails={setDocumentDetails}
					documentDetails={documentDetails}
				/>
			)}
		</ComponentContainer>
	) : (
		<>
			<div className={classes.overlayLoader} />
			<CircularProgressIndicator className={classes.loader} />
		</>
	);
};

export default DocumentsModule;
