import React from "react";

import { useTranslation } from "react-i18next";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";

import { Check } from "components/Icons";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles(() => ({
	fieldLabel: {
		margin: "0px 10px 10px !important",
	},
}));

function PublishSettings({
	includeMinutesAttachments,
	updateMinutesAttachment,
	updateShowClosedItemTitle,
	showClosedItemTitle,
	updateAlwaysUseRecordedVote,
	alwaysUseRecordedVote,
	onPublishShowDraftMinutes,
	updateIsPublishDraftMinutes,
}) {
	const classes = useStyles();
	const { t } = useTranslation("meetings");

	return (
		<>
			<div>
				<label>{t("templateDetail.labels.publishSettings")}</label>
			</div>
			<FormControlLabel
				className={classes.fieldLabel}
				id="onPublishShowDraftMinutes"
				control={
					<Checkbox
						checkedIcon={<Check fontSize="small" color="black" />}
						checked={onPublishShowDraftMinutes}
						onChange={() => updateIsPublishDraftMinutes()}
						value={onPublishShowDraftMinutes}
					/>
				}
				label={<Typography>{t("publishSettings.PublishDraftMinutesPublicly")}</Typography>}
			/>
			<FormControlLabel
				className={classes.fieldLabel}
				id="includeMinutesAttachments"
				control={
					<Checkbox
						checkedIcon={<Check fontSize="small" color="black" />}
						checked={includeMinutesAttachments}
						onChange={() => updateMinutesAttachment()}
						value={includeMinutesAttachments}
					/>
				}
				label={<Typography>{t("publishSettings.minutesAttachment")}</Typography>}
			/>
			<FormControlLabel
				className={classes.fieldLabel}
				id="showClosedItemTitle"
				control={
					<Checkbox
						checkedIcon={<Check fontSize="small" color="black" />}
						checked={showClosedItemTitle}
						onChange={() => updateShowClosedItemTitle()}
						value={showClosedItemTitle}
					/>
				}
				label={<Typography>{t("publishSettings.showClosedItems")}</Typography>}
			/>
			<FormControlLabel
				className={classes.fieldLabel}
				id="always-use-recorded-vote"
				control={
					<Checkbox
						checkedIcon={<Check fontSize="small" color="black" />}
						checked={alwaysUseRecordedVote}
						onChange={() => updateAlwaysUseRecordedVote()}
						value={alwaysUseRecordedVote}
					/>
				}
				label={<Typography>{t("publishSettings.alwaysUseRecordedVote")}</Typography>}
			/>
		</>
	);
}

export default PublishSettings;
