import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthUp } from "atlas/utils/useWidth";
import { UserContext } from "contexts/User/UserContext";
import { FOLDER_TYPE_RECYCLE_BIN } from "utils/enums/DocumentFolderTypes";
import DocumentListHeader from "./DocumentListHeader";
import DocumentTree from "./DocumentTree";
import DocumentTreeDateItemColumn from "./DocumentTreeDateItemColumn";
import DocumentTreeMenuItemColumn from "./DocumentTreeMenuItemColumn";
import DocumentTreeSelectItemColumn from "./DocumentTreeSelectItemColumn";
import DocumentTreeTextItemColumn from "./DocumentTreeTextItemColumn";
import DocumentTreeTitleItemColumn from "./DocumentTreeTitleItemColumn";

const useStyles = makeStyles(() => ({
	tree: {
		"& *": {
			fontFamily: ({ inPortal }) => (inPortal ? "Segoe UI, Helvetica, Tahoma, Arial, Calibri, Verdana, sans-serif !important" : undefined),
			fontSize: ({ inPortal }) => (inPortal ? "13px" : undefined),
		},
	},
	noResults: {
		padding: "16px",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "20px",
		textAlign: "center",
		letterSpacing: "1px",
		textTransform: "uppercase",
		paddingTop: "54px",
		borderTop: ({ inPortal }) => (!inPortal ? "1px solid rgba(224, 224, 224, 1)" : undefined),
	},
}));

const DocumentList = (props) => {
	const { label, type, inPortal, order, permissions, documents, handleEvent, handleSort, handleCheck, telemetryPage } = props;
	const widthUpSm = useWidthUp("sm");
	const widthUpLg = useWidthUp("lg");
	const { t } = useTranslation("documents");
	const [anchor, setAnchor] = useState({});
	const { publicUser } = useContext(UserContext).user;
	const classes = useStyles({ inPortal });
	const recycleBin = type === FOLDER_TYPE_RECYCLE_BIN;

	const handleSetMenuAnchor = (document, anchor) =>
		setAnchor((prev) => (!anchor || (prev.document === document && prev.anchor) ? {} : { document, anchor }));

	const menuOptions = permissions
		? !recycleBin
			? (document) => [
					{
						label: t("app:buttons.rename"),
						tooltip: t("tooltips.rename"),
						actionFunction: (document) => handleEvent({ eventName: "rename", document }),
						"data-cy": "rename",
						hidden: !document.canUpdate,
					},
					{
						label: t("app:buttons.delete"),
						tooltip: t("tooltips.delete"),
						actionFunction: (document) => handleEvent({ eventName: "delete", document }),
						"data-cy": "delete",
						hidden: !document.canDelete,
					},
			  ]
			: (document) => [
					{
						label: t("app:buttons.permanentlyDelete"),
						tooltip: t("tooltips.permanentlyDeleteDocument"),
						actionFunction: (document) => handleEvent({ eventName: "permanently-delete", document }),
						"data-cy": "permanently-delete",
						hidden: !permissions.canPermanentlyDelete,
					},
					{
						label: t("app:buttons.restore"),
						tooltip: t("tooltips.restoreDocument"),
						actionFunction: (document) => handleEvent({ eventName: "restore", document }),
						"data-cy": "restore",
						hidden: !document.canDelete && !permissions.canPermanentlyDelete,
					},
			  ]
		: [];

	const columns = [];
	if (recycleBin) {
		columns.push({
			id: "select",
			action: handleCheck,
			isSelect: true,
			width: "42px",
			component: DocumentTreeSelectItemColumn,
		});
	}
	columns.push({
		id: "title",
		label: t("list.header.name"),
		action: handleSort,
		isSort: true,
		component: DocumentTreeTitleItemColumn,
	});
	if (!publicUser && widthUpSm) {
		if (!recycleBin) {
			columns.push({
				id: "dateModified",
				label: t("list.header.lastModified"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "200px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeDateItemColumn,
			});
			columns.push({
				id: "modifiedBy",
				label: t("list.header.modifiedBy"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "180px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeTextItemColumn,
			});
		} else {
			columns.push({
				id: "dateDeleted",
				label: t("list.header.dateDeleted"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "200px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeDateItemColumn,
			});
			columns.push({
				id: "deletedBy",
				label: t("list.header.deletedBy"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "180px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeTextItemColumn,
			});
		}
	}
	if (!publicUser) {
		columns.push({
			id: "menu",
			width: "76px",
			options: menuOptions,
			menuTooltipText: t("tooltips.listMenu"),
			component: DocumentTreeMenuItemColumn,
		});
	}

	return documents?.root ? (
		<>
			<DocumentListHeader inPortal={inPortal} order={order} documents={documents} columns={columns} />
			<div className={classes.tree}>
				{documents.root.length > 0 ? (
					<DocumentTree
						documents={documents}
						columns={columns}
						label={label}
						type={type}
						inPortal={inPortal}
						handleEvent={handleEvent}
						anchor={anchor}
						setAnchor={handleSetMenuAnchor}
						telemetryPage={telemetryPage}
					/>
				) : (
					<div className={classes.noResults}>{t("list.noResults.description")}</div>
				)}
			</div>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default DocumentList;
