import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { AutoSizer, List, Column, Table, CellMeasurer } from "react-virtualized";

import makeStyles from "@mui/styles/makeStyles";
import { Button, Box, Typography, TableCell, IconButton } from "@mui/material";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { blackColor, primaryColor, grayColor } from "atlas/assets/jss/shared";
import tableDataStyle from "assets/jss/components/tableDataStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import Icon from "atlas/components/Icon/Icon";

const publicUserImageUrl =
	"data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M12 1.25c5.937 0 10.75 4.813 10.75 10.75a10.729 10.729 0 0 1-3.968 8.342A10.708 10.708 0 0 1 12 22.75C6.063 22.75 1.25 17.937 1.25 12S6.063 1.25 12 1.25zm3.063 9.856c1.41-3.81-2.103-5.769-5.4-3.459a.75.75 0 0 1-.43.136c-.595 0-.84 1.361-.307 3.394a.75.75 0 0 1-.726.94c-.013 0 .011-.025-.016.084a1.614 1.614 0 0 0-.034.4v.032c0 .373.274 1.217.25 1.217a.75.75 0 0 1 .726.564c.2.781.623 1.488 1.216 2.035a.75.75 0 0 1 .241.551v.333c0 .586-.09.97-.48 1.41-.502.568-1.403.963-2.849 1.23l.041-.007A9.207 9.207 0 0 0 12 21.25a9.207 9.207 0 0 0 4.746-1.309l-.016-.004c-1.43-.264-2.331-.659-2.834-1.227-.39-.44-.48-.825-.48-1.41v-.333a.75.75 0 0 1 .237-.547 4.183 4.183 0 0 0 1.187-2.007.75.75 0 0 1 .727-.563s.25-.785.25-1.217v-.035c0-.177-.007-.29-.031-.397-.026-.112 0-.084-.02-.084a.75.75 0 0 1-.703-1.01zM12 2.75a9.25 9.25 0 0 0-6.199 16.116 4.583 4.583 0 0 1 1.196-.37c1.131-.21 1.746-.48 1.983-.747.088-.1.103-.164.103-.416v-.018a5.683 5.683 0 0 1-1.281-2.099c-.434-.216-.72-.68-.915-1.28-.146-.45-.237-1.017-.237-1.303v-.026c-.001-.285.013-.5.077-.762.098-.4.295-.738.592-.957-.497-2.458-.049-4.422 1.666-4.593 4.15-2.738 8.974-.2 7.71 4.624.278.223.462.555.552.945.058.25.07.454.07.74v.03c0 .31-.08.862-.223 1.31-.19.599-.485 1.064-.931 1.277a5.683 5.683 0 0 1-1.246 2.057v.022c0 .252.015.316.103.416.237.268.852.537 1.985.747.417.078.82.211 1.2.394A9.25 9.25 0 0 0 12 2.75z'%3E%3C/path%3E%3C/svg%3E";

const useStyles = makeStyles({
	tableContainer: {
		height: "calc(100vh - 195px)",
		"& .MuiTooltip-popper": {},
	},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		width: "100%",
	},
	table: {
		width: "100%",
	},
	tableRow: {
		borderBottom: "1px solid #e0e0e0",
	},
	tableRowHover: {
		"&:hover": {
			backgroundColor: grayColor[0],
		},
	},
	headCell: {
		fontWeight: "600 !important",
		fontSize: "16px !important",
		lineHeight: "20px !important",
		whiteSpace: "nowrap",
		cursor: "pointer",
		"& > span": {
			marginRight: "8px",
		},
	},
	cell: {
		...tableDataStyle.cell,
		width: "100%",
		height: isMobile ? "auto" : "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "16px",
	},
	activeButton: {
		width: "fit-content",
		margin: 0,
		padding: "0 8px",
		height: "24px",
		"& .active": {},
		"& .inactive": {},
		"& .sendConfirmation": {},
	},
	avatar: {
		fontWeight: "600",
		marginRight: "8px",
		marginLeft: "16px",
	},
	noClick: {
		cursor: "default !important",
		pointerEvents: "none !important",
	},
	noResults: {
		padding: "16px",
	},
	deleteIcon: {
		alignSelf: "start",
	},
});

const SubscribersVirtualizedList = (props) => {
	const { rows = [], sort = {}, headerHeight, meetingTypes, handleSort, handleActiveClick, handleDeleteClick, cache, mobileCache } = props;
	const { t } = useTranslation("subscribers");
	const classes = useStyles();

	const columns = [
		{
			width: 330,
			id: "userEmail",
			dataKey: "userEmail",
			disablePadding: false,
			label: t("list.header.emailAddress"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				let { row, rowIndex } = cellProps;
				row = row ? row : rows[rowIndex];
				if (row.public) {
					row.userImageUrl = publicUserImageUrl;
					row.name = row.userEmail;
				}
				return (
					<div title={row.name} style={{ display: "flex", alignItems: "center", marginTop: isMobile ? "8px" : "" }}>
						<GenericAvatar
							className={classes.avatar}
							imageUrl={row.userImageUrl}
							name={row.name}
							initials={`${row.firstName.substr(0, 1)}${(row.lastName || "").substr(0, 1)}`}
							backgroundNumber={(0 % GENERIC_AVATAR_BACKGROUNDS) + 1}
						/>
						<Typography>{row.userEmail}</Typography>
					</div>
				);
			},
		},
		{
			width: 330,
			id: "meetingTypes",
			dataKey: "meetingTypes",
			disablePadding: false,
			label: t("list.header.meetingTypes"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				let { row, rowIndex } = cellProps;
				row = row ? row : rows[rowIndex];
				return (
					<div className={classes.cell} style={{ minHeight: "48px" }}>
						{row.meetingTypes.map((meetingTypeId) => {
							const meetingType = meetingTypes.find((meetingType) => meetingType.id === meetingTypeId);
							return <div key={`meeting-types-names-${meetingTypeId}-${rowIndex}`}>{`${meetingType ? meetingType.name : ""}`}</div>;
						})}
					</div>
				);
			},
		},
		{
			width: 70,
			id: "public",
			dataKey: "public",
			disablePadding: false,
			label: t("list.header.public"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				let { row, rowIndex } = cellProps;
				row = row ? row : rows[rowIndex];
				return <div className={classes.cell}>{t(`list.cell.${row.public ? "yes" : "no"}`)}</div>;
			},
		},
		{
			width: 220,
			id: "active",
			dataKey: "active",
			disablePadding: false,
			label: t("list.header.active"),
			headClassName: classes.activeHead,
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				let { row, rowIndex } = cellProps;
				row = row ? row : rows[rowIndex];
				return (
					<div className={clsx(classes.cell)}>
						<Button
							className={clsx(classes.activeButton, {
								[classes.active]: row.active,
								[classes.inactive]: !row.public && !row.active,
								[classes.sendConfirmation]: row.public && !row.active,
							})}
							variant="outlined"
							color="primary"
							title={t(`tooltips.${row.active ? "active" : row.public ? "sendConfirmation" : "inactive"}`)}
							onClick={() => handleActiveClick(row)}
							data-cy={`active-${row.id.join("-")}-button`}
						>
							{t(`buttons.${row.active ? "active" : row.public ? "sendConfirmation" : "inactive"}`)}
						</Button>
					</div>
				);
			},
		},
		{
			width: 70,
			id: "delete-subscription",
			dataKey: "delete-subscription",
			disablePadding: false,
			label: "Delete",
			retainCase: true,
			headClassName: classes.deleteHead,
			rowColor: blackColor[1],
			component: (cellProps) => {
				let { row, rowIndex } = cellProps;
				row = row ? row : rows[rowIndex];
				return (
					<div className={classes.cell}>
						<IconButton
							className={classes.deleteIcon}
							onClick={() => handleDeleteClick(row)}
							aria-label={t("tooltips.delete")}
							data-cy={`delete-subscription-${row.id.join("-")}`}
							size="large"
						>
							<Icon name="remove" color={primaryColor[1]} />
						</IconButton>
					</div>
				);
			},
		},
	];

	const rowRenderer = (rowProps) => {
		const { key, index, parent, style } = rowProps;
		return (
			<CellMeasurer cache={mobileCache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
				<div key={`row-${key}`} style={{ ...style }} className={classes.tableRow}>
					{columns.map((column) => column.component({ row: rows[index], index }))}
				</div>
			</CellMeasurer>
		);
	};

	const cellRenderer = (cellProps) => {
		const { dataKey, parent, rowData, columnIndex, rowIndex, style } = cellProps;
		const column = columns[columnIndex];
		if (dataKey === "meetingTypes") {
			return (
				<CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
					<div style={{ ...style }}>{column.component({ row: rowData, rowIndex })}</div>
				</CellMeasurer>
			);
		} else {
			return column.component({ row: rowData, rowIndex });
		}
	};

	const headerRenderer = (headerProps) => {
		const { label, dataKey, sortBy, sortDirection } = headerProps;

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.headCell, {
					[classes.noClick]: dataKey === "delete-subscription",
				})}
				variant="head"
				style={{ height: headerHeight }}
				align={"left"}
				data-cy={dataKey}
			>
				<span>{label}</span>
				{sortBy === dataKey && <Icon name={`${sortDirection === "ASC" ? "expand-up" : "expand-down"}`} size="16px" />}
			</TableCell>
		);
	};

	const noResultsRenderer = () => {
		return <Typography variant="body2">{t("list.noResults.description")}</Typography>;
	};

	return rows ? (
		rows.length > 0 ? (
			<div className={classes.tableContainer} data-cy="subscribers-list">
				<AutoSizer>
					{({ height, width }) =>
						isMobile ? (
							<List rowRenderer={rowRenderer} rowCount={rows.length} rowHeight={mobileCache.rowHeight} height={height} width={width} />
						) : (
							<Table
								height={height}
								width={width}
								rowHeight={cache.rowHeight}
								gridStyle={{
									direction: "column",
								}}
								headerHeight={headerHeight}
								className={classes.table}
								rowCount={rows.length}
								rowGetter={({ index }) => rows[index]}
								rowClassName={clsx(classes.tableRow, classes.flexContainer)}
								noRowsRenderer={noResultsRenderer}
								deferredMeasurementCache={cache}
								overscanColumnCount={2}
								sort={({ sortBy, sortDirection }) => {
									if (sortBy !== "delete-subscription") {
										handleSort(sortBy, sortDirection);
									}

									return false;
								}}
								sortBy={sort.field}
								sortDirection={sort.dir}
							>
								{columns.map(({ dataKey, ...other }, index) => {
									return (
										<Column
											key={dataKey}
											className={classes.flexContainer}
											headerRenderer={headerRenderer}
											cellRenderer={cellRenderer}
											dataKey={dataKey}
											{...other}
										/>
									);
								})}
							</Table>
						)
					}
				</AutoSizer>
			</div>
		) : (
			<div className={classes.noResults}>
				<Box>
					<Typography variant="h3">{t("list.noResults.title")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("list.noResults.description")}</Typography>
				</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default SubscribersVirtualizedList;
