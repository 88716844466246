import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";
import clsx from "clsx";

import { SettingsContext } from "contexts/Settings/SettingsContext";

import makeStyles from "@mui/styles/makeStyles";

import searchAndTreeDialogStyle from "assets/jss/components/searchAndTreeDialogStyle";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import DocumentTree from "components/Dialogs/DocumentTree";
import SearchInput from "components/Search/SearchInput";
import { API_HOST } from "config/env";
import { FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL, FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import { sortTextFieldCaseInsensitive, sortBooleanField, sortNumericField } from "utils/sort";
import SearchResults from "views/Search/components/SearchResults";

const useStyles = makeStyles(searchAndTreeDialogStyle);

const sortDefault = (a, b) => {
	const folderTypeSort = sortNumericField(a, b, "folderType");
	const folderSort = folderTypeSort !== 0 ? folderTypeSort : sortBooleanField(a, b, "folder", true);

	return folderSort !== 0 ? folderSort : sortTextFieldCaseInsensitive(a, b, "title");
};

const DocumentMoveCopyDialog = (props) => {
	const { show = true, document, onClose, afterCopyMove } = props;
	const { t } = useTranslation("documents");
	const [searchKeywords, setSearchKeywords] = useState("");
	const [documents, setDocuments] = useState(null);
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);
	const [selection, setSelection] = useState(null);
	const [moving, setMoving] = useState(false);
	const lastSearch = useRef("");
	const { lite } = useContext(SettingsContext);
	const classes = useStyles();

	const move = document.parentFolderType > 0;

	const setSelectedData = (document) => {
		setSelection(document.selected ? document.guid : null);
	};

	const setTreeSelectedData = useCallback(
		(document) => {
			setSelectedData(document);

			// Only one document can be selected at a time
			clearDocumentSelection(documents, document.guid);
		},
		[documents],
	);

	const clearDocumentSelection = (documents, excludeGuid) => {
		documents
			.filter((document) => document.guid !== excludeGuid)
			.forEach((document) => {
				document.selected = false;
				if (document.children) {
					clearDocumentSelection(document.children, excludeGuid);
				}
			});
	};

	const setSearchResultsSelectedData = useCallback(
		(document) => {
			setSelectedData(document);

			// Only one document can be selected at a time
			searchResults
				.filter((otherResult) => otherResult.selected && otherResult !== document)
				.forEach((otherResult) => {
					otherResult.selected = false;
				});
		},
		[searchResults],
	);

	const canSelect = (document) => document?.folder;

	const loadDocuments = (parent) => {
		const folderTypes = [FOLDER_TYPE_PUBLIC];
		if (!lite.enabled) {
			folderTypes.push(FOLDER_TYPE_INTERNAL);
			folderTypes.push(FOLDER_TYPE_CUSTOM);
		}

		return new Promise((resolve, reject) => {
			request
				.get(`${API_HOST}/api/documents`)
				.query({
					id: parent?.guid,
					types: folderTypes.join(","),
					showDocuments: false,
					displayHasChildren: true,
				})
				.withCredentials()
				.then((res) => {
					let documents = res.body || [];
					documents = documents.filter((policy) => policy.canUpdate).sort(sortDefault);

					if (parent) {
						parent.children = documents;
					} else {
						setDocuments(documents);
					}

					resolve(documents);
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							loadDocuments(parent);
						},
						reject,
					);
				});
		});
	};

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
		if (!value) {
			setSearching(false);
		}
	};

	const handleSearchKeyDown = (e) => {
		if (e.key === "Enter") {
			const { value } = e.target;
			handleSearch(value);
		}
	};

	const onClearSearchClick = () => {
		setSearchKeywords("");
		setSearching(false);
	};

	const searchResultIsFolder = (result) => result && result.folder;

	const handleSearch = (value, pageLoadResolver) => {
		setSearching(true);
		lastSearch.current = value;

		if (value) {
			if (!pageLoadResolver) {
				setSearchResults(null); // New search/filter, so show the loading indicator
			}

			const folderTypes = [FOLDER_TYPE_PUBLIC];
			if (!lite.enabled) {
				folderTypes.push(FOLDER_TYPE_INTERNAL);
				folderTypes.push(FOLDER_TYPE_CUSTOM);
			}

			request
				.get(`${API_HOST}/api/items/search`)
				.withCredentials()
				.query({
					keyWords: value,
					includeTotals: true,
					searchTypes: 2,
					folderTypes: folderTypes.join(","),
					page: pageLoadResolver ? page + 1 : undefined,
				})
				.then((res) => {
					const { results = [], total } = res.body;

					if (!pageLoadResolver) {
						setSearchResults(results.filter(searchResultIsFolder));
						setTotal(total);
						setPage(1);
					} else {
						setSearchResults((prev) => (prev || []).concat(results.filter(searchResultIsFolder)));
						pageLoadResolver();
						setPage((prev) => prev + 1);
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							handleSearch(value);
						},
						() => {
							setSearchResults([]);
							setTotal(0);
							setPage(0);
						},
					);
				});
		} else {
			setSearchResults([]);
		}
	};

	const loadMore = () =>
		new Promise((resolve) => {
			handleSearch(lastSearch.current, resolve);
		});

	const handleCopyMove = () => {
		if (selection) {
			setMoving(true);

			const copyMoveRequest = move
				? request.patch(`${API_HOST}/api/document/${document.guid}/detail`)
				: request.post(`${API_HOST}/api/document/${document.guid}/copy`);

			copyMoveRequest
				.withCredentials()
				.send({ parentId: selection })
				.then((res) => {
					afterCopyMove(res.body, move, res.body.path.map((pathPart) => pathPart.title.trim()).join(" > "));
					onClose();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	useEffect(() => {
		loadDocuments();
	}, []);

	const i18n = t("copyMoveDocumentDialog", { title: document.title + document.extension });

	const dialog = {
		title: i18n.title[move ? "move" : "copy"],
		line1: i18n.line1[move ? "move" : "copy"],
		primaryTitle: t(`app:buttons.${move ? "move" : "copy"}`),
		primaryAction: handleCopyMove,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: onClose,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={moving || selection === 0}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={moving}
			clickAwayDisabled={moving}
			closeIcon={<Icon name="close" />}
			data-cy="move-dialog"
		>
			<div className={classes.content}>
				<div
					className={clsx(classes.searchContainerNoTabs, {
						[classes.searchContainerLoading]: !documents,
					})}
				>
					<SearchInput
						size="extra-small"
						fullWidth
						value={searchKeywords || ""}
						showClearIcon={searchKeywords && searchKeywords.length > 0}
						onKeyDown={handleSearchKeyDown}
						onChange={handleSearchChange}
						onIconClick={() => {
							handleSearch(searchKeywords);
						}}
						onClearClick={onClearSearchClick}
						data-cy="document-search"
					/>
				</div>
				{!searching ? (
					documents ? (
						<div className={classes.dataContainer} data-cy="document-tree">
							<DocumentTree
								documents={documents}
								foldersOnly
								canSelect={canSelect}
								selection={selection}
								handleLoadChildren={loadDocuments}
								setSelectedData={setTreeSelectedData}
							/>
						</div>
					) : (
						<CircularProgressIndicator />
					)
				) : null}
				{searching ? (
					searchResults ? (
						<div className={classes.dataContainer} data-search data-cy="document-search-results">
							<SearchResults
								results={searchResults}
								total={total}
								loadMore={loadMore}
								compact={{ height: "250px" }}
								enableSelect={{
									allow: (result) => result.folder,
									handle: (result, selected) => {
										result.selected = selected;
									},
								}}
								selection={selection}
								setSelectedData={setSearchResultsSelectedData}
							/>
						</div>
					) : (
						<CircularProgressIndicator />
					)
				) : null}
			</div>
		</GenericDialog>
	);
};

export default DocumentMoveCopyDialog;
