import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
}));

const AddFolderDialog = (props) => {
	const { show = true, id = 0, type, onClose, afterAddFolder, showSignIn } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [folderName, setFolderName] = useState("");
	const [adding, setAdding] = useState(false);
	const classes = useStyles();

	const handleAddFolder = useCallback(() => {
		setAdding(true);

		request
			.post(`${API_HOST}/api/document/${id}/addfolder`)
			.query({ type })
			.withCredentials()
			.send({ title: folderName })
			.then((res) => {
				onClose();
				let option = notifierMessage(t("addFolderDialog.snackbar.success", { title: folderName }), "success");
				dispatch(setSnackbarOptions(option));
				afterAddFolder(res.body);
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleAddFolder();
				});
			});
	}, [folderName]);

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("addFolderDialog", { title: "" });

	const dialog = {
		title: i18n.title,
		primaryTitle: t("app:buttons.create"),
		primaryAction: handleAddFolder,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={adding || !folderName}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={adding}
			clickAwayDisabled={adding}
			closeIcon={<Icon name="close" />}
			data-cy="add-folder-dialog"
		>
			<OutlinedInput
				id="name"
				label={t("addFolderDialog.labels.name")}
				placeholder={t("addFolderDialog.placeholders.folderName")}
				value={folderName}
				onChange={(e) => setFolderName(e.target.value)}
				noDefaultClassName
				fullWidth
				autoFocus
				size="small"
				data-cy="name"
			/>
		</GenericDialog>
	);
};

export default AddFolderDialog;
