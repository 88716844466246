import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EasyImagePlugin from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageCaptionPlugin from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbedPlugin from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import PageBreakPlugin from "@ckeditor/ckeditor5-page-break/src/pagebreak";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PasteFromOfficePlugin from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TwoStepCaretMovement from "@ckeditor/ckeditor5-typing/src/twostepcaretmovement";
import CloudServicesPlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";
import Parser, { domToReact } from "html-react-parser";

import AddPolicyPlugin from "components/Editor/plugins/AddPolicy/AddPolicy";
import AttachFilePlugin from "components/Editor/plugins/AttachFile/AttachFile";
import InlineFilePlugin from "components/Editor/plugins/InlineFile/inlineFile";
import { setNodeTitle } from "components/Editor/plugins/InlineFile/inlineFileUtils";
import InsertPlaceholder from "components/Editor/plugins/Placeholders/placeholders";
import MapleUploadAdapter from "components/Editor/MapleUploadAdapter";
import InlineFileMOPlugin from "components/Editor/plugins/InlineFile/memberOnly/inlineFileMO";

import compact from "lodash/fp/compact";
import forEach from "lodash/fp/forEach";

import { v4 as uuid } from "uuid";

import { SettingsContext } from "contexts/Settings/SettingsContext";
import { validateLinkHref } from "../functions/utils";
import { allowedEditorExtensions } from "utils/allowedExtensions";

const toolbars = {
	simple: { items: ["link", "inlineFile", "addPolicy"] },
	simpleMo: { items: ["link", "inlineFileMo", "addPolicy"] },
	scratchpad: { items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "insertTable"] },
	limited: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "inlineFile", "addPolicy", "insertTable"],
	},
	itemText: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"insertTable",
			"pageBreak",
		],
	},
	itemTextMo: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFileMo",
			"addPolicy",
			"insertTable",
			"pageBreak",
		],
	},
	header: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertMeetingPlaceHolder",
		],
	},
	footer: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertMeetingPlaceHolder",
		],
	},
};

class AgendaCkeEditorContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			isLoading: false,
		};
		this.editorConfig = {};
		this.loaded = false;
		this.editorContainer = React.createRef();
		this.initEditor = this.initEditor.bind(this);
		this.checkEditorLoad = this.checkEditorLoad.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleUndoRedo = this.handleUndoRedo.bind(this);
		this.batchLength = 0;
	}

	componentDidMount() {
		this.checkEditorLoad();
	}

	componentDidUpdate(_prevProps, prevState) {
		this.checkEditorLoad();

		const { handleLoad } = this.props;
		if (typeof handleLoad === "function" && this.state.loaded && !prevState.loaded) {
			handleLoad({ editor: this.editor, container: this.editorContainer.current });
		}
	}

	componentWillUnmount() {
		clearTimeout(this.saveTimer);
		if (this.editor) this.editor.destroy();
	}

	handleEditorChange(event, editor, batchLength) {
		// if our batchLength dropped, CKE registered an undo-able step, so we save the changes.
		// if not, we kick off a 500ms timer, and save if nothing else comes in.
		clearTimeout(this.saveTimer);
		const cancelNextRender = { agenda: true };
		if (batchLength < this.batchLength) {
			this.props.onChange(event, editor, cancelNextRender);
			this.batchLength = 0;
		} else {
			this.batchLength = batchLength;
			this.saveTimer = setTimeout(this.props.onChange, 500, event, editor, cancelNextRender);
		}
	}

	handleUndoRedo(isUndo) {
		const { editorFunctionsContext } = this.props;
		if (isUndo) {
			editorFunctionsContext.undo();
		} else {
			editorFunctionsContext.redo();
		}
	}

	checkEditorLoad() {
		const { isActive, loadAsync } = this.props;
		const { ckEditorArguments } = this.context;
		const { loaded, isLoading } = this.state;
		if (!loaded && !isLoading && isActive && ckEditorArguments) {
			// if we previously re-positioned a number because of a CKE toolbar, put it back.
			const movedDiv = document.getElementsByClassName("cke-number-offset")[0];
			if (movedDiv) movedDiv.classList.remove("cke-number-offset");

			if (loadAsync) {
				setTimeout(this.initEditor);
			} else {
				this.initEditor();
			}
			this.setState({ isLoading: true });
		} else if (loaded && !isActive) {
			try {
				this.editor.community.functions.setMeetingEditorData("currentEditor", null);
				this.editor.destroy();
			} catch (e) {
				// the react timing is making this throw errors on destroy due to DOM elements that are missing attributes... but that is OK. I think.
			}
			// } else if (!isLoading && this.editor.getData() !== content) {
			// we changed the content outside the editor (undo, for example) -- update it.
			// Note:  This was removed for inline attachments.  To improve performance we are preventing updating of
			// agenda items in some circumstances, which means that the props.content may be out of sync
			// this.editor.data.set(content);
		}
	}

	initEditor() {
		const { toolbar, guid, editorFunctionsContext, addPolicy, isMemberOnlySection, isClosedMeeting, t } = this.props;
		const { ckEditorArguments } = this.context;
		let itemGuid = this.props.guid;
		if (itemGuid.endsWith("-text")) {
			itemGuid = itemGuid.substr(0, itemGuid.length - 5);
		}
		let isMemberOnly = false;
		if (isMemberOnlySection === true || isClosedMeeting === true) {
			isMemberOnly = true;
		}

		let foundPageBreaks = false;
		let editorContentHtml = this.editorContainer.current.innerHTML;
		// Check for break-after style attribute and change it to page-break-after so ckeditor can read it properly
		if (this.editorContainer.current.innerHTML.indexOf("break-after: page") !== -1) {
			editorContentHtml = this.editorContainer.current.innerHTML.replace(/break-after: page/g, "page-break-after: always");
			foundPageBreaks = true;
		}

		if (toolbars.header.items.indexOf("heading") < 0) {
			const headingToolbarItems = ["heading", "|"].concat(toolbars.header.items);
			toolbars.header.items = headingToolbarItems;
		}
		const useInineFileMo = Boolean(toolbars[toolbar]?.items?.includes("inlineFileMo"));

		// Disable addPolicy if needed
		const useAddPolicy = typeof addPolicy === "function";
		if (!useAddPolicy && toolbars[toolbar]?.items) {
			toolbars[toolbar].items = toolbars[toolbar].items.filter((item) => item !== "addPolicy");
		}

		ClassicEditor.create(this.editorContainer.current, {
			updateSourceElementOnDestroy: true,
			custom: ckEditorArguments,
			extraPlugins: [MapleUploadAdapter],
			mediaEmbed: {
				removeProviders: ["instagram", "twitter", "googleMaps", "flickr", "facebook"],
			},
			image: {
				toolbar: ["imageTextAlternative"],
			},
			language: "en",
			licenseKey: ckEditorArguments.key,
			plugins: compact([
				Clipboard,
				EssentialsPlugin,
				AlignmentPlugin,
				AutoformatPlugin,
				useAddPolicy && AddPolicyPlugin,
				AttachFilePlugin,
				!useInineFileMo && InlineFilePlugin,
				useInineFileMo && InlineFileMOPlugin,
				InsertPlaceholder,
				BoldPlugin,
				EasyImagePlugin,
				FontPlugin,
				HeadingPlugin,
				ItalicPlugin,
				ImagePlugin,
				ImageCaptionPlugin,
				ImageStylePlugin,
				ImageToolbarPlugin,
				ImageUploadPlugin,
				IndentPlugin,
				IndentBlockPlugin,
				LinkPlugin,
				toolbar !== "simple" && ListPlugin,
				MediaEmbedPlugin,
				ParagraphPlugin,
				PasteFromOfficePlugin,
				PageBreakPlugin,
				TablePlugin,
				TableToolbarPlugin,
				TableProperties,
				TableCellProperties,
				TwoStepCaretMovement,
				CloudServicesPlugin,
			]),
			link: {
				defaultProtocol: "https://",
			},
			indentBlock: {
				offset: 1,
				unit: "em",
			},
			table: {
				contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
			},
			heading: {
				options: [
					{ model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
					{ model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
					{ model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
				],
			},
			fontSize: {
				options: [9, 11, 13, "default", 17, 19, 21],
				supportAllValues: true,
			},
			toolbar: toolbars[toolbar],
			inlineFile: {
				parentId: itemGuid,
				positionLimiter: document.querySelector("div.scroll-bar-agenda-editor"),
				translations: t("inlineFile"),
				anchorTitle: t("inlineFile.features.MOA.anchorTitlePublic"),
				features: [
					{
						id: "MOA",
						label: t("inlineFile.features.MOA.featureLabel"),
						className: "closed",
						defaultValue: isMemberOnly,
						disabledValue: isMemberOnly,
						isEnabled: !isMemberOnly,
						anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
						tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
					},
				],
				urlBase: "/document",
				allowedFileExtensions: allowedEditorExtensions,
				functions: {
					onAddFiles: (editor, parentId, files) => {
						const cancelNextRender = true;
						editor.onItemChange(null, editor, cancelNextRender);
						editor.community.functions.addFileUpload(files);
					},
					onDeleteFile: (editor, parentId, fileGuid) => {
						const cancelNextRender = true;
						editor.onItemChange(null, editor, cancelNextRender);
						editor.community.functions.deleteFile(parentId, fileGuid);
					},
					onInvalidExtension: (editor, parentId, fileNames) => {
						editor.community.functions.invalidFileExtension(parentId, fileNames);
					},
				},
			},
			addPolicy: {
				urlBase: "/home/document",
				itemGuid,
				features: [
					{
						id: "MOA",
						label: t("inlineFile.features.MOA.featureLabel"),
						className: "closed",
						defaultValue: isMemberOnly,
						disabledValue: isMemberOnly,
						isEnabled: !isMemberOnly,
						anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
						tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
					},
				],
				functions: {
					onOpenDialog: addPolicy,
				},
			},
		})
			.then((editor) => {
				// this is to set the editor active after the fade in animation
				setTimeout(() => {
					editor.editing.view.focus();
				}, 250);
				// This sets tab shortcut to get into ANY sub bar that ck-editor might throw at us. Then using tabs and/or arrow keys and enter will change and press buttons
				editor.keystrokes.set("tab", (data, stop) => {
					const elem = document.getElementsByClassName(`ck-balloon-panel_visible`);
					if (elem.length > 0) {
						elem[0].addEventListener("keydown", function handler(e) {
							if (e.key === "Tab") {
								editorFunctionsContext.focusNextElement(guid);
								this.removeEventListener("keydown", handler);
								e.preventDefault();
							}
						});
						elem[0].getElementsByClassName("ck-balloon-rotator__content")[0].getElementsByTagName("button")[0].focus();
						stop(); // Works like data.preventDefault() + evt.stop()
					}
				});
				editor.model.document.on("change:data", (event, batch) => {
					if (this.props.onChange) {
						const editorData = editor.getData();
						// TODO find a way to save an empty value editor
						if (editorData !== this.props.content) {
							this.lastUpdateWasEmpty = editorData === "";
							this.handleEditorChange(event, editor, batch.operations.length);
						}
					}
				});
				// CKEditorInspector.attach(editor);

				editor.guid = uuid();
				editor.onItemChange = this.props.onChange;

				editor.editing.view.document.on("keydown", (evt, data) => {
					if (data.ctrlKey && (data.keyCode === 89 || data.keyCode === 90)) {
						this.handleUndoRedo(data.keyCode === 90);
						data.preventDefault();
						evt.stop();
					}
					// escape pressed
					if (this.props.escFunction && data.keyCode === 27) {
						this.props.escFunction();
					}
				});

				editor.editing.view.document.on("mouseup", (evt, data) => {
					const pageBreakCommand = editor.commands.get("pageBreak");
					const insertTableCommand = editor.commands.get("insertTable");
					// Disable pageBreak button if insertTable button is disabled, insertTable button will disable automatically if you focus a table
					if (!insertTableCommand.isEnabled) {
						pageBreakCommand.forceDisabled("PageBreak");

						let toolbarPropertiesOpen = false;
						const editorToolbarBoundingRec = editor.ui.element.querySelector(".ck.ck-editor__top").getBoundingClientRect();
						let editorTableToolbarBoundingRec = null;

						document.querySelectorAll(".ck.ck-balloon-panel").forEach((balloon) => {
							if (balloon && balloon.offsetParent !== null) {
								editorTableToolbarBoundingRec = balloon.getBoundingClientRect();
								toolbarPropertiesOpen = true;
							}
						});

						if (
							toolbarPropertiesOpen &&
							!editor.ui.element.style.paddingTop &&
							Math.abs(editorTableToolbarBoundingRec.top - editorToolbarBoundingRec.top) < 40
						) {
							editor.ui.element.style.paddingTop = "48px";
							editor.plugins.get("ContextualBalloon").view.top += 48;
						}
					} else {
						pageBreakCommand.clearForceDisabled("PageBreak");
						editor.ui.element.style.paddingTop = "";
					}
				});

				const clipboardPlugin = editor.plugins.get("ClipboardPipeline");
				if (clipboardPlugin != null) {
					clipboardPlugin.on("inputTransformation", (evt, data) => {
						let content = editor.data.processor.toData(data.content);
						content = content.replace(/font-size:[^;']*(;)?/gi, "").replace(/font-family:[^;']*(;)?/gi, "");
						data.content = editor.data.htmlProcessor.toView(content);
					});
				}

				editor.commands.get("tableCellBorderStyle").on("change:value", (evt, propertyName, newValue, oldValue) => {
					editor.execute("tableCellBorderWidth", {
						value: newValue ? "1px" : "",
					});

					editor.execute("tableCellBorderColor", {
						value: newValue ? "#000000" : "",
					});
				});

				editor.ui.focusTracker.on("change:isFocused", (event) => {
					if (!editor.ui.focusTracker.isFocused) {
						this.props.onChange(event, editor, true, this.lastUpdateWasEmpty);
						editor.ui.element.querySelector(".ck.ck-editor__main").style.paddingTop = "";
					}
				});

				this.editor = editor;
				editor.community = {
					functions: editorFunctionsContext,
				};

				// the CKE CSS resets actually break the toolbars when in React/Material. We can look for a better solution, but for now, brute force fix.
				forEach((elem) => {
					if (elem) {
						elem.classList.remove("ck-reset_all");
					}
				}, document.getElementsByClassName("ck-reset_all"));
				forEach((elem) => {
					if (elem) {
						elem.classList.remove("ck-reset");
					}
				}, document.getElementsByClassName("ck-reset"));

				// Prevent hidden file input accessibility errors
				forEach((element) => {
					if (element && element.tagName === "INPUT" && element.getAttribute("type") === "file") {
						element.setAttribute("title", t("app:editor.tooltips.selectFile"));
						if (element.previousSibling && element.previousSibling.tagName === "BUTTON") {
							const labeledBy = element.previousSibling.getAttribute("aria-labelledby");
							if (labeledBy) {
								element.setAttribute("aria-labelledby", labeledBy);
							}
						}
					}
				}, document.getElementsByClassName("ck-hidden"));

				this.setState({ loaded: true, isLoading: false });
				editor.editing.view.focus();

				// set up data and pointer to this editor, for undo functions
				editor.initHistoryLength = editor.model.document.history._operations.length;
				editor.community.functions.setMeetingEditorData("currentEditor", editor);

				if (foundPageBreaks) {
					editor.setData(editorContentHtml);
				}
			})
			.catch((err) => {
				console.error(err.stack);
			});
	}

	render() {
		const {
			content = "",
			isHeading,
			isSubHeading,
			isHeadingText,
			isAgendaItem,
			isItemText,
			isHeadingAction,
			active,
			isScratchpad,
			isRecommendation,
			guid,
			isMemberOnlySection,
			showScratchpad,
			item,
			isClosedMeeting,
			t,
		} = this.props;

		// CKEditor is injecting 0 margins to paragraphs... sometimes. This removes it when rendering as HTML.
		const parserOptions = {
			replace: (node) => {
				if (!node.attribs) return;
				if (node.name === "a" && node.attribs.href) {
					if (node.attribs.class && node.attribs.class.indexOf("inlineFile") !== -1) {
						setNodeTitle(node, item, isMemberOnlySection, isClosedMeeting, t("inlineFile.features.MOA"));

						// Link to the new document details
						if (node.attribs.href.indexOf("/document/") === 0) {
							node.attribs.href = `/home${node.attribs.href}`;
						}
					} else {
						node.attribs.href = validateLinkHref(node.attribs.href);
					}
				} else if (["img", "br"].includes(node.name) && node.attribs.style) {
					node.attribs.style = "";
				}
				if (guid !== "toc-header" && ["h1", "h2"].indexOf(node.name) >= 0) {
					return <p>{domToReact(node.children, parserOptions)}</p>;
				}
				if (
					node.attribs.style === "margin-top: 0; margin-bottom: 0;" ||
					(node.attribs.style && node.attribs.style.indexOf("font-size") >= 0)
				) {
					// eslint-disable-next-line consistent-return
					return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
				}
			},
		};

		let containerClassName;
		let height = "100%";
		if (isSubHeading) {
			containerClassName = isHeadingText ? "subheading-text-container active" : "subheading-container active";
		} else if (isHeading) {
			containerClassName = isMemberOnlySection ? "member-only-heading-container active" : "heading-container active";
		} else if (isHeadingText) {
			containerClassName = "heading-text-container active";
		} else if (isHeadingAction) {
			containerClassName = "heading-recommendation-container active";
		} else if (isAgendaItem) {
			containerClassName = "agenda-item-container active";
		} else if (isItemText) {
			containerClassName = "agenda-item-text-container active";
		} else if (isScratchpad) {
			containerClassName = this.isFocused ? "scratchpad-container-focused" : "scratchpad-container";
			height = "calc(100% - 40px)";
		} else if (isRecommendation) {
			containerClassName = "recommendation-container active";
		}

		return (
			<>
				{isScratchpad ? (
					<div className="scratchpad-top-container">
						<div
							className={containerClassName}
							onFocus={() => {
								if (this.isFocused) return;
								this.isFocused = true;
								this.editor.community.active = active;
							}}
							onBlur={() => (this.isFocused = false)}
						>
							{this.context.ckEditorArguments && (
								<div ref={this.editorContainer} tabIndex={showScratchpad ? 0 : -1}>
									{Parser(content || "<p></p>", parserOptions)}
								</div>
							)}
						</div>
					</div>
				) : (
					<div
						className={containerClassName}
						onFocus={() => {
							if (this.isFocused) return;
							this.isFocused = false;
							this.editor.community.active = active;
						}}
						onBlur={() => (this.isFocused = false)}
						data-cy={`agenda-item-${guid}`}
					>
						{this.context.ckEditorArguments && (
							<div id={guid} className="with-content" ref={this.editorContainer}>
								{Parser(content || "<p></p>", parserOptions)}
							</div>
						)}
					</div>
				)}
			</>
		);
	}
}

AgendaCkeEditorContent.contextType = SettingsContext;

export default withTranslation("meetings")(AgendaCkeEditorContent);
