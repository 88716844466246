import React from "react";
import parse from "html-react-parser";

import Tooltip from "atlas/components/Tooltip/Tooltip";
import { stripHtml } from "utils/processHtml";

const TOCPrimaryText = (props) => {
	const { text, parserOptions, showTruncationTooltip, truncationThreshold, tooltipPlacement = "top", isPdfSidebar } = props;

	const textOnly = stripHtml(text);
	const parsedTextForTooltip = isPdfSidebar && parse(text, parserOptions({ tooltip: true }));
	let parsedText;
	if (isPdfSidebar) {
		parsedText = parse(text, parserOptions());
	} else {
		parsedText = parse(text, parserOptions);
	}

	return showTruncationTooltip && textOnly.length > truncationThreshold ? (
		<Tooltip placement={tooltipPlacement} title={isPdfSidebar ? parsedTextForTooltip : textOnly}>
			<div>{parsedText}</div>
		</Tooltip>
	) : (
		parsedText
	);
};

export default TOCPrimaryText;
