import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import request from "superagent";
import clsx from "clsx";
import { v4 as uuid } from "uuid";
import queryString from "query-string";
import cookie from "react-cookies";

import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import { formatDate } from "utils/date";
import { getContentSize } from "utils/contentSize";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { PROGRESS_TYPE_PREVIEW } from "components/Dialogs/MeetingPreviewDialog";
import SendDraftForReviewDialog from "components/Dialogs/SendDraftForReviewDialog";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import { resetPageConfigs, updatePageConfigs, updateTabs, setProgressIds } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { Typography } from "@mui/material";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useAsyncResult } from "utils/useAsyncResult";

import PdfViewerNew from "../../components/PdfViewerNew/PdfViewerNew";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles(() => ({
	frame: {
		display: "block",
		border: "none",
		height: (props) => (props?.sizes?.height ? `${props.sizes.height}px` : "100%"),
		width: "100%",
	},
	pdfFrame: {
		height: (props) => (props?.sizes?.height ? `${props.sizes.height}px` : "100%"),
		"& iframe": {
			border: "none",
		},
	},
	hidden: {
		visibility: "hidden", // Workaround for PDFTron in firefox
		height: "0 !important",
		width: "0 !important",
	},
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
}));

const MeetingPreview = (props) => {
	const tabIndexes = {
		web: 0,
		pdf: 1,
	};

	const { final = false, review, showSignIn } = props;
	const location = useLocation();
	const { id, type, target } = useParams();
	const queryStringValues = queryString.parse(location.search) || {};
	const { t } = useTranslation("meetings");
	const [sizes, setSizes] = useState({});
	const [preview, setPreview] = useState(null);
	const [selected, setSelected] = useState({
		tab: tabIndexes.web,
		id: 0,
	});
	const [pdfViewerSettings, setPdfViewerSettings] = useState(null);
	const [isDocumentAdopted, setIsDocumentAdopted] = useState(false);
	const [openSendForeReviewDialog, setOpenSendForeReviewDialog] = useState(false);
	const [currentMeeting, setCurrentMeeting] = useState(null);
	const [openGeneratePreviewDialog, setOpenGeneratePreviewDialog] = useState(false);
	const [errors, setErrors] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const { client, handler } = useSelector((state) => state.appReducer.signalR);
	const { progressGuid, resultId, type: progressType } = useSelector((state) => state.appReducer.progressIds);
	const dispatch = useDispatch();
	const classes = useStyles({ sizes });

	const { lite, dateFormat } = useContext(SettingsContext);
	const { boardAdmin } = useContext(UserContext).user;

	const previewRef = useRef();
	const agenda = type.toLowerCase() !== "minutes"; // Default all invalid values to agenda
	const members = target.toLowerCase() === "members"; // Default all invalid values to public

	const continueExternalPreview = progressGuid && resultId && progressType === PROGRESS_TYPE_PREVIEW;

	previewRef.current = preview; // Ensures that tabChange has the latest preview

	const tabChange = (_e, tab) => {
		const {
			html: { documentId: htmlDocumentId = 0, lastModified: htmlLastModified = 0 } = {},
			pdf: { documentId: pdfDocumentId = 0, lastModified: pdfLastModified = 0 } = {},
		} = previewRef.current;

		setSelected({
			tab,
			id: tab === tabIndexes.web ? htmlDocumentId : pdfDocumentId,
			lastModified: tab === tabIndexes.web ? htmlLastModified : pdfLastModified,
		});
	};

	const customizationOptions = {
		fullScreenCustom: {
			customWrapper: null,
		},
		downloadCustom: {
			onClickUrl: `/document/${selected.id}?preview=true&eventwithindexpoints=0`,
		},
	};

	const loadPreview = useCallback(
		async (meetingId, isAgenda, isMembers) => {
			if (!continueExternalPreview) {
				setPreview(null);
			}

			try {
				const response = await request.get(
					`${API_HOST}/api/meeting/${id}/${final ? "final" : review ? "draftreview" : "preview"}?agenda=${isAgenda}&members=${isMembers}`,
				);
				const {
					meeting,
					html: { documentId: htmlDocumentId = 0, lastModified: htmlLastModified = 0 } = {},
					pdf: { documentId: pdfDocumentId = 0, lastModified: pdfLastModified = 0 } = {},
					adopted,
					outOfDate,
				} = response.body;

				const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);
				setCurrentMeeting({ ...meeting });

				const tabsOptions = [];
				if (htmlDocumentId > 0) {
					tabsOptions.push({ key: "web", label: t("tabs.webView"), dataCy: "web-view" });
				}
				if (pdfDocumentId > 0 || continueExternalPreview) {
					tabsOptions.push({ key: "pdf", label: t("tabs.pdf"), dataCy: "pdf" });
				}

				setIsDocumentAdopted(adopted);

				const query = new URLSearchParams(location.search);
				const liveMeeting = query.get("liveMeeting");

				dispatch(
					updatePageConfigs({
						back: liveMeeting > 0 ? undefined : { url: review ? "/" : `/meeting/${meetingId}` },
						preferedBack:
							liveMeeting > 0
								? { url: `/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${liveMeeting}` }
								: undefined,
						title: t(
							review
								? "preview"
								: members
									? adopted
										? "adoptedMembersView"
										: "previewMembersView"
									: adopted
										? "adoptedPublicView"
										: "previewPublicView",
							{ meetingName: meeting.name },
						),
						telemetryPage: "Meeting preview",
					}),
				);
				dispatch(
					updatePageHeader({
						additionalText: [meetingDate],
						primaryAction:
							!final && boardAdmin && !review && !lite.enabled
								? () => {
										telemetryAddEvent(`${isAgenda ? "Agenda" : "Minutes"} Preview - Send for Review`);
										setOpenSendForeReviewDialog(true);
									}
								: undefined,
						primaryActionText:
							!final && boardAdmin && !review ? <span style={{ whiteSpace: "normal" }}>{t("buttons.sendForReview")}</span> : undefined,
						primaryActionTooltip: !final && boardAdmin && !review ? t("tooltips.sendForReview") : undefined,
					}),
				);

				setPreview(response.body);
				// eslint-disable-next-line no-nested-ternary

				setSelected((prev) => {
					const webTabSelected = prev.tab !== tabIndexes.pdf;

					dispatch(
						updateTabs({
							display: true,
							selectedTab: htmlDocumentId > 0 && !adopted && webTabSelected ? tabIndexes.web : tabIndexes.pdf,
							tabsOptions,
							onChange: (_e, newTab) => {
								dispatch(
									updateTabs({
										selectedTab: newTab,
									}),
								);

								tabChange(_e, newTab);
							},
						}),
					);

					return {
						tab: htmlDocumentId > 0 && !adopted && webTabSelected ? tabIndexes.web : tabIndexes.pdf,
						id: htmlDocumentId > 0 && !adopted && webTabSelected ? htmlDocumentId : pdfDocumentId,
						lastModified: htmlDocumentId > 0 && webTabSelected ? htmlLastModified : pdfLastModified,
					};
				});
				setPdfViewerSettings({
					meetingName: meeting.cleanName,
					meetingDownloadDate: `${meeting.cleanName} - ${format(parseISO(meeting.date), dateFormat)}`,
					meetingDate,
					disableAnnotations: true,
				});

				if (review) {
					if (outOfDate && !continueExternalPreview) {
						setOpenGeneratePreviewDialog(true);
						const guid = uuid();
						updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });
						client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid));
						generatePreview(guid);
					}
				}
			} catch (err) {
				showSignIn(
					err,
					() => {
						loadPreview(meetingId, isAgenda, isMembers);
					},
					() => {
						if (err.status === 403) {
							setErrorMessage(t("app:noAccess"));
						}
					},
				);
				console.log(err);
			}
		},
		[continueExternalPreview],
	);

	const previewSuccess = (res) => {
		if (res.status === 200) {
			setProgress({
				label: " ",
				percent: 100,
			});

			if (res && res.body && res.body.pdf && res.body.pdf.errors) {
				// backend returns both item and attachment errors under the "pdf.errors" section
				setErrors(res.body.pdf.errors);
			} else {
				setErrors(null);
				setOpenGeneratePreviewDialog(false);
				loadPreview(id, agenda, members);
			}

			dispatch(setProgressIds());
		}
	};

	const previewError = (err) => {
		if ([408, 504].indexOf(err.status) >= 0) {
			err.timeout = true;
		}
		setErrors(err);
	};

	const [startResultCheck, resultCheckNow] = useAsyncResult(previewSuccess, previewError);

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage >= 100) {
			resultCheckNow();
		}
	};

	const generatePreview = (guid) => {
		request
			.post(`${API_HOST}/api/meeting/${id}/preview`)
			.withCredentials()
			.send({ progressGuid: guid, agenda, members })
			.then((res) => {
				if (res.status === 200) {
					startResultCheck(res);
				}
			})
			.catch(previewError);
	};

	const closeSendForeReviewDialog = () => {
		setOpenSendForeReviewDialog(false);
	};

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadPreview(id, agenda, members);

		if (queryStringValues && queryStringValues.adopted) {
			telemetryAddEvent("Minutes Adopted View - Final Print");
		}
	}, [id, agenda, members]);

	useEffect(() => {
		setSizes(getContentSize());
	}, [id, agenda, members, currentMeeting, preview, selected]);

	useEffect(() => {
		if (progressGuid && resultId && progressType === PROGRESS_TYPE_PREVIEW) {
			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

			client.ensureStarted().then(() => client.progressHub.registerProgressGuid(progressGuid));

			startResultCheck({ text: resultId });

			return () => {
				client.ensureStarted().then(() => client.progressHub.clearProgressGuid(progressGuid));

				updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
			};
		}
	}, [progressGuid, resultId, progressType]);

	return preview ? (
		<>
			<ComponentContainer padding="0">
				{openGeneratePreviewDialog && (
					<GenericDialog
						className={classes.dialog}
						show={openGeneratePreviewDialog}
						title={t(`meetingPreviewDialog.${agenda ? "agenda" : "minutes"}Title`)}
						secondaryAction={() => {
							setOpenGeneratePreviewDialog(false);
						}}
						secondaryTitle={t("app:buttons.cancel")}
						closeIcon={<Icon name="close" />}
						data-cy="meeting-preview"
					>
						<div className={classes.meetingName}>
							{t(`meetingPreviewDialog.${agenda ? "agenda" : "minutes"}Label`, { meetingName: currentMeeting.cleanName })}
						</div>
						<ProgressBar className={classes.progressBar} label={progress.label} progress={progress.percent} maxLabelWidthPercent={150} />
					</GenericDialog>
				)}
				{openSendForeReviewDialog && (
					<SendDraftForReviewDialog
						show={openSendForeReviewDialog}
						agenda={agenda}
						members={members}
						meeting={currentMeeting}
						onClose={closeSendForeReviewDialog}
					/>
				)}
				{selected.tab === tabIndexes.web && (
					<iframe
						className={clsx(classes.frame, {
							[classes.hidden]: selected.tab === tabIndexes.pdf,
						})}
						src={`/document/${selected.id}?preview=true&eventwithindexpoints=0&lastModified=${selected.lastModified}`}
						title={selected.tab === tabIndexes.web ? t("tabs.webView") : t("tabs.pdf")}
					/>
				)}
				<div
					className={clsx(classes.pdfFrame, {
						[classes.hidden]: selected.tab === tabIndexes.web,
					})}
				>
					{continueExternalPreview ? (
						<ProgressBar className={classes.progressBar} label={progress.label} progress={progress.percent} maxLabelWidthPercent={150} />
					) : (
						<>
							{pdfViewerSettings !== null && selected.tab === tabIndexes.pdf && (
								<PdfViewerNew
									meetingDownloadDate={pdfViewerSettings.meetingDownloadDate}
									id={selected.tab === tabIndexes.pdf ? selected.id : 0}
									settings={{
										...pdfViewerSettings,
										url: `/document/${selected.id}?preview=true&eventwithindexpoints=0&lastModified=${selected.lastModified}`,
										downloadOpensNewTab: true,
										showPrintButton: isDocumentAdopted,
										closeLeftPanel: true,
										watermark: isDocumentAdopted || final || !agenda ? null : { text: t("options.draft") },
										downloadAnnotations: true,
									}}
									isMeetingDocument
									selectedDocumentId={selected.tab === tabIndexes.pdf ? selected.id : 0}
									customizationOptions={customizationOptions}
									isGoalAdded
								/>
							)}
						</>
					)}
				</div>
			</ComponentContainer>
		</>
	) : errorMessage ? (
		<Typography variant="h2">{errorMessage}</Typography>
	) : (
		<CircularProgressIndicator />
	);
};

export default MeetingPreview;
