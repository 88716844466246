import React, { useRef, useEffect, useState, useContext } from "react";
import WebViewer from "@pdftron/webviewer";
import clsx from "clsx";

import { blackColor } from "atlas/assets/jss/shared";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box } from "@mui/material";
import { useWidthDown } from "atlas/utils/useWidth";
import { withRouter } from "utils/router";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { mapStateToProps } from "../../redux/app/mapStateToProps";
import useStateRef from "./utils/useStateRef";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import { StaffUserContext } from "./PdfWithSideBar";
import { makeStyles } from "@mui/styles";
import PdfPasswordDialog from "./PdfPasswordDialog";
import GoalsViewDialog from "./GoalsViewDialog";
import CustomAnnotationPopup from "./CustomAnnotationPopup";
import SignAndAdopt from "./Helper/SignAndAdopt";
import {
	customToggleFullScreen,
	getFileNameForComparePolicy,
	getImgForCssFullScreen,
	getImgForFullScreen,
	getTitleForCssFullScreen,
	getTitleForFullScreen,
	onCustomFullScreenChangeHandler,
	reArrangeHeaderForFullScreenMode,
	removeCustomSearchAnnotations,
	setCustomAnnotationClick,
	setCustomSearchAnnotations,
	setCustomUIElements,
	updateCustomUIElements,
	setDrawerModeForCustomAnnotationMenu,
	setSignatureButtonUI,
	toolModes,
	updateToolBarIcons,
	updateZoomIcons,
	updateZoomIconsForCompareMode,
} from "./Helper/CustomUIConfiguration";
import { closeButton } from "./Assets/PdftronIcons";
import { getUrlForPolicyCompare } from "./PdfAPICalls";
import { PolicyCompare } from "./Assets/PolicyCompare";
import { displayErrorNotification } from "views/Policy/utils/getNotification";
import useToolbarWidth from "./utils/useToolbarWidth";
import DiffCustomAnnotation from "./utils/DiffCustomAnnotation";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthDownSm = useWidthDown("sm");
	const widthDownMd = useWidthDown("md");

	return <WrappedComponent {...props} widthDownSm={widthDownSm} widthDownMd={widthDownMd} />;
};

const useStyles = makeStyles({
	viewFullScreenMobileContainer: {
		position: "absolute",
		top: "45px",
		left: "50%",
		transform: "translateX(-50%)",
		backgroundColor: "#5D7599",
		color: "white",
		border: "none",
		zIndex: 2,
	},
	headerBackgroundPosition: {
		display: "flex",
		background: "#2f3b4d",
		width: "100%",
		height: "48px",
		position: "absolute",
		zIndex: 1,
	},
	loadingIndicatorContainer: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "transparent",
		zIndex: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	loadingIndicatorForCompareMode: {
		backgroundColor: "white",
	},
	backgroundMobileContainer: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0,0,0,0.5)",
		zIndex: 1,
	},
	webviewerContainer: {
		height: "100%",
		position: "relative",
	},
	annotationContainer: {
		paddingLeft: "27px",
	},
	annotationTitleContainer: {
		margin: "4px 0",
	},
	annotationText: {
		fontWeight: "bold",
	},
});

const IFRAME_ID = "documentcontent";

const PdfViewerNew = (props) => {
	const viewerPromise = useRef(null);
	const viewer = useRef(null);
	const instance = useRef();
	const mutationObserver = useRef(null);
	const [customannotation, setCurrentCustomAnnotation, currentCustomAnnotation] = useStateRef(toolModes.default);
	const refShowCompareMode = useRef(false);
	const userZoomLevel = useRef(1);
	const dispatch = useDispatch();
	const [widthWebviewer] = useToolbarWidth(viewer);
	const webviewerRef = useRef(widthWebviewer);
	const [, setSelectedDocumentId, refselectedDocumentId] = useStateRef(props.selectedDocumentId);
	const [selectedDocumentData, setSelectedDocumentData, refselectedDocumentData] = useStateRef(null);
	const [documentWithAttachment, setdocumentWithAttachment, refdocumentWithAttachment] = useStateRef(false);
	const [documentVersionSelected, setDocumentVersionSelected] = useState(false);
	const [versionsDialogOpen, setversionsDialogOpen] = useState(false);
	const [loadingAllAnnotations, setLoadingAllAnnotations] = useState(false);
	const [isCompareModeOn, setIsCompareModeOn, refIsCompareModeOn] = useStateRef(false);
	const [initialDocumentLoaded, setinitialDocumentLoaded, refInitialDocumentLoaded] = useStateRef(false);
	const [mainDocumentLoaded, setMainDocumentLoaded] = useState(false);
	const [, setBookmarksData, refBookmarksData] = useStateRef();
	const [, setFullScreenMode, refFullscreenMode] = useStateRef(false);
	const [loadingDocumentInProgress, setloadingDocumentInProgress] = useState(true);
	const [dialogs, setDialogs] = useState({});
	const [isPolicyAttachment, setIsPolicyAttachment, refIsPolicyAttachment] = useStateRef(false);
	const [checked, setChecked] = useState(true);
	const [showGoal, setShowGoal] = useState(false);
	const [goalLink, setGoalLink] = useState("");
	const policyCompareDetail = useRef({});
	const {
		id,
		saveAnnotationAction,
		deleteAnnotationAction,
		t,
		loadAnnotationsAction,
		loadAllAnnotations,
		widthDownSm,
		widthDownMd,
		moveAllAnnotationsAction,
		deleteAllAnnotationsAction,
		loadAttachment,
		attachmentData,
		activeOutlineItem,
		loadOriginalDoc,
		showSignIn,
		meetingId,
		fileBlob,
		useBlob,
		setShowSideBar,
		refShowSideBar,
		documentAnnotationVersions,
		resetDocumentAnnotationVersion,
		setLoadOriginalDocFromPDF,
		setSearchHeading,
		isMeetingDocument,
		isMemberAgenda,
		setDataForAttachmentLink,
		showSidePanel,
		isAgendaScreen,
		agendaItemsForBookmarks,
		openComparePolicySideBar,
		showCompareMode = false,
		setShowCompareHeader,
		agendaItems = [],
		selectedCompareDoc = {},
		cleanAttachmentData,
		resetComparePolicySelection,
		customizationOptions = {},
		headerEndDivider = false,
		isViewerId = null,
		isGoalAdded,
	} = props;
	const {
		url,
		meetingName,
		meetingDate,
		meetingDownloadDate,
		documentOriginalName,
		fullAgendaPdfId,
		loadAllAnnotationVersions,
		userId,
		publicUser,
		enableSignatures,
		disableAnnotations = false,
		showPrintButton,
		downloadOpensNewTab,
		xod,
		attachments,
		watermark,
		downloadAnnotations,
	} = props.settings;
	const [, setActiveOutlineItem, refActiveOutlineItem] = useStateRef(activeOutlineItem);
	const [, setSelectedCompareDoc, refSelectedCompareDoc] = useStateRef({});
	const [, setAgendaItemsRef, refAgendaItems] = useStateRef(agendaItems);
	const { pdfTronL, policyEnabled } = useContext(SettingsContext);
	const isStaffUser = useContext(StaffUserContext);
	const { user: { name: userName } = {} } = useContext(UserContext);
	const appReducer = useSelector((state) => state.appReducer);
	const { bottomNotice } = appReducer;
	const telemetryTitleForMemberAgenda = "Agenda View";
	const telemetryForAgendaItem = "Agenda Items";
	let annotationTools = [];
	const classes = useStyles();
	const highQualityPrint = 2;
	const isCSSFullScreenRequired =
		document.fullscreenEnabled || document.webkitRequestFullscreen || document.mozRequestFullScreen || document.msRequestFullscreen;
	if (disableAnnotations) {
		annotationTools = [
			"stickyToolGroupButton",
			"highlightToolGroupButton",
			"freeHandToolGroupButton",
			"underlineToolGroupButton",
			"toggleNotesButton",
			"notesPanel",
		];
	}

	const disabledElements = [
		"toolbarGroup-Annotate",
		"undoButton",
		"redoButton",
		"eraserToolButton",
		"toolbarGroup-Edit",
		"toolbarGroup-EditText",
		"toolbarGroup-FillAndSign",
		"toolbarGroup-Forms",
		"toolbarGroup-Insert",
		"toolbarGroup-Measure",
		"toolbarGroup-Redact",
		"toolbarGroup-Shapes",
		"toolbarGroup-View",
		"toolbarGroup-Draw",
		"toolsHeader",
		"toggleCompareModeButton",
		"saveAsButton",
		"settingsButton",
		"rubberStampToolGroupButton",
		"signatureToolGroupButton",
		"stampToolGroupButton",
		isMemberAgenda && "sortContainer",
		isMemberAgenda && "filterAnnotationButton",
		"addReplyAttachmentButton",
		"fileAttachmentToolGroupButton",
		"calloutToolGroupButton",
		"threeDToolGroupButton",
		"changeViewToolGroupButton",
		"AnnotationEraserTool",
		"annotationNoteConnectorLine",
		"noteState",
		"unpostedCommentIndicator",
		"dropdown-item-status",
		downloadOpensNewTab && "printButton",
		...annotationTools,
		isAgendaScreen && "downloadButton",
		"annotationCommentButton",
		"annotationStyleEditButton",
		"linkButton",
		"header",
		"multiReplyButton",
		"multiStateButton",
		"multiStyleButton",
		"multiGroupButton",
		"annotationGroupButton",
		"annotationUngroupButton",
		"viewControlsDivider3",
		"viewControlsDivider2",
		"coverLayoutButton",
		"doubleLayoutButton",
		"singleLayoutButton",
		"layoutHeader",
		(isMemberAgenda || isAgendaScreen) && "toolsOverlay",
	];

	const annotationToolMode = [
		"AnnotationCreateTextHighlight",
		"AnnotationCreateTextUnderline",
		"AnnotationCreateFreeHand",
		"AnnotationCreateSticky",
	];

	const compareHiddenTools = [
		"notesPanel",
		"toggleNotesButton",
		"searchButton",
		"stickyToolGroupButton",
		"freeHandToolGroupButton",
		"underlineToolGroupButton",
		"highlightToolGroupButton",
		"zoomOverlayButton",
		"menuButton",
		"customAnnotationMenu",
	];
	const { displayDiffCustomAnnotations, removeDiffCustomAnnotations } = DiffCustomAnnotation();

	useEffect(() => {
		// Initialize the viewer
		viewerPromise.current = WebViewer(
			{
				path: "/Global/3rdParty/version10.6.1/lib",
				css: "/css/pdftron.viewer.css",
				licenseKey: pdfTronL,
				disabledElements,
				useDownloader: false,
				streaming: false,
				backendType: "ems",
				extension: "pdf",
				preloadWorker: "pdf",
				fullAPI: true,
				accessibleMode: true,
				annotationUser: userName,
				disableMultiViewerComparison: true,
			},
			viewer.current,
		);

		return () => {
			mutationObserver.current?.disconnect();
		};
	}, []);

	useEffect(() => {
		// If the viewer is initialized and the document URL exists, load the document
		if (viewerPromise.current && ((useBlob && fileBlob) || (!useBlob && url))) {
			viewerPromise.current.then(async (inst) => {
				const { UI, Core } = inst;
				const { annotationManager, documentViewer, Annotations } = Core;
				instance.current = inst;
				loadDocument();
				documentViewer.addEventListener("documentLoaded", documentLoadedHandler);
				isMeetingDocument && documentViewer.setOpenURIHandler((target) => pdfContentLinkClick(target));
				(isMemberAgenda || isAgendaScreen) && documentViewer.addEventListener("toolModeUpdated", toolModeUpdatedhandler);
				await UI.setZoomLevel(1);
				isMemberAgenda && documentViewer.addEventListener("zoomUpdated", onZoomUpdated);
				isAgendaScreen && document.addEventListener("fullscreenchange", onFullScreenChangeHandler);
				customizationOptions?.fullScreenCustom &&
					document.addEventListener("fullscreenchange", () => {
						onCustomFullScreenChangeHandler(instance.current, refShowCompareMode.current, isMemberAgenda);
					});
				if (!publicUser) {
					annotationManager.addEventListener("annotationChanged", annotationChangedHandler);
				}
				setUIElements(UI);
				let printInternalMethod = null;
				if (isMemberAgenda) {
					printInternalMethod = loadFullDocumentWithAttachments;
				}
				if (customizationOptions) {
					setCustomUIElements(inst, customizationOptions, printInternalMethod);
				}
				if (downloadAnnotations) {
					UI.updateElement("downloadButton", {
						onClick: (e) => {
							UI.downloadPdf({
								includeAnnotations: true,
							});
							e.stopPropagation();
						},
					});
				}
				if (enableSignatures) {
					UI.enableElements(["signatureToolGroupButton"]);
					UI.setToolbarGroup("toolbarGroup-Insert");
				} else {
					UI.setToolbarGroup("toolbarGroup-View");
				}
				if (watermark) {
					documentViewer.setWatermark({
						diagonal: {
							fontSize: 80,
							fontFamily: "sans-serif",
							color: "black",
							opacity: 30,
							text: watermark.text,
						},
					});
				}
				setToolStyles(documentViewer, Annotations);
				UI.setPrintQuality(highQualityPrint);
				setSkiptoOutlineUI();
				isMemberAgenda && UI.addEventListener(UI.Events.MULTI_VIEWER_READY, onMultiViewerReady);
			});

			viewerPromise.current = null;
		}
	}, [viewerPromise.current, useBlob ? fileBlob : url]);

	useEffect(() => {
		if (instance.current && customizationOptions && customizationOptions?.downloadOriginal) {
			updateCustomUIElements(instance.current, customizationOptions);
		}
	}, [customizationOptions]);

	useEffect(() => {
		if (instance.current && currentCustomAnnotation.current) {
			instance.current.UI.setHeaderItems((header) => {
				const items = header.getItems();
				const customAnnotationMenu = items.find((item) => item.dataElement === "customAnnotationMenu");
				if (customAnnotationMenu) {
					(items[items.indexOf(customAnnotationMenu)] = {
						type: "customElement",
						render: () => (
							<CustomAnnotationPopup
								instance={instance.current}
								currentCustomAnnotation={currentCustomAnnotation.current}
								setCurrentCustomAnnotation={setCurrentCustomAnnotation}
							/>
						),
						dataElement: "customAnnotationMenu",
					}),
						header.update([...items]);
				}
			});
		}
	}, [customannotation]);

	useEffect(() => {
		webviewerRef.current = widthWebviewer;
		if (instance.current && !instance.current.UI.isElementDisabled("menuButton")) {
			onToolbarWidthChange();
		}
	}, [widthWebviewer, instance.current]);

	//This useEffect is used to call load attachment document whenever user clicks on attachment in sidepanel or in the document
	useEffect(() => {
		if (loadAttachment && attachmentData) {
			loadAttachmentDocument();
		}
	}, [attachmentData, loadAttachment]);

	useEffect(() => {
		if (agendaItemsForBookmarks && mainDocumentLoaded && !refBookmarksData.current) {
			setBookMarkDataForInitialDocument();
		}
	}, [agendaItemsForBookmarks, mainDocumentLoaded]);

	//This useEffect is called when user clicked on any of the heading/subheading in sidepanel to trigger search functionality
	useEffect(() => {
		if (activeOutlineItem && instance.current) {
			setActiveOutlineItem(activeOutlineItem);
			if (refInitialDocumentLoaded.current || refdocumentWithAttachment.current) {
				onOutlineClick(activeOutlineItem);
			}
		}
	}, [activeOutlineItem, refInitialDocumentLoaded.current, refdocumentWithAttachment.current]);

	//This useEffect is called when attachment is opened and whcn click on other heading of sidepanel it will load the initial document again
	useEffect(() => {
		if (loadOriginalDoc) {
			loadInitialDocument();
		}
	}, [loadOriginalDoc]);

	useEffect(() => {
		if (instance.current) {
			setDrawerModeForCustomAnnotationMenu(instance, webviewerRef);
		}
	}, [widthWebviewer, instance.current]);

	useEffect(() => {
		if (instance.current) {
			const { UI } = instance.current;
			const isMobileWidth = widthWebviewer !== 0 && widthWebviewer <= 471;
			const compareElements = [
				"viewControlsButton",
				"panToolButton",
				"selectToolButton",
				"showComparisonButton",
				"policy-compare",
				"compare-full-screen",
				"comparePanelToggleButton",
			];
			const compareMobileElements = ["comparisonLabelMobile"];
			if (isMobileWidth && refShowCompareMode.current) {
				UI.disableElements(compareElements);
				UI.setHeaderItems((header) => {
					const items = header.getItems();
					const comparisonLabelMobile = items.find((item) => item.dataElement === "comparisonLabelMobile");
					const toggleNotesButton = items.find((item) => item.dataElement === "toggleNotesButton");
					if (!comparisonLabelMobile) {
						items.splice(items.indexOf(toggleNotesButton) - 1, 0, {
							type: "customElement",
							render: () => <label style={{ color: "white" }}>Compare Policy</label>,
							dataElement: "comparisonLabelMobile",
						});
						header.update([...items]);
					} else {
						UI.enableElements(["comparisonLabelMobile"]);
					}
				});
			} else {
				UI.enableElements(compareElements);
				UI.disableElements(compareMobileElements);
				setDrawerModeForCustomAnnotationMenu(instance, webviewerRef);
			}
		}
	}, [widthWebviewer, refShowCompareMode.current]);

	useEffect(() => {
		if (instance.current && policyEnabled) {
			instance.current.UI.setHeaderItems((header) => {
				const items = header.getItems();
				const policyCompare = items.find((item) => item.dataElement === "policy-compare");
				if (isPolicyAttachment) {
					if (!attachmentData?.isPolicy || attachmentData?.sourcePolicyGuid === "") {
						if (policyCompare) {
							items.splice(items.indexOf(policyCompare), 2);
							header.update([...items]);
						}
						return;
					}
					if (!policyCompare) {
						const highlightButton = items.find((item) => item.dataElement === "highlightToolGroupButton");
						const compareButtons = [
							{
								type: "customElement",
								render: () => (
									<>
										<button
											class="comparePolicyText"
											onClick={() => {
												telemetryAddEvent("Agenda View - Policy Opening Compare");
												openComparePolicySideBar(policyCompareDetail.current);
											}}
										>
											<span class="comparePolicySpan">Compare Policy</span>
										</button>
										<button
											class="comparePolicyIcon"
											onClick={() => {
												telemetryAddEvent("Agenda View - Policy Opening Compare");
												openComparePolicySideBar(policyCompareDetail.current);
											}}
										>
											<PolicyCompare />
										</button>
									</>
								),
								dataElement: "policy-compare",
							},
							{
								type: "spacer",
								hidden: [],
							},
						];
						if (highlightButton) {
							items.splice(items.indexOf(highlightButton) - 1, 0, ...compareButtons);
							header.update([...items]);
						}
					}
				} else {
					if (policyCompare) {
						items.splice(items.indexOf(policyCompare), 2);
						header.update([...items]);
						setHeaderForCompareMode();
					}
				}
			});
		}
	}, [isPolicyAttachment, attachmentData]);

	useEffect(() => {
		if (instance.current && showCompareMode && selectedCompareDoc) {
			setHeaderForCompareMode();
			const { UI } = instance.current;
			UI.exitMultiViewerMode();
			refShowCompareMode.current = true;
			setSelectedCompareDoc(selectedCompareDoc);
			setloadingDocumentInProgress(true);
			instance.current.Core.documentViewer.getDocument().setFilename(`Current Draft - ${attachmentData?.title}`);
			setIsCompareModeOn(true);
			UI.enterMultiViewerMode();
			setShowSideBar(false);
			setShowCompareHeader(false);
			window.onbeforeunload = () => {
				if (instance.current && instance.current.Core) {
					const { Core } = instance.current;
					removeDiffCustomAnnotations(Core.documentViewer);
				}
			};
		}
	}, [showCompareMode, selectedCompareDoc]);

	useEffect(() => {
		if (instance.current) {
			instance.current.UI.setHeaderItems((header) => {
				const items = header.getItems();
				const comparePanelButton = items.find((item) => item.dataElement === "showComparisonButton");
				if (comparePanelButton) {
					items[items.indexOf(comparePanelButton)] = {
						type: "customElement",
						render: () => <Slider />,
						dataElement: "showComparisonButton",
					};
					header.update([...items]);
				}
			});
		}
	}, [checked, instance.current]);

	useEffect(() => {
		if (isMemberAgenda && agendaItems.length > 0) {
			setAgendaItemsRef(agendaItems);
		}
	}, [agendaItems]);

	const onToolbarWidthChange = () => {
		const annotationTogglePresent = isAgendaScreen || isMemberAgenda;
		if (webviewerRef.current !== 0 && webviewerRef.current <= 471) {
			setCustomSearchAnnotations(instance.current, annotationTogglePresent);
		} else if (webviewerRef.current !== 0) {
			removeCustomSearchAnnotations(instance.current, annotationTogglePresent);
		}
	};

	const onZoomUpdated = (e) => {
		if (e !== userZoomLevel.current) {
			userZoomLevel.current = e;
		}
	};

	const toolModeUpdatedhandler = (newTool) => {
		const activeToolName = annotationToolMode.find((e) => e === newTool.name);
		if (activeToolName) {
			setCurrentCustomAnnotation(toolModes[activeToolName]);
		} else if (currentCustomAnnotation.current !== toolModes.default) {
			setCurrentCustomAnnotation(toolModes.default);
		}
	};

	const onMultiViewerReady = () => {
		const { UI, Core } = instance.current;
		const { Annotations, documentViewer } = Core;
		const { Color } = Annotations;
		documentViewer.removeEventListener("zoomUpdated", onZoomUpdated);
		const [, documentViewer2] = Core.getDocumentViewers();
		let url = getUrlForPolicyCompare(refSelectedCompareDoc.current);
		documentViewer2.loadDocument(url, {
			filename: getFileNameForComparePolicy(refSelectedCompareDoc.current),
		});
		documentViewer2.setOpenURIHandler((target) => {
			window.open(target, "_blank", "noopener");
		});
		updateZoomIconsForCompareMode(UI);
		const startCompare = async () => {
			removeDiffCustomAnnotations(Core.documentViewer);
			const shouldCompare = documentViewer.getDocument() && documentViewer2.getDocument();
			if (shouldCompare) {
				let pageContent = await documentViewer2.getDocument().loadPageText(1);
				if (pageContent === "") {
					setloadingDocumentInProgress(false);
					disableCompareMode();
					displayErrorNotification(t("comparePolicy.emptyDocumentMsg"), dispatch);
				}
				// Check if both documents loaded before comparing
				const beforeColor = new Color(21, 205, 131, 0.4);
				const afterColor = new Color(255, 73, 73, 0.4);
				const options = { beforeColor, afterColor };
				const { doc1Annotations, doc2Annotations, diffCount } = await documentViewer.startSemanticDiff(documentViewer2, options);
				displayDiffCustomAnnotations(instance, doc1Annotations, doc2Annotations);
				UI.enableMultiViewerSync(1);
				await UI.setHeaderItems((header) => {
					const item = header.getItems();
					const comparePanelPresent = item.find((item) => item.dataElement === "comparePanelToggleButton");
					const searchButton = item.find((item) => item.dataElement === "searchButton");
					const comparisonLabelMobile = item.find((item) => item.dataElement === "comparisonLabelMobile");
					const isMobileWidth = widthWebviewer !== 0 && widthWebviewer <= 471;
					let togglePosition = item.find((item) => item.toolName === "AnnotationEdit");
					if (!togglePosition) {
						togglePosition = item.find((item) => item.toolName === "Pan");
					}
					if (!comparePanelPresent) {
						item.splice(
							item.indexOf(togglePosition) + 1,
							0,
							{
								type: "divider",
							},
							{
								type: "customElement",
								render: () => <Slider />,
								dataElement: "showComparisonButton",
							},
						);
						item.splice(
							item.length - 1,
							0,
							{
								dataElement: "comparePanelToggleButton",
								hiddenOnMobileDevices: true,
								img: "icon-header-compare",
								type: "toggleElementButton",
								element: "comparePanel",
								title: "component.comparePanel",
								hidden: ["small-mobile"],
							},
							{
								dataElement: "compare-full-screen",
								type: "actionButton",
								img: isCSSFullScreenRequired ? getImgForFullScreen() : getImgForCssFullScreen(),
								title: isCSSFullScreenRequired ? getTitleForFullScreen() : getTitleForCssFullScreen(),
								onClick: () => {
									customToggleFullScreen(document.getElementById("memberAgendaWrapper"), instance.current);
								},
							},
							{
								dataElement: "close-compare-mode",
								type: "actionButton",
								img: closeButton,
								title: "Close Compare",
								onClick: () => {
									telemetryAddEvent("Agenda View - Policy Closing Compare");
									disableCompareMode();
								},
							},
						);
						if (isMobileWidth) {
							item.splice(item.indexOf(comparisonLabelMobile) - 1, 1);
						} else {
							item.splice(item.indexOf(searchButton) - 1, 1);
						}
						header.update([...item]);
					}
				});
				UI.openElement("comparePanel");
				setloadingDocumentInProgress(false);
				UI.updateElement("compare-full-screen", {
					img: isCSSFullScreenRequired ? getImgForFullScreen() : getImgForCssFullScreen(),
					title: isCSSFullScreenRequired ? getTitleForFullScreen() : getTitleForCssFullScreen(),
				});
			}
		};
		documentViewer2.addEventListener("documentLoaded", startCompare);
	};

	const toggleComparisonMode = (e) => {
		setChecked(!checked);
		const [documentViewer, documentViewer2] = instance.current.Core.getDocumentViewers();
		const annotationManager = documentViewer.getAnnotationManager();
		const annotationManager2 = documentViewer2.getAnnotationManager();
		if (e.target.checked) {
			const doc1Annotations = annotationManager.getSemanticDiffAnnotations(1);
			const doc2Annotations = annotationManager2.getSemanticDiffAnnotations(2);
			annotationManager.showAnnotations(doc1Annotations, 1);
			annotationManager2.showAnnotations(doc2Annotations, 2);
			displayDiffCustomAnnotations(instance, doc1Annotations, doc2Annotations);
		} else {
			annotationManager.hideAnnotations(annotationManager.getSemanticDiffAnnotations(1), 1);
			annotationManager2.hideAnnotations(annotationManager2.getSemanticDiffAnnotations(2), 2);
			removeDiffCustomAnnotations(documentViewer);
			removeDiffCustomAnnotations(documentViewer2);
		}
	};

	const Slider = () => {
		return (
			<div class="checkboxcontainer">
				<input type="checkbox" checked={checked} onChange={(e) => toggleComparisonMode(e)}></input>
				<label style={{ fontSize: "unset" }}>Show Comparison</label>
			</div>
		);
	};

	const disableCompareMode = async () => {
		const { UI, Core } = instance.current;
		const officeExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "tif", "tiff"];
		refShowCompareMode.current = false;
		setTimeout(() => {
			UI.setZoomLevel(userZoomLevel.current);
		}, 100);
		Core.documentViewer.addEventListener("zoomUpdated", onZoomUpdated);
		if (loadAttachment) {
			instance.current.Core.documentViewer
				.getDocument()
				.setFilename(
					`${attachmentData?.title}${
						officeExtensions.includes(attachmentData?.fileExtension) ? ".pdf" : `.${attachmentData?.fileExtension}`
					}`,
				);
		} else {
			Core.documentViewer.getDocument().setFilename(`${createFileName()}.pdf`);
		}
		UI.exitMultiViewerMode();
		updateZoomIcons(UI);
		if (refIsPolicyAttachment.current) {
			UI.enableElements(["policy-compare"]);
		}
		resetComparePolicySelection();
		UI.setHeaderItems((header) => {
			const items = header.getItems();
			const comparePanelButton = items.find((item) => item.dataElement === "comparePanelToggleButton");
			const showComparisonButtonPresent = items.find((item) => item.dataElement === "showComparisonButton");
			const stickyToolGroupButton = items.find((item) => item.dataElement === "stickyToolGroupButton");
			if (comparePanelButton || showComparisonButtonPresent) {
				items.splice(items.indexOf(comparePanelButton), 3);
				items.splice(items.indexOf(showComparisonButtonPresent) - 1, 2);
				items.splice(items.indexOf(stickyToolGroupButton) + 1, 0, {
					type: "divider",
					hidden: [],
				});
				header.update([...items]);
			}
			UI.enableElements(compareHiddenTools);
			webviewerRef.current !== 0 && webviewerRef.current <= 472 && UI.disableElements(["toggleNotesButton", "searchButton"]);
			!UI.isElementDisabled("menuButton") && onToolbarWidthChange();
			if (!refShowSideBar.current) {
				setShowSideBar(true);
			}
			return;
		});
		removeDiffCustomAnnotations(Core.documentViewer);
	};

	const closeGoalPopup = () => {
		setShowGoal(false);
	};

	const setHeaderForCompareMode = () => {
		if (isPolicyAttachment) {
			instance.current.UI.disableElements(compareHiddenTools);
		} else {
			instance.current.UI.enableElements(compareHiddenTools);
		}
	};

	const setToolStyles = (documentViewer, Annotations) => {
		documentViewer.getTool("AnnotationCreateSticky").setStyles({
			StrokeColor: new Annotations.Color(146, 232, 232),
		});

		documentViewer.getTool("AnnotationCreateFreeHand").setStyles({
			StrokeThickness: 4,
		});
	};

	const onFullScreenChangeHandler = () => {
		if (document.fullscreenElement) {
			setFullScreenMode(true);
		} else {
			setFullScreenMode(false);
		}
		reArrangeHeaderForFullScreenMode(instance.current, refFullscreenMode.current, t);
	};

	const setSkiptoOutlineUI = () => {
		const parentSkipTag = document.querySelector("div[class='skiptocontent']");
		const skiptocontent = document.querySelector("div[class='skiptocontent']>a");
		if (!skiptocontent) {
			let a2 = document.createElement("a");
			a2.href = `#${IFRAME_ID}`;
			a2.textContent = "Skip to Content";
			a2.id = "skiptodocument";
			parentSkipTag.appendChild(a2);
		} else {
			skiptocontent.setAttribute("href", `#${IFRAME_ID}`);
		}
		if (showSidePanel) {
			let a = document.createElement("a");
			a.href = "#toc";
			a.textContent = "Skip to outline";
			a.id = "skiptooutline";
			parentSkipTag.appendChild(a);
		}
	};

	const resetPdfPassword = () => {
		setDialogs((prev) => ({
			...prev,
			password: undefined,
		}));
	};

	const getPdfPassword = (callback) => {
		// Get a password from the user if the document is password protected
		setDialogs((prev) => ({
			...prev,
			password: (password) => {
				resetPdfPassword();
				callback(password);
			},
		}));
	};

	const jumpToActive = () => {
		// Jump to the active item if needed
		if (typeof bottomNotice?.actionAfterNavigate === "function") {
			bottomNotice.actionAfterNavigate();
		}
	};

	const createFileName = () => {
		if (meetingDownloadDate && meetingName) {
			let tempArray = typeof meetingDownloadDate === "string" && meetingDownloadDate.split(" ");
			let tempDate = [tempArray[1], tempArray[0], tempArray[2]].join(" ");
			return meetingName.includes(tempDate) ? meetingName : `${meetingDownloadDate}`;
		} else if (meetingDate && meetingName) {
			let tempArray = typeof meetingDate === "string" && meetingDate.split(" ");
			let tempDate = [tempArray[1], tempArray[0], tempArray[2]].join(" ");
			return meetingName.includes(tempDate) ? meetingName : `${meetingName} - ${meetingDate}`;
		}
		return "FullAgenda";
	};

	const startMenuButtonObserver = () => {
		// Observe the menu button to set the aria-expanded attribute when it gets toggled
		mutationObserver.current?.disconnect();
		mutationObserver.current = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (mutation.target) {
					mutation.target.setAttribute("aria-expanded", mutation.target.classList.contains("active"));
				}
			}
		});
		mutationObserver.current.observe(
			document.getElementById(IFRAME_ID).contentDocument.querySelector('button.Button[data-element="menuButton"]'),
			{
				attributes: true,
				attributeFilter: ["class"],
			},
		);
	};

	const loadInitialDocument = () => {
		if (instance.current && ((useBlob && fileBlob) || (!useBlob && url)) && !initialDocumentLoaded) {
			generateDocument();
		}
	};

	//This method is used for loading initial document
	const loadDocument = () => {
		if (instance.current && ((useBlob && fileBlob) || (!useBlob && url)) && !initialDocumentLoaded && !documentWithAttachment) {
			generateDocument();
		}
	};

	const generateDocument = () => {
		if (id === 0) {
			setloadingDocumentInProgress(false);
		}
		if (id > 0) {
			if (isCompareModeOn) {
				setIsCompareModeOn(false);
				disableCompareMode();
			}
			isPolicyAttachment && setIsPolicyAttachment(false);
			setloadingDocumentInProgress(true);
			resetPdfPassword();

			instance.current.Core.documentViewer
				.loadDocument(useBlob ? fileBlob : url, {
					extension: "pdf",
					filename: `${createFileName()}.pdf`,
					password: getPdfPassword,
				})
				.then(() => {
					setTimeout(jumpToActive, 2000);
					setTimeout(startMenuButtonObserver, 2000);
				});
			setSelectedDocumentData({
				id,
				title: `${meetingName} - ${meetingDate}`,
				url,
				fileExtension: "pdf",
				documentOriginalName,
			});
			setdocumentWithAttachment(false);
			setSelectedDocumentId(id);
			setinitialDocumentLoaded(true);
		}
	};

	//This method is used for loading attachment document clicked from side panel or in the document
	const loadAttachmentDocument = () => {
		if (instance.current) {
			if (isCompareModeOn) {
				setIsCompareModeOn(false);
				disableCompareMode();
			}
			setloadingDocumentInProgress(true);
			const { title, url, fileExtension, isPolicy = false } = attachmentData;
			const xodConversionExtensions = ["bmp", "emf", "jpeg", "png", "tif", "xps", "pdf", "docx", "xlsx", "pptx", "txt"];
			const viewerAllowedExtensions = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "png", "jpg", "jpeg", "tif", "tiff"];
			const officeExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "tif", "tiff"];
			const fitPageExtensions = ["ppt", "pptx"];

			telemetryAddEvent("Agenda View - Load attachment");

			if (viewerAllowedExtensions.includes(fileExtension.toLowerCase())) {
				let documentUrl = url;
				const separator = documentUrl.includes("?") ? "&" : "?";
				if (xod && xodConversionExtensions.includes(fileExtension)) {
					documentUrl = `${documentUrl + separator}xod=true`;
				} else {
					documentUrl = `${
						documentUrl + (officeExtensions.includes(fileExtension) ? `${documentUrl.includes("?") ? "&" : "?"}printPdf=true` : "")
					}`;
				}
				setdocumentWithAttachment(false);
				setDocumentVersionSelected(false);
				setSelectedDocumentId(attachmentData.documentId);
				attachmentData["documentOriginalName"] = attachmentData.originalName
					? attachmentData.originalName
					: attachmentData.documentOriginalName;
				setSelectedDocumentData(attachmentData);
				resetPdfPassword();
				instance.current.Core.documentViewer
					.loadDocument(documentUrl, {
						streaming: true,
						extension: officeExtensions.includes(fileExtension) ? "pdf" : fileExtension,
						filename: title + (officeExtensions.includes(fileExtension) ? ".pdf" : `.${fileExtension}`),
						password: getPdfPassword,
					})
					.then(() => {
						if (fitPageExtensions.includes(fileExtension)) {
							instance.current.UI.setFitMode(instance.current.UI.FitMode.FitPage);
						} else {
							instance.current.UI.setZoomLevel(1);
						}
					});
				setinitialDocumentLoaded(false);
				setLoadOriginalDocFromPDF(false);
				setActiveOutlineItem(null);
				if (isPolicy) {
					policyCompareDetail.current = attachmentData;
					setIsPolicyAttachment(true);
				} else {
					setIsPolicyAttachment(false);
				}
			} else {
				window.open(url, "_blank", "noopener");
			}
		}
	};

	//This method is used to set UI Elements and header items of Pdftron Toolbar
	const setUIElements = (UI) => {
		UI.createToolbarGroup({
			name: "Tools",
			dataElementSuffix: "Draw",
			useDefaultElements: true,
			children: [
				{
					dataElement: "highlightToolGroupButton",
					title: "annotation.highlight",
					toolGroup: "highlightTools",
					type: "toolGroupButton",
				},
				{
					dataElement: "underlineToolGroupButton",
					title: "annotation.underline",
					toolGroup: "underlineTools",
					type: "toolGroupButton",
				},
				{
					dataElement: "freeHandToolGroupButton",
					title: "annotation.freehand",
					toolGroup: "freeHandTools",
					type: "toolGroupButton",
				},
			],
		});
		UI.setHeaderItems((header) => {
			if (enableSignatures) {
				UI.enableTools(["AnnotationCreateSignature"]);
			}
			setHeaderConfigurations(header);
		});
		updateToolBarIcons(UI);
	};

	const setHeaderConfigurations = (header) => {
		const headerItems = [
			...(showSidePanel
				? [
						{
							dataElement: "sideBarButton",
							element: "sideBar",
							img: "icon-header-sidebar-line",
							title: "Side Bar",
							type: "actionButton",
							onClick: () => {
								setShowSideBar(!refShowSideBar.current);
								if (refShowSideBar.current) {
									telemetryAddEvent(`Agenda View - Outline hide`);
								} else {
									telemetryAddEvent(`Agenda View - Outline Show`);
								}
							},
						},
					]
				: []),
			header.getItems()[3],
			{ type: "divider" },
			{
				...header.getItems()[5],
				hiddenOnMobileDevice: false,
				hidden: ["small-mobile", "mobile"],
			},
			{
				type: "divider",
				hiddenOnMobileDevice: false,
				hidden: ["small-mobile", "mobile"],
			},
			header.getItems()[7],
			{
				hidden: [],
				toolName: "AnnotationEdit",
				type: "toolButton",
			},
			...(isAgendaScreen
				? [
						{
							type: "spacer",
						},
						{
							dataElement: "view-full-screen",
							type: "actionButton",
							label: t("buttons.fullScreen"),
							onClick: () => {
								customToggleFullScreen(document.getElementById("viewer"), instance.current, t);
							},
						},
					]
				: []),
			header.getItems()[10],
			{
				type: "spacer",
				hidden: [],
			},
			...(isMemberAgenda || isAgendaScreen
				? [
						{
							type: "customElement",
							render: () => (
								<CustomAnnotationPopup
									instance={instance.current}
									currentCustomAnnotation={currentCustomAnnotation.current}
									setCurrentCustomAnnotation={setCurrentCustomAnnotation}
								/>
							),
							dataElement: "customAnnotationMenu",
						},
					]
				: []),
			{
				dataElement: "highlightToolGroupButton",
				title: "annotation.highlight",
				toolGroup: "highlightTools",
				type: "toolGroupButton",
			},
			{
				dataElement: "underlineToolGroupButton",
				title: "annotation.underline",
				toolGroup: "underlineTools",
				type: "toolGroupButton",
			},
			{
				dataElement: "freeHandToolGroupButton",
				title: "annotation.freehand",
				toolGroup: "freeHandTools",
				type: "toolGroupButton",
			},
			{
				dataElement: "stickyToolGroupButton",
				title: "annotation.stickyNote",
				toolGroup: "stickyTools",
				type: "toolGroupButton",
			},
			...(headerEndDivider
				? [
						{
							type: "divider",
							hidden: [],
						},
					]
				: []),
			header.getItems()[11],
			{
				...header.getItems()[12],
				hidden: [], // Prevent the notes panel from hiding on mobile
			},
			header.getItems()[0],
		];
		header.update(headerItems);
		instance.current.UI.enableElements(["header"]);
	};

	//This method wil get called when any type of annotation change is done by user - Highlight,Underline,sticky notes,Free hand pen tool.
	const annotationChangedHandler = (annotations, type, { imported }) => {
		if (imported || refdocumentWithAttachment.current) {
			return;
		}
		const annotationManager = instance.current.Core.annotationManager;
		const documentOriginalName = refselectedDocumentData.current != null ? refselectedDocumentData.current.documentOriginalName : "";
		const currentDocumentId = refselectedDocumentId.current;

		annotations.forEach((annotation) => {
			switch (type) {
				case "add":
				case "modify":
					if (saveAnnotationAction != null) {
						annotationManager.exportAnnotationCommand().then((exportedAnnotation) => {
							telemetryAddEvent(
								`${isMemberAgenda ? telemetryTitleForMemberAgenda : telemetryForAgendaItem} - Annotation ${annotation.Subject}${
									annotation.isReply() ? "InReplyTo" : ""
								}`,
							);
							const paramObject = {
								documentId: currentDocumentId,
								documentOriginalName,
								annotationId: annotation.Id,
								exportedAnnotation,
								userId,
								showSignIn,
							};
							saveAnnotationAction(paramObject);
						});
					}
					break;

				case "delete":
					telemetryAddEvent(`${isMemberAgenda ? telemetryTitleForMemberAgenda : telemetryForAgendaItem} - Delete annotation`);
					deleteAnnotationAction(currentDocumentId, annotation.Id, userId, showSignIn);
					break;

				default:
					break;
			}
		});
	};

	//This method is used to search data in the document using bookmarks.
	const searchBookmarksWithGuid = (guid, isChildren) => {
		const { documentViewer } = instance.current.Core;
		let bookmarkPlace = null;
		agendaItemsForBookmarks &&
			agendaItemsForBookmarks.find((mainItem, mainIndex) => {
				if (isChildren && mainItem.children) {
					mainItem.children.find((childItem, childrenIndex) => {
						if (childItem.guid === guid && refBookmarksData.current) {
							bookmarkPlace = refBookmarksData.current[mainIndex]?.children[childrenIndex];
							bookmarkPlace && documentViewer.displayBookmark(bookmarkPlace);
							return true;
						}
					});
				} else {
					if (mainItem.guid === guid && refBookmarksData.current) {
						bookmarkPlace = refBookmarksData.current[mainIndex];
						bookmarkPlace && documentViewer.displayBookmark(bookmarkPlace);
						return true;
					}
				}
				return bookmarkPlace;
			});
	};

	//This method is used when user clicked on any heading or sunheading other than attachment in sidepanel then search will get trigered on the basis of bookmarks
	const onOutlineClick = (itemData) => {
		const {
			itemType,
			fields: { Name, Number },
			guid,
			attributes: { relationshipGuid },
		} = itemData;
		let isChildren = false;
		isChildren = itemType === 3 || (itemType === 10 && relationshipGuid);
		if (Name.Value === "" && Number.Value) {
			documentSearch(Number.Value);
		} else if (itemType === 7 && Name.Value !== "") {
			let parsedText = parseHTMLContent(Name.Value);
			parsedText = parsedText.replace(/\s\s+/g, " ");
			parsedText = parsedText.substring(0, 20);
			documentSearch(parsedText);
		} else {
			searchBookmarksWithGuid(guid, isChildren);
		}
		setSearchHeading(null);
	};

	//This method is used if any heading or subheading is not present in bookmarkdata then manual search emthod will be called.
	const documentSearch = async (data) => {
		if (refInitialDocumentLoaded.current) {
			const { documentViewer, Search, Annotations } = instance.current.Core;
			const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
			const searchOptions = {
				fullSearch: false,
				onResult: (result) => {
					if (result.resultCode === Search.ResultCode.FOUND) {
						documentViewer.displaySearchResult(result);
					}
				},
			};

			documentViewer.textSearchInit(data, mode, searchOptions);
			documentViewer.setSearchHighlightColors({
				searchResult: new Annotations.Color(0, 0, 0, 0),
				activeSearchResult: "rgba(0, 0, 0, 0)",
			});
			setSearchHeading(null);
		}
	};

	//This method is used to parse sidepanel heading and subheading from html tags to content.
	const parseHTMLContent = (s) => {
		var span = document.createElement("span");
		span.innerHTML = s;
		if (isStaffUser) {
			let text = "";
			span.firstChild &&
				span.firstChild.childNodes &&
				span.firstChild.childNodes.forEach((e) => {
					if (e.nodeType === 3) {
						text += `${e.textContent}`;
					} else if (e.nodeType === 1) {
						if (e.className === "inlineFile closed") {
							text += ``;
						} else {
							text += `${e.textContent}`;
						}
					}
				});
			return text;
		}
		return span.textContent || span.innerText;
	};

	//This method will be called every time when document is succesfully loaded
	const documentLoadedHandler = () => {
		if (instance.current) {
			const { UI } = instance.current;
			const pdfViewIframe = UI.iframeWindow.document;
			pdfViewIframe.querySelector("iframe").title = "PDF Document";
			if (viewer.current) {
				viewer.current.querySelector("iframe").id = IFRAME_ID;
			}
			if (UI.getZoomLevel() !== userZoomLevel.current) {
				UI.setZoomLevel(userZoomLevel.current);
			}
			enableSignatures && setSignatureButtonUI(pdfViewIframe, enableSignatures, instance.current, userId, dispatch, t);
			(isMemberAgenda || isAgendaScreen) && setCustomAnnotationClick(pdfViewIframe, instance.current);
		}
		if (refInitialDocumentLoaded.current) {
			setMainDocumentLoaded(refInitialDocumentLoaded.current);
		}
		if (
			documentAnnotationVersions &&
			documentAnnotationVersions.current &&
			documentAnnotationVersions.current.length > 0 &&
			!refdocumentWithAttachment.current
		) {
			setversionsDialogOpen(true);
		} else if (loadAnnotationsAction && !refdocumentWithAttachment.current) {
			loadAnnotationsAction(refselectedDocumentId.current, refselectedDocumentData.current?.documentOriginalName, showSignIn).then(
				loadAnnotationsCallback,
			);
		}
		if (refdocumentWithAttachment.current) {
			loadAllAnnotationsCallback();
		}
		setloadingDocumentInProgress(false);

		// Add custom styles to the viewer
		const iframeDocument = viewer.current?.querySelector("iframe")?.contentDocument;
		if (iframeDocument) {
			const style = document.createElement("style");
			style.innerHTML = `
                .App .content .right-panel {
                    z-index: 71;
                }

                .App .ui__choice__input .ui__choice__input__check,
                .App .content .right-panel .input-container,
                .App .content .right-panel input[type="text"] {
                    border-color: ${blackColor[0]};
                }

                .App .ui__choice__input .ui__choice__input__check--focus,
                .App .content .right-panel .input-container:focus-within {
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
                }
            `;
			iframeDocument.head.appendChild(style);
		}
	};

	//This method is called when attachments link inside document is clicked
	const pdfContentLinkClick = (target) => {
		let linkUrl = target.replace("http://", "").replace("https://", "");
		if (!linkUrl.endsWith("/")) {
			linkUrl = linkUrl + "/";
		}
		if ((isMemberAgenda || isGoalAdded) && linkUrl.includes("goals")) {
			setShowGoal(true);
			setGoalLink(linkUrl);
		}
		if (linkUrl.indexOf(window.location.host) < 0 && !linkUrl.startsWith("/")) {
			window.open(target, "_blank", "noopener");
		} else {
			let foundAttachment = false;
			if (attachments) {
				attachments.forEach((attachment) => {
					const { documentId, title, url, fileExtension, originalName } = attachment;
					if (linkUrl.includes(url) || linkUrl.includes(originalName)) {
						foundAttachment = true;
						let policyAttachmentDetails = {};
						const agendaItem = refAgendaItems.current?.find((e) => e.guid === attachment.guid);
						if (agendaItem?.attachments?.length > 0) {
							policyAttachmentDetails = agendaItem.attachments.find((e) => e.isPolicy && e.url.includes(url));
						}
						const attachmentData = {
							id: documentId,
							documentId,
							title,
							url,
							fileExtension,
							documentOriginalName: originalName,
							...(policyAttachmentDetails?.isPolicy && {
								isPolicy: policyAttachmentDetails.isPolicy,
								sourcePolicyGuid: policyAttachmentDetails.sourcePolicyGuid,
								policyGuid: policyAttachmentDetails.guid,
							}),
						};
						setDataForAttachmentLink(attachmentData);
						if (attachmentData.isPolicy) {
							policyCompareDetail.current = attachmentData;
						}
					}
				});
			}

			if (!foundAttachment && !linkUrl.includes("goals")) {
				// Non-attachment internal link
				window.open(target, "_blank", "noopener");
			}
		}
	};

	//This method is used to create bookmarks on the basis of existing meetingItems.
	const setBookMarkDataForInitialDocument = async () => {
		const { PDFNet, documentViewer } = instance.current.Core;
		await PDFNet.initialize();
		const doc = documentViewer.getDocument();
		let bookmarks = await doc.getBookmarks();
		if (agendaItemsForBookmarks && agendaItemsForBookmarks.length > 1 && bookmarks) {
			// If bookmarks come as a single object, extract the children
			if (bookmarks.length === 1) {
				bookmarks = bookmarks[0]?.children;
			}
			// If bookmarks are in the other index for some templates, handle accordingly
			else if (bookmarks.length > 1 && bookmarks.length < agendaItemsForBookmarks.length) {
				// Loop through the bookmarks to find the correct index where bookmarks are present
				for (let bookmark of bookmarks) {
					// Check if bookmark children match the length of agendaItemsForBookmarks
					if (bookmark.children.length >= agendaItemsForBookmarks.length) {
						bookmarks = bookmark.children;
						break;
					}
				}
			}
		}
		const agendaItemData = parseHTMLContent(agendaItemsForBookmarks[0]?.fields?.Name?.Value).replaceAll(/\s/g, "").toLowerCase();
		let index = 0;
		const tempData = [...bookmarks];
		while (tempData[index] && !agendaItemData.includes(tempData[index].name.replaceAll(/\s/g, "").toLowerCase())) {
			bookmarks.shift();
			index = index + 1;
		}
		setBookmarksData(bookmarks);
	};

	//This method wil be triggered when loadFullDocumentWithAttachments api gives successful response.
	const loadAllAnnotationsCallback = () => {
		let docViewer = instance.current.Core.documentViewer;
		const annotationManager = instance.current.Core.annotationManager;
		setLoadingAllAnnotations(true);

		loadAllAnnotations(meetingId, showSignIn)
			.then((annotationDocuments) => {
				if (annotationDocuments && annotationDocuments.length > 0) {
					annotationDocuments.forEach((annotationDocument, i) => {
						if (annotationDocument && annotationDocument.annotations) {
							annotationDocument.annotations.forEach((annotation, j) => {
								const parser = new DOMParser();
								const xfdfElements = parser.parseFromString(annotation, "text/xml");
								if (!annotationDocument.agenda) {
									const pageElement = xfdfElements.querySelector("[page]");
									const currentPageNumber = parseInt(pageElement.getAttribute("page"));
									const newPageNumber = annotationDocument.startPage + currentPageNumber - 1;
									pageElement.setAttribute("page", `${newPageNumber > 0 ? newPageNumber : 0}`);
								}
								const serializer = new XMLSerializer();
								const updatedXfdfString = serializer.serializeToString(xfdfElements);
								annotationManager.importAnnotationCommand(updatedXfdfString);
							});
						}

						if (i === annotationDocuments.length - 1) {
							setLoadingAllAnnotations(false);
							docViewer.refreshAll();
							instance.current.UI.print();
							setdocumentWithAttachment(false);
						}
					});
				} else {
					setLoadingAllAnnotations(false);
					instance.current.UI.print();
					setdocumentWithAttachment(false);
				}
			})
			.catch((err) => {
				setLoadingAllAnnotations(false);
				instance.current.UI.print();
			});
	};

	//This method is triggered when click Print on memeber agenda in order to load documewnt with attachments linked to it with respective annotations.
	const loadFullDocumentWithAttachments = () => {
		refIsPolicyAttachment.current && setIsPolicyAttachment(false);
		setdocumentWithAttachment(true);
		resetPdfPassword();
		instance.current.Core.documentViewer.loadDocument(`/document/${fullAgendaPdfId}`, {
			streaming: true,
			extension: "pdf",
			filename: `${createFileName()}.pdf`,
			password: getPdfPassword,
		});
		setinitialDocumentLoaded(false);
		setLoadOriginalDocFromPDF(false);
		cleanAttachmentData();
	};

	//This method wil be triggered when loadannotations api gives successful response.
	const loadAnnotationsCallback = (annotationDataCollection) => {
		const annotationManager = instance.current.Core.annotationManager;

		if (selectedDocumentData == null || loadAllAnnotationVersions) {
			// Loading Agenda annotations
			for (let i = 0; i < annotationDataCollection.length; i += 1) {
				const annotationData = annotationDataCollection[i];
				for (let j = 0; j < annotationData.annotations.length; j += 1) {
					const annotation = annotationData.annotations[j];
					annotationManager
						.importAnnotationCommand(annotation)
						.then((importedAnnotations) => annotationManager.showAnnotations(importedAnnotations));
				}
			}
		} else if (!documentVersionSelected && annotationDataCollection && annotationDataCollection.length > 1 && selectedDocumentData) {
			// multiple attachment versions exist with annotations, show dialog to choose from attachment versions
			// add newest version to the list if there are no annotations on it
			const currentDocumentVersion = annotationDataCollection.find(
				(annotationData) => annotationData.documentOriginalName === selectedDocumentData.documentOriginalName,
			);
			if (!currentDocumentVersion) {
				annotationDataCollection.push(selectedDocumentData);
			}
			setversionsDialogOpen(true);
		} else if (annotationDataCollection && annotationDataCollection.length > 0) {
			const annotationsData = annotationDataCollection[0];
			if (
				annotationsData.documentOriginalName === selectedDocumentData.documentOriginalName ||
				selectedDocumentData.documentOriginalName == null ||
				annotationsData.id === selectedDocumentData.id
			) {
				// annotations exist for this attachment and there are no other versions
				for (let i = 0; i < annotationsData.annotations.length; i += 1) {
					const annotation = annotationsData.annotations[i];
					annotationManager
						.importAnnotationCommand(annotation)
						.then((importedAnnotations) => annotationManager.showAnnotations(importedAnnotations));
				}
			} else if (!documentVersionSelected) {
				// annotations exist but only for a different version, show dialog to choose from attachment versions
				annotationDataCollection.push(selectedDocumentData);
				setversionsDialogOpen(true);
			}
		}
	};

	//This method will triggered when after publishing the document user chooses Keep option for keeping annotation as previous version
	const handleKeepAnnotationsClick = () => {
		telemetryAddEvent(`${telemetryTitleForMemberAgenda} - Move all annotations`);
		moveAllAnnotationsAction(selectedDocumentData.id, meetingId, showSignIn).then(() => {
			resetDocumentAnnotationVersion();
			if (loadAnnotationsAction) {
				loadAnnotationsAction(selectedDocumentData.id, null, showSignIn).then(loadAnnotationsCallback);
			}
			setversionsDialogOpen(false);
		});
	};

	//This method will triggered when after publishing the document user chooses discard option for deleting all annotation from previous version
	const handleDiscardAnnotationsClick = () => {
		telemetryAddEvent(`${telemetryTitleForMemberAgenda} - Delete all annotations`);
		deleteAllAnnotationsAction(selectedDocumentData.id, meetingId, showSignIn).then(() => {
			resetDocumentAnnotationVersion();
			if (loadAnnotationsAction) {
				loadAnnotationsAction(selectedDocumentData.id, null, showSignIn).then(loadAnnotationsCallback);
			}
			setversionsDialogOpen(false);
		});
	};

	return (
		<>
			{dialogs.password ? <PdfPasswordDialog sendPassword={dialogs.password} /> : null}
			{showGoal ? <GoalsViewDialog goalLink={goalLink} closeGoalPopup={closeGoalPopup} /> : null}
			{(enableSignatures || showPrintButton) && instance.current && (
				<SignAndAdopt
					enableSignatures={enableSignatures}
					showPrintButton={showPrintButton}
					ti18n={t}
					settings={props.settings}
					instance={instance.current}
					id={id}
				/>
			)}
			<div className={classes.webviewerContainer} ref={viewer} id={isViewerId ? isViewerId : "viewer"}>
				{(loadingAllAnnotations || loadingDocumentInProgress) && (
					<>
						<div className={classes.headerBackgroundPosition}></div>
						<div
							id="loading"
							className={clsx(classes.loadingIndicatorContainer, {
								[classes.loadingIndicatorForCompareMode]: isCompareModeOn && widthDownMd,
							})}
						>
							<Box>
								<CircularProgressIndicator size={30} minHeight={"30"} style={{ color: "#2f3b4d" }} />
							</Box>
						</div>
					</>
				)}
				{widthDownMd && isAgendaScreen && !document.fullscreenElement && (
					<div id="agendaFullScreenContainer">
						<ButtonWithTooltip
							className={classes.viewFullScreenMobileContainer}
							variant="outlined"
							color="primary"
							onClick={() => {
								customToggleFullScreen(document.getElementById("viewer"), instance.current, t, true);
							}}
							title={t("tooltips.viewFullScreen")}
							data-cy="approve"
						>
							{t("buttons.viewFullScreen")}
						</ButtonWithTooltip>
						<div className={classes.backgroundMobileContainer} />
					</div>
				)}
				{documentAnnotationVersions && documentAnnotationVersions.current && documentAnnotationVersions.current.length > 0 && (
					<Dialog aria-labelledby="simple-dialog-title" open={versionsDialogOpen} fullScreen={widthDownSm}>
						<DialogTitle id="simple-dialog-title">
							<Typography variant="h3">{t("annotationsVersionChangeDialog.title")}</Typography>
						</DialogTitle>
						<DialogContent dividers>
							{t("annotationsVersionChangeDialog.listLabel")}
							<ul style={{ paddingLeft: "27px" }}>
								{documentAnnotationVersions.current.map((documentVersion, index) => (
									<li key={index} style={{ margin: "4px 0" }}>
										{documentVersion.title}
									</li>
								))}
							</ul>
							<p>{t("annotationsVersionChangeDialog.annotationsLocationChange")}</p>
							<p style={{ fontWeight: "bold" }}>{t("annotationsVersionChangeDialog.keepAnnotationsConfirm")}</p>
						</DialogContent>
						<DialogActions>
							<Button
								size="medium"
								variant="contained"
								color="primary"
								onClick={(e) => {
									handleKeepAnnotationsClick();
									e.stopPropagation();
								}}
								style={{ margin: "0 8px" }}
								data-cy="annotationsMoveAll"
							>
								{t("keep").toUpperCase()}
							</Button>
							<Button
								size="medium"
								variant="outlined"
								color="primary"
								onClick={(e) => {
									handleDiscardAnnotationsClick();
									e.stopPropagation();
								}}
								style={{ margin: "0 8px" }}
								data-cy="annotationsDeleteAll"
							>
								{t("discard").toUpperCase()}
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		</>
	);
};

export default withTranslation("meetings")(withWidth()(connect(mapStateToProps)(withRouter(PdfViewerNew))));
