import { format } from "date-fns";

import {
	createMeetingElement,
	updateRelationships,
	updateOrderAndNumbering,
	updateFlag,
	getAllChildren,
	mergeMeetingPersistanceData,
	STATUS_SAVED_TIMEOUT,
} from "utils/meetingElement";
import { processTimes } from "utils/meetingTime";
import {
	GET_MEETING_TEMPLATES_FULFILLED,
	GET_MEETING_TEMPLATE_FULFILLED,
	GET_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED,
	GET_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED,
	UPDATE_MEETING_TEMPLATE,
	UPDATE_MEETING_TEMPLATE_AGENDA_ITEMS,
	UPDATE_MEETING_TEMPLATE_AGENDA_PUBLIC_RELEASE_DELAY,
	UPDATE_MEETING_TEMPLATE_AGENDA_SHOW_CONFIDENTIAL_ITEM_LABEL,
	UPDATE_MEETING_TEMPLATE_AGENDA_HIDE_RECOMMENDATIONS_ON_PUBLIC,
	UPDATE_MEETING_TEMPLATE_MINUTES_ITEMS,
	ADD_MEETING_TEMPLATE_AGENDA_MEETING_ITEM,
	DELETE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM,
	REORDER_MEETING_TEMPLATE_AGENDA_ITEMS,
	UPDATE_MEETING_TEMPLATE_VOTING_ROLL_CALL,
	PERSIST_MEETING_TEMPLATE_PENDING,
	PERSIST_MEETING_TEMPLATE_FULFILLED,
	PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING,
	PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED,
	PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED,
	PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING,
	PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED,
	PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED,
	CLEAR_MEETING_TEMPLATE_STATUS,
	SET_ACTIVE,
	SET_CUSTOM_NUMBERING_OPTION,
	UPDATE_MINUTES_INCLUDES_MINUTES_ATTACHMENT,
	UPDATE_SHOW_CLOSED_ITEM_TITLE,
	UPDATE_ALWAYS_USE_RECORDED_VOTE,
	UPDATE_PUBLISHED_DRAFT_MINUTES,
	UPDATE_SIGNATURE_FAILED,
	UPDATE_SIGNATURE_FULFILLED,
	UPDATE_SIGNATURE_PENDING,
} from "./types";

export const meetingTemplatesReducer = (
	state = {
		meetingTemplate: undefined,
		saving: false,
		updated: false,
	},
	action,
) => {
	switch (action.type) {
		case GET_MEETING_TEMPLATES_FULFILLED: {
			const meetingTemplates = action.payload;

			state = {
				...state,
				meetingTemplates,
			};
			break;
		}

		case GET_MEETING_TEMPLATE_FULFILLED:
		case PERSIST_MEETING_TEMPLATE_FULFILLED: {
			const meetingTemplate = processTimes(action.payload);

			state = {
				...state,
				meetingTemplate,
				saving: false,
				updated: false,
			};
			break;
		}

		case GET_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED: {
			const {
				id,
				items,
				header,
				footer,
				numbering,
				publicReleaseDelay,
				showClosedItemDescription,
				hideRecommendationsOnPublic,
				customNumbering,
			} = action.payload;
			const persistObject = { items: [], itemIdsToDelete: [] };
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;
			persistObject.showClosedItemDescription = showClosedItemDescription;

			// Set any missing or incorrect numbering and save it
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			state = {
				...state,
				agenda: {
					...(state?.agenda || {}),
					id: parseInt(id, 10),
					items,
					header,
					footer,
					numbering,
					publicReleaseDelay,
					showClosedItemDescription,
					hideRecommendationsOnPublic,
					customNumbering: customNumbering,
					persistObject,
					saving: false,
					updated: persistObject.items.length > 0,
				},
				active: undefined,
			};
			break;
		}

		case GET_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED: {
			const {
				id,
				items,
				header,
				footer,
				numbering,
				votingAndRollCall,
				customNumbering,
				publicReleaseDelay,
				showClosedItemDescription,
				includeMinutesAttachments = false,
				showClosedItemTitle = false,
				alwaysUseRecordedVote = false,
				onPublishShowDraftMinutes = false,
				signatures = [],
			} = action.payload;

			const persistObject = { items: [], itemIdsToDelete: [] };
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;
			persistObject.showClosedItemDescription = showClosedItemDescription;
			persistObject.includeMinutesAttachments = includeMinutesAttachments;
			persistObject.onPublishShowDraftMinutes = onPublishShowDraftMinutes;
			persistObject.showClosedItemTitle = showClosedItemTitle;
			persistObject.alwaysUseRecordedVote = alwaysUseRecordedVote;

			// Set any missing or incorrect numbering, but don't save it
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			state = {
				...state,
				minutes: {
					...(state?.minutes || {}),
					id: parseInt(id, 10),
					items,
					header,
					footer,
					numbering,
					votingAndRollCall,
					customNumbering: customNumbering,
					saving: false,
					updated: false,
					includeMinutesAttachments,
					showClosedItemTitle,
					alwaysUseRecordedVote,
					onPublishShowDraftMinutes,
					signatures,
				},
				active: undefined,
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE: {
			const meetingTemplate = action.payload;

			state = {
				...state,
				meetingTemplate: typeof meetingTemplate === "function" ? meetingTemplate(state.meetingTemplate) : meetingTemplate, // A method can be used to do processing on the entity before setting it to redux state
				updated: true,
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE_AGENDA_ITEMS: {
			const { fieldData, editorFields } = action;
			const {
				meetingTemplate,
				agenda: {
					items,
					header,
					footer,
					customNumbering,
					publicReleaseDelay,
					showClosedItemDescription,
					persistObject = { items: [], itemIdsToDelete: [] },
				} = {},
			} = state;
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;
			persistObject.showClosedItemDescription = showClosedItemDescription;

			let newFooter = footer;
			let newHeader = header;
			if (meetingTemplate) {
				fieldData.forEach((field) => {
					const editorField = editorFields.find((f) => f.name === field.fieldName);
					const guid = field.fieldName.replace("-text", "");
					if (guid === "toc-header" || guid === "toc-footer") {
						if (guid === "toc-header") {
							if (header.replace(/ \/>/g, ">") === field.fieldData) return;
							newHeader = field.fieldData;
							persistObject.header = field.fieldData;
							return;
						}
						if (guid === "toc-footer") {
							if (footer.replace(/ \/>/g, ">") === field.fieldData) return;
							newFooter = field.fieldData;
							persistObject.footer = field.fieldData;
							return;
						}
					} else {
						const item = items.find((item) => item.guid === guid);
						const persistItem = persistObject.items.find((item) => item.guid === guid);
						if (item) {
							if (field.nonEditor) {
								if (item.fields[field.name].Value === field.fieldData) return;
								item.fields[field.name].Value = field.fieldData;
								if (persistItem) {
									persistItem.fields[field.name].Value = field.fieldData;
								} else {
									persistObject.items.push(item);
								}
							} else {
								if (field.fieldName.includes("-text")) {
									if (
										!editorField ||
										(!editorField.deleted && item.fields.Text.Value && item.fields.Text.Value.replace(/ \/>/g, ">") === field.fieldData) ||
										(!editorField.deleted && item.fields.Text.Value === null && field.fieldData === "") ||
										(editorField.deleted && item.fields.Text.Value === null)
									)
										return;
									item.fields.Text.Value = editorField.deleted ? null : field.fieldData;
									if (persistItem) {
										persistItem.fields.Text.Value = editorField.deleted ? null : field.fieldData;
									} else {
										persistObject.items.push(item);
									}
									return;
								}
								if (item.fields.Name.Value && item.fields.Name.Value.replace(/ \/>/g, ">") === field.fieldData) return;
								item.fields.Name.Value = field.fieldData;
								if (persistItem) {
									persistItem.fields.Name.Value = field.fieldData;
								} else {
									persistObject.items.push(item);
								}
								return;
							}
						}
					}
				});
			}

			updateFlag(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			state = {
				...state,
				agenda: {
					...(state.agenda || {}),
					items,
					header: newHeader,
					footer: newFooter,
					persistObject: persistObject,
					updated:
						state.agenda?.updated ||
						persistObject.items.length > 0 ||
						persistObject.itemIdsToDelete.length > 0 ||
						persistObject.header ||
						persistObject.footer,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE_AGENDA_PUBLIC_RELEASE_DELAY: {
			const publicReleaseDelay = action.payload;
			const {
				agenda: { persistObject = { items: [], itemIdsToDelete: [] }, customNumbering, showClosedItemDescription },
			} = state;

			persistObject.showClosedItemDescription = showClosedItemDescription;
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;

			state = {
				...state,
				agenda: {
					...state.agenda,
					publicReleaseDelay: persistObject.publicReleaseDelay,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE_AGENDA_SHOW_CONFIDENTIAL_ITEM_LABEL: {
			const showClosedItemDescription = action.payload;
			const {
				agenda: { persistObject = { items: [], itemIdsToDelete: [] }, customNumbering, publicReleaseDelay },
			} = state;

			persistObject.showClosedItemDescription = showClosedItemDescription;
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;

			state = {
				...state,
				agenda: {
					...state.agenda,
					showClosedItemDescription: persistObject.showClosedItemDescription,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE_AGENDA_HIDE_RECOMMENDATIONS_ON_PUBLIC: {
			const hideRecommendationsOnPublic = action.payload;
			const {
				agenda: { persistObject = { items: [], itemIdsToDelete: [] }, customNumbering, publicReleaseDelay },
			} = state;

			persistObject.customNumbering = customNumbering;
			persistObject.hideRecommendationsOnPublic = hideRecommendationsOnPublic;

			state = {
				...state,
				agenda: {
					...state.agenda,
					hideRecommendationsOnPublic,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE_MINUTES_ITEMS: {
			const { fieldData, editorFields } = action;
			const { meetingTemplate, minutes: { items, header, footer, persistObject = { items: [], itemIdsToDelete: [] } } = {} } = state;

			let newFooter = footer;
			let newHeader = header;
			if (meetingTemplate) {
				fieldData.forEach((field) => {
					const editorField = editorFields.find((f) => f.name === field.fieldName);
					const guid = field.fieldName.replace("-text", "");
					if (guid === "toc-header" || guid === "toc-footer") {
						if (guid === "toc-header") {
							if (header.replace(/ \/>/g, ">") === field.fieldData) return;
							newHeader = field.fieldData;
							persistObject.header = field.fieldData;
							return;
						}
						if (guid === "toc-footer") {
							if (footer.replace(/ \/>/g, ">") === field.fieldData) return;
							newFooter = field.fieldData;
							persistObject.footer = field.fieldData;
							return;
						}
					} else {
						const item = items.find((item) => item.guid === guid);
						const persistItem = persistObject.items.find((item) => item.guid === guid);
						if (item) {
							if (field.nonEditor) {
								if (item.fields[field.name].Value === field.fieldData) return;
								item.fields[field.name].Value = field.fieldData;
								if (persistItem) {
									persistItem.fields[field.name].Value = field.fieldData;
								} else {
									persistObject.items.push(item);
								}
							} else {
								if (field.fieldName.includes("-text")) {
									if (
										!editorField ||
										(!editorField.deleted && item.fields.Text.Value && item.fields.Text.Value.replace(/ \/>/g, ">") === field.fieldData) ||
										(!editorField.deleted && item.fields.Text.Value === null && field.fieldData === "") ||
										(editorField.deleted && item.fields.Text.Value === null)
									)
										return;
									item.fields.Text.Value = editorField.deleted ? null : field.fieldData;
									if (persistItem) {
										persistItem.fields.Text.Value = editorField.deleted ? null : field.fieldData;
									} else {
										persistObject.items.push(item);
									}
									return;
								}
								if (item.fields.Name.Value && item.fields.Name.Value.replace(/ \/>/g, ">") === field.fieldData) return;
								item.fields.Name.Value = field.fieldData;
								if (persistItem) {
									persistItem.fields.Name.Value = field.fieldData;
								} else {
									persistObject.items.push(item);
								}
								return;
							}
						}
					}
				});
			}

			state = {
				...state,
				minutes: {
					...(state.minutes || {}),
					items,
					header: newHeader,
					footer: newFooter,
					persistObject: persistObject,
					updated:
						state.minutes?.updated ||
						persistObject.items.length > 0 ||
						persistObject.itemIdsToDelete.length > 0 ||
						persistObject.header ||
						persistObject.footer,
				},
			};
			break;
		}

		case ADD_MEETING_TEMPLATE_AGENDA_MEETING_ITEM: {
			const { sourceItem, itemType, subHeading, newItemGuid, itemsInsertIndex, parentItem } = action.payload;

			const {
				agenda: {
					items,
					customNumbering,
					showClosedItemDescription,
					publicReleaseDelay,
					persistObject = { items: [], itemIdsToDelete: [] },
				},
			} = state;
			persistObject.showClosedItemDescription = showClosedItemDescription;
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;

			items.splice(
				itemsInsertIndex,
				0,
				createMeetingElement({
					guid: newItemGuid,
					itemType: itemType,
					name: sourceItem?.fields?.Name?.Value || "",
					text: sourceItem?.fields?.Text?.Value || null,
					indent: subHeading ? 1 : undefined,
					closed: Boolean(parentItem?.fields?.Closed?.Value),
					consent: Boolean(parentItem?.fields?.Consent?.Value),
					publicComment: Boolean(parentItem?.fields?.PublicComment?.Value),
					parentGuid: parentItem?.guid,
				}),
			);

			//Get siblings and update ordering
			updateOrderAndNumbering(
				persistObject,
				items.filter(
					(item) =>
						((parentItem && item.attributes.relationshipGuid === parentItem.guid) || (!parentItem && !item.attributes?.relationshipGuid)) &&
						!item.deleted,
				),
				parentItem,
				items,
				newItemGuid,
			);

			state = {
				...state,
				agenda: {
					...state.agenda,
					items: [...items],
					persistObject,
					updated: true,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case DELETE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM: {
			const { payload: guid } = action;

			const {
				agenda: {
					items,
					customNumbering,
					publicReleaseDelay,
					showClosedItemDescription,
					persistObject = { items: [], itemIdsToDelete: [] },
				},
			} = state;
			persistObject.showClosedItemDescription = showClosedItemDescription;
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;
			const index = items.findIndex((item) => item.guid === guid);
			const itemToDelete = items.find((item) => item.guid === guid);
			const parentItem = items.find((item) => item.guid === itemToDelete.attributes.relationshipGuid);

			const itemsToDelete = [itemToDelete].concat(getAllChildren(guid, items)); // Get the item and all of it's children
			itemsToDelete.forEach((item) => {
				persistObject.itemIdsToDelete.push(item.guid);
				item.deleted = true;
			});

			if (index >= 0) {
				items.splice(index, itemsToDelete.length); // Children should immediate follow the parents in the array
			}

			// Update numbering
			updateOrderAndNumbering(
				persistObject,
				items.filter(
					(item) =>
						((parentItem && item.attributes.relationshipGuid === parentItem.guid) || (!parentItem && !item.attributes?.relationshipGuid)) &&
						!item.deleted,
				),
				parentItem,
				items,
			);

			state = {
				...state,
				agenda: {
					...state.agenda,
					items: [...items],
					persistObject,
					updated: true,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case REORDER_MEETING_TEMPLATE_AGENDA_ITEMS: {
			const { payload: items } = action;

			const {
				agenda: { customNumbering, showClosedItemDescription, publicReleaseDelay, persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.showClosedItemDescription = showClosedItemDescription;
			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;

			// Update relationships, order, and numbering
			updateRelationships(persistObject, items);
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			state = {
				...state,
				agenda: {
					...state.agenda,
					items: [...items],
					persistObject,
					updated: true,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case UPDATE_MEETING_TEMPLATE_VOTING_ROLL_CALL: {
			const votingAndRollCall = action.payload;

			const {
				minutes: { persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.votingAndRollCall =
				typeof votingAndRollCall === "function" ? votingAndRollCall(state.minutes.votingAndRollCall) : votingAndRollCall; // A method can be used to do processing on the entity before setting it to redux state

			state = {
				...state,
				minutes: {
					...state.minutes,
					votingAndRollCall: persistObject.votingAndRollCall,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case UPDATE_MINUTES_INCLUDES_MINUTES_ATTACHMENT: {
			const includeMinutesAttachments = action.payload;
			const {
				minutes: { persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.includeMinutesAttachments = includeMinutesAttachments;
			state = {
				...state,
				minutes: {
					...state.minutes,
					includeMinutesAttachments,
					persistObject,
					updated: true,
				},
			};
			break;
		}
		case UPDATE_PUBLISHED_DRAFT_MINUTES: {
			const onPublishShowDraftMinutes = action.payload;
			const {
				minutes: { persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.onPublishShowDraftMinutes = onPublishShowDraftMinutes;
			state = {
				...state,
				minutes: {
					...state.minutes,
					onPublishShowDraftMinutes,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case UPDATE_SHOW_CLOSED_ITEM_TITLE: {
			const showClosedItemTitle = action.payload;
			const {
				minutes: { persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.showClosedItemTitle = showClosedItemTitle;
			state = {
				...state,
				minutes: {
					...state.minutes,
					showClosedItemTitle,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case UPDATE_ALWAYS_USE_RECORDED_VOTE: {
			const alwaysUseRecordedVote = action.payload;
			const {
				minutes: { persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.alwaysUseRecordedVote = alwaysUseRecordedVote;
			state = {
				...state,
				minutes: {
					...state.minutes,
					alwaysUseRecordedVote,
					persistObject,
					updated: true,
				},
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_PENDING: {
			state = {
				...state,
				saving: true,
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING: {
			const { noChanges, t } = action;

			clearTimeout(state.agenda?.status?.timeout);

			state = {
				...state,
				agenda: {
					...state.agenda,
					persistObject: undefined, // This has been sent to the server, so allow a new persistObject to be created
					saving: !noChanges ? true : false,
					updated: false,
					status: !noChanges
						? {
								label: t("agendaMenu:saving"),
								tooltip: t("agendaMenu:saveTooltip"),
							}
						: state.agenda?.status,
				},
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED: {
			const { t, dateFormat, clearStatus } = action;

			clearTimeout(state.agenda?.status?.timeout);

			state = {
				...state,
				agenda: {
					...state.agenda,
					saving: false,
					error: false,
					status: {
						label: t("agendaMenu:saved"),
						tooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
						timeout: typeof clearStatus === "function" ? setTimeout(clearStatus, STATUS_SAVED_TIMEOUT) : undefined,
					},
				},
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED: {
			const { t, persistObject } = action;

			clearTimeout(state.agenda?.status?.timeout);

			const mergedObject = mergeMeetingPersistanceData(state.agenda?.persistObject, persistObject);

			state = {
				...state,
				agenda: {
					...state.agenda,
					persistObject: mergedObject,
					saving: false,
					error: true,
					updated: Boolean(
						mergedObject.agendaHeader ||
							mergedObject.agendaFooter ||
							mergedObject.items.length > 0 ||
							mergedObject.itemIdsToDelete.length > 0,
					),
					status: {
						label: t("agendaMenu:saveError"),
						tooltip: t("agendaMenu:saveErrorTooltip"),
					},
				},
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING: {
			const { noChanges, t } = action;

			clearTimeout(state.minutes?.status?.timeout);

			state = {
				...state,
				minutes: {
					...state.minutes,
					persistObject: undefined, // This has been sent to the server, so allow a new persistObject to be created
					saving: !noChanges ? true : false,
					updated: false,
					error: false,
					status: !noChanges
						? {
								label: t("agendaMenu:saving"),
								tooltip: t("agendaMenu:saveTooltip"),
							}
						: state.minutes?.status,
				},
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED: {
			const { t, dateFormat, rollCallMapping, clearStatus } = action;
			let {
				minutes: { votingAndRollCall },
			} = state;

			clearTimeout(state.minutes?.status?.timeout);

			// Update the ids of new roll calls
			if (rollCallMapping && rollCallMapping.length > 0) {
				votingAndRollCall.rollCalls
					.filter((rollCall) => rollCall.id <= 0)
					.forEach((rollCall) => {
						rollCall.id = rollCallMapping.find((mapping) => mapping.oldId === rollCall.id)?.newId || rollCall.id;
					});
				votingAndRollCall = { ...votingAndRollCall };
			}

			state = {
				...state,
				minutes: {
					...state.minutes,
					votingAndRollCall,
					saving: false,
					status: {
						label: t("agendaMenu:saved"),
						tooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
						timeout: typeof clearStatus === "function" ? setTimeout(clearStatus, STATUS_SAVED_TIMEOUT) : undefined,
					},
				},
			};
			break;
		}

		case PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED: {
			const { t, persistObject } = action;

			clearTimeout(state.minutes?.status?.timeout);

			const mergedObject = mergeMeetingPersistanceData(state.minutes?.persistObject, persistObject);

			state = {
				...state,
				minutes: {
					...state.minutes,
					persistObject: mergedObject,
					saving: false,
					error: true,
					updated: Boolean(
						mergedObject.agendaHeader ||
							mergedObject.agendaFooter ||
							mergedObject.items.length > 0 ||
							mergedObject.itemIdsToDelete.length > 0,
					),
					status: {
						label: t("agendaMenu:saveError"),
						tooltip: t("agendaMenu:saveErrorTooltip"),
					},
				},
			};
			break;
		}

		case CLEAR_MEETING_TEMPLATE_STATUS: {
			state = {
				...state,
				agenda: state.agenda
					? {
							...state.agenda,
							status: {},
						}
					: undefined,
				minutes: state.minutes
					? {
							...state.minutes,
							status: {},
						}
					: undefined,
			};
			break;
		}

		case SET_ACTIVE: {
			const { guid, focus, field } = action.payload;

			state = {
				...state,
				active: guid ? { id: guid, focus, field } : undefined,
			};
			break;
		}

		case SET_CUSTOM_NUMBERING_OPTION: {
			const { customNumbering } = action.payload;
			const {
				agenda: { items, persistObject = { items: [], itemIdsToDelete: [] }, publicReleaseDelay, showClosedItemDescription },
			} = state;

			persistObject.customNumbering = customNumbering;
			persistObject.publicReleaseDelay = publicReleaseDelay;
			persistObject.showClosedItemDescription = showClosedItemDescription;
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);
			state = {
				...state,
				agenda: {
					...state.agenda,
					persistObject,
					updated: true,
					customNumbering,
				},
			};
			break;
		}

		case UPDATE_SIGNATURE_PENDING: {
			state = {
				...state,
				minutes: {
					...state.minutes,
				},
			};
			break;
		}

		case UPDATE_SIGNATURE_FULFILLED: {
			const signatures = action.payload;
			state = {
				...state,
				minutes: {
					...state.minutes,
					signatures,
				},
			};
			break;
		}

		case UPDATE_SIGNATURE_FAILED: {
			state = {
				...state,
				minutes: {
					...state.minutes,
				},
			};
			break;
		}
	}

	return state;
};

export default meetingTemplatesReducer;
