import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import request from "superagent";
import { API_HOST } from "config/env";

import { isMobile } from "react-device-detect";
import { CellMeasurerCache } from "react-virtualized";

import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Button } from "@mui/material";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import FilterPanel from "components/Panels/FilterPanel";
import SubscribersList from "./components/SubscribersList";
import SubscribersVirtualizedList from "./components/SubscribersVirtualizedList";
import SubscribersFilter from "./components/SubscribersFilter";

import telemetryAddEvent from "utils/telemetryAddEvent";
import { resetPageConfigs, updatePageConfigs, updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { setSnackbarOptions } from "../../redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";

const telemetryPage = "Subscribers";
const defaultFilter = {
	emailAddress: "",
	searchLowerCase: "",
	meetingTypes: [],
	publicYes: null,
	publicNo: null,
	activeYes: null,
	activeNo: null,
};

const SubscribersModule = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("subscribers");
	const navigate = useNavigate();
	const { closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [subscribers, setSubscribers] = useState(null);
	const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
	const [totalCount, setTotalCount] = useState(0);
	const [meetingTypes, setMeetingTypes] = useState(null);
	const [subscribersCount, setSubscribersCount] = useState(null);
	const [panels, setPanels] = useState({});
	const [filter, setFilter] = useState(defaultFilter);
	const [filtered, setFiltered] = useState(false);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(100000);
	const [sort, setSort] = useState();

	const paginationProps = {
		rowsPerPageOptions: [],
		colSpan: 5,
		count: totalCount,
		rowsPerPage: pageSize,
		page: page,
		onPageChange: (e, pageNum) => {
			setPage(pageNum);
		},
	};

	const cache = new CellMeasurerCache({
		fixedWidth: true,
		defaultHeight: 48,
	});

	const mobileCache = new CellMeasurerCache({
		defaultHeight: 48,
	});

	const handleActiveClick = (subscription) => {
		const active = !subscription.active;
		const subscriptionId = subscription.id ? subscription.id.join("-") : "";

		if (subscriptionId.length > 0) {
			request
				.put(`${API_HOST}/api/subscription/${subscriptionId}`)
				.send({ active: active })
				.then((res) => {
					telemetryAddEvent(`${telemetryPage} - ${!active ? "Deactivate" : subscription.public ? "Send Confirmation" : "Activate"}`);

					let option = notifierMessage(
						t(
							`list.notification.${res.body.ConfirmationSent ? "confirmationSent" : res.body.Active ? "activeSuccess" : "inactiveSuccess"}`,
						),
						"success",
					);
					dispatch(setSnackbarOptions(option));

					setSubscribers((prev) => {
						prev.forEach((prevSub) => {
							if (prevSub.id.join("-") === subscriptionId) {
								if (!subscription.public) {
									prevSub.active = active;
								} else if (!active) {
									prevSub.active = active;
								}
							}
						});
						return [...prev];
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleDeleteClick = (subscription) => {
		const action = (key) => (
			<>
				<Button id="undo-button" style={{ margin: "8px", color: "white" }} onClick={() => undoDelete(subscription.id)}>
					Undo
				</Button>
			</>
		);

		const subscriptionId = subscription.id ? subscription.id.join("-") : "";

		if (subscriptionId.length > 0 && subscriptionId != subscriptionToDelete) {
			setSubscriptionToDelete(subscriptionId);
			request
				.del(`${API_HOST}/api/subscription/${subscriptionId}`)
				.then((res) => {
					telemetryAddEvent(`${telemetryPage} - Delete`);

					let option = notifierMessage(t("list.notification.deleteSuccess"), "success", () => undoDelete(subscription.id));
					dispatch(setSnackbarOptions(option));

					setSubscribers((prev) => {
						return prev.filter((prevSub) => prevSub.id.join("-") !== subscriptionId);
					});
					setTotalCount((prev) => --prev);
					setSubscriptionToDelete(null);
				})
				.catch((err) => {
					console.log(err);
					setSubscriptionToDelete(null);
				});
		}
	};

	const undoDelete = (subscriptionId) => {
		closeSnackbar();
		request
			.post(`${API_HOST}/api/subscription/${subscriptionId}/undodelete`)
			.then((res) => {
				let option = notifierMessage(t("list.notification.restoreSuccess"), "success");
				dispatch(setSnackbarOptions(option));
				loadSubscribers();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadSubscribers = async () => {
		const meetingTypeRes = await request.get(`${API_HOST}/api/meetingtypes`);
		setMeetingTypes(meetingTypeRes.body);

		const countResponse = await request.get(`${API_HOST}/api/subscriptions/count`);
		setSubscribersCount(countResponse?.body);

		setSubscribers(null);
		request
			.post(`${API_HOST}/api/subscriptions/filteredlist`)
			.send({ filter, page: page + 1, pageSize: pageSize, sort })
			.then((res) => {
				const { subscriptions, totalCount } = res.body;
				setSubscribers(subscriptions);
				setTotalCount(totalCount);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadSubscribers();
				});
			});
	};

	const filterChange = (newActive) => {
		telemetryAddEvent(`${telemetryPage} - Filter`);
		setFilter(newActive || defaultFilter);
	};

	const clearFilter = () => {
		setFilter(defaultFilter);
	};

	const filterClick = () => {
		setPanels((prev) => ({
			filter: !prev.filter,
		}));
	};

	const closeFilter = () => {
		setPanels({
			filter: false,
		});
	};

	const handleSort = (property, order) => {
		setSort({ field: property, dir: order });
	};

	useEffect(() => {
		loadSubscribers();

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("title"),
				telemetryPage,
				preferedBack: { url: "/subscribers" },
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: () => navigate("/subscribers/create"),
				primaryActionText: t("buttons.addNewSubscription"),
				primaryActionTooltip: t("tooltips.addNewSubscription"),
			}),
		);
	}, []);

	useEffect(() => {
		loadSubscribers();
		setFiltered(
			filter !== null &&
				(filter.emailAddress.length > 0 ||
					filter.meetingTypes.length > 0 ||
					filter.publicYes ||
					filter.publicNo ||
					filter.activeYes ||
					filter.activeNo),
		);
	}, [filter, page, pageSize, sort]);

	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				right: {
					tools: [
						{
							id: "openFilter",
							icon: "filter",
							tooltipText: t("tooltips.filter"),
							ariaLabel: t("tooltips.filter"),
							onClick: filterClick,
							dataCy: "toggle-filter",
							badgeProps: {
								display: filtered,
								dataCy: "filtered",
							},
						},
					],
				},
			}),
		);
	}, [subscribers, filtered]);

	return (
		<ComponentContainer padding="0">
			<SubscribersVirtualizedList
				rows={subscribers}
				sort={sort}
				headerHeight={48}
				totalCount={totalCount}
				meetingTypes={meetingTypes}
				paginationProps={paginationProps}
				handleActiveClick={handleActiveClick}
				handleDeleteClick={handleDeleteClick}
				handleSort={handleSort}
				cache={cache}
				mobileCache={mobileCache}
			/>
			<RightPanelContainer id="right-panel-container" open={panels.filter} float drawerWidth={MD_DOWN_WIDTH} fullHeight>
				<FilterPanel
					filters={filter}
					onFilterChange={filterChange}
					clearClick={clearFilter}
					isFiltered={filtered}
					closeFilter={closeFilter}
				>
					<SubscribersFilter
						filters={filter}
						onFilterChange={filterChange}
						meetingTypes={meetingTypes}
						subscribersCount={subscribersCount}
					/>
				</FilterPanel>
			</RightPanelContainer>
		</ComponentContainer>
	);
};

export default withErrorHandling(SubscribersModule);
