import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EasyImagePlugin from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageCaptionPlugin from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbedPlugin from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak";
import PasteFromOfficePlugin from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TwoStepCaretMovement from "@ckeditor/ckeditor5-typing/src/twostepcaretmovement";
import CloudServicesPlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";

import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

import Parser, { domToReact } from "html-react-parser";

import AddPolicyPlugin from "components/Editor/plugins/AddPolicy/AddPolicy";
import AddGoalsPlugin from "components/Editor/plugins/AddGoals/AddGoalsInline";
import AttachFilePlugin from "components/Editor/plugins/AttachFile/AttachFile";
import InlineFilePlugin from "components/Editor/plugins/InlineFile/inlineFile";
import InlineFileMOPlugin from "components/Editor/plugins/InlineFile/memberOnly/inlineFileMO";
import MapleUploadAdapter from "components/Editor/MapleUploadAdapter";
import InsertPlaceholder from "components/Editor/plugins/Placeholders/placeholders";
import InsertRollCall from "components/Editor/plugins/RollCall/rollCall";

import { debounce } from "lodash";
import compact from "lodash/fp/compact";
import findIndex from "lodash/fp/findIndex";
import forEach from "lodash/fp/forEach";

import { v4 as uuid } from "uuid";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import InputLabel from "atlas/components/FormControls/InputLabel";
import { allowedEditorExtensions } from "utils/allowedExtensions";

const toolbars = {
	simple: { items: ["link", "inlineFile", "addPolicy"] },
	simpleMo: { items: ["link", "inlineFileMO", "addPolicy"] },
	scratchpad: { items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "insertTable"] },
	limited: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "inlineFile", "addPolicy", "insertTable"],
	},
	limitedMo: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "inlineFileMO", "addPolicy", "insertTable"],
	},
	itemText: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "inlineFile", "addPolicy", "insertTable"],
	},
	notes: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "inlineFileMO", "addPolicy", "addGoals"],
	},
	minutesNotes: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"insertTable",
			"insertRollCall",
		],
	},
	meetingTemplateNotes: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link"],
	},
	header: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertMeetingPlaceHolder",
		],
	},
	footer: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertMeetingPlaceHolder",
		],
	},
	notificationsSettings: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "insertTable", "|", "insertPlaceholder"],
	},
	generalSettings: {
		items: ["undo", "redo", "bold", "italic", "outdent", "indent", "bulletedList", "numberedList"],
	},
};

const useStyles = makeStyles(() => ({
	editor: {
		position: "relative",
	},
	loadingIndicator: {
		height: "49.4px",
		width: "100%",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		left: "0",
		top: "0",
		boxSizing: "border-box",
	},
	preloadContent: {
		padding: "0 0.6em",
		paddingTop: (props) => (props && props.preload && props.preload.staticToolbar ? "49.4px" : undefined),
		boxSizing: "border-box",
	},
}));

const GenericEditor = (props) => {
	const {
		guid,
		meetingId,
		toolbar,
		content = "",
		name,
		title,
		labelSize,
		labelEllipsis,
		labelLines,
		setEditorsData,
		deleteFile,
		invalidFileExtension,
		features,
		queueFileUploads,
		addPolicy,
		addGoals,
		fileExtensions,
		willBeAnAttachment,
		onChange,
		handleRedo,
		handleUndo,
		focus,
		mt = 3,
		loadAsync,
		preload: { staticToolbar = false } = {},
		preload,
		disabled = false,
	} = props;
	const { t } = useTranslation();
	const { ckEditorArguments, lite } = useContext(SettingsContext);
	const [loading, setLoading] = useState(Boolean(loadAsync));
	const classes = useStyles({ preload });
	const editorContainer = useRef(null);
	const editorReference = useRef(null);

	const handleEditorChange = debounce((event, editor) => {
		onChange(event, editor, name);
	}, 100);

	const handleUndoRedo = (isUndo) => {
		if (handleUndo && handleRedo) {
			if (isUndo) {
				handleUndo();
			} else {
				handleRedo();
			}
		}
	};

	const validateLinkHref = (href) => {
		if (href && !href.toLowerCase().startsWith("http") && !href.toLowerCase().startsWith("mailto:")) {
			if (href.substr(0, 2) === "//") {
				href = `http:${href}`;
			} else if (href.substr(0, 1) === "/") {
				href = `http:/${href}`;
			} else {
				href = `http://${href}`;
			}
		}
		return href;
	};

	const addFileUpload = (fileUploads) => {
		const fileData = new FormData();
		let closed = false;
		forEach((fileUpload) => {
			fileData.append(fileUpload.guid, fileUpload.file);
			if (findIndex((feature) => feature.id === "MOA", fileUpload.features) !== -1) {
				closed = true;
			}
		}, fileUploads);

		fileData.append(
			"data",
			JSON.stringify({
				meetingId,
				itemGuid: guid,
				attach: true,
				closed,
				willBeAnAttachment,
			}),
		);

		queueFileUploads(guid, fileUploads, fileData);
	};

	const initEditor = () => {
		const inlineFileConfig = {
			parentId: name,
			translations: t("editor.inlineFile"),
			features,
			urlBase: "/home/document",
			allowedFileExtensions: fileExtensions || allowedEditorExtensions,
			functions: {
				onAddFiles: (editor, parentId, files) => {
					editor.onItemChange(null, editor);
					addFileUpload(files);
				},
				onDeleteFile: (editor, parentId, fileGuid) => {
					editor.onItemChange(null, editor);
					if (deleteFile) {
						deleteFile(parentId, fileGuid);
					}
				},
				onInvalidExtension: (editor, parentId, fileNames) => {
					invalidFileExtension(parentId, fileNames);
				},
			},
		};
		const useAddPolicy = typeof addPolicy === "function";
		const addPolicyConfig = {
			urlBase: "/home/document",
			itemGuid: guid,
			features: features
				? [
						{
							id: features.id,
							label: features.label,
							className: features.className,
							defaultValue: false,
							disabledValue: false,
							isEnabled: true,
							anchorTitle: features.anchorTitle,
							tooltipDisabledOn: features.tooltipDisabledOn,
						},
					]
				: [],
			functions: {
				onOpenDialog: addPolicy,
			},
		};
		const addGoalsConfig = {
			urlBase: "/home/goals/view",
			itemGuid: guid,
			features: features
				? [
						{
							id: features.id,
							label: features.label,
							className: features.className,
							defaultValue: false,
							disabledValue: false,
							isEnabled: true,
							anchorTitle: features.anchorTitle,
							tooltipDisabledOn: features.tooltipDisabledOn,
						},
					]
				: [],
			functions: {
				onOpenDialog: addGoals,
			},
		};

		const filteredToolbar = { items: (toolbars[toolbar]?.items || []).filter((item) => useAddPolicy || item !== "addPolicy") };
		const useInlineFileMo = filteredToolbar.items.includes("inlineFileMO");

		let updatedEditorContentHtml; // Check for break-after style attribute and change it to page-break-after so ckeditor can read it properly
		if (editorContainer.current && editorContainer.current.innerHTML.indexOf("break-after: page") !== -1) {
			updatedEditorContentHtml = editorContainer.current.innerHTML.replace("break-after: page", "page-break-after: always");
		}
		ClassicEditor.create(editorContainer.current, {
			updateSourceElementOnDestroy: true,
			custom: ckEditorArguments,
			extraPlugins: [MapleUploadAdapter],
			mediaEmbed: {
				removeProviders: ["instagram", "twitter", "googleMaps", "flickr", "facebook"],
			},
			image: {
				toolbar: ["imageTextAlternative"],
			},
			language: "en",
			licenseKey: ckEditorArguments.key,
			plugins: compact([
				Clipboard,
				EssentialsPlugin,
				AlignmentPlugin,
				AutoformatPlugin,
				useAddPolicy && AddPolicyPlugin,
				!lite.enabled && AddGoalsPlugin,
				AttachFilePlugin,
				InlineFilePlugin,
				useInlineFileMo && InlineFileMOPlugin,
				InsertPlaceholder,
				InsertRollCall,
				BoldPlugin,
				EasyImagePlugin,
				FontPlugin,
				HeadingPlugin,
				ItalicPlugin,
				ImagePlugin,
				ImageCaptionPlugin,
				ImageStylePlugin,
				ImageToolbarPlugin,
				ImageUploadPlugin,
				IndentPlugin,
				IndentBlockPlugin,
				LinkPlugin,
				ListPlugin,
				MediaEmbedPlugin,
				ParagraphPlugin,
				PageBreak,
				PasteFromOfficePlugin,
				TablePlugin,
				TableToolbarPlugin,
				TableProperties,
				TableCellProperties,
				TwoStepCaretMovement,
				CloudServicesPlugin,
			]),
			link: {
				defaultProtocol: "https://",
			},
			indentBlock: {
				offset: 1,
				unit: "em",
			},
			table: {
				contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
			},
			heading: {
				options: [
					{ model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
					{ model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
					{ model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
				],
			},
			fontSize: {
				options: [9, 11, 13, "default", 17, 19, 21],
				supportAllValues: true,
			},
			toolbar: filteredToolbar,
			inlineFile: inlineFileConfig,
			addPolicy: addPolicyConfig,
			addGoals: addGoalsConfig,
		})
			.then((editor) => {
				// This sets tab shortcut to get into ANY sub bar that ck-editor might throw at us. Then using tabs and/or arrow keys and enter will change and press buttons
				editor.keystrokes.set("tab", (data, stop) => {
					const elem = document.getElementsByClassName(`ck-balloon-panel_visible`);
					if (elem.length > 0) {
						elem[0].getElementsByClassName("ck-balloon-rotator__content")[0].getElementsByTagName("button")[0].focus();
						stop(); // Works like data.preventDefault() + evt.stop()
					}
				});
				editor.model.document.on("change:data", (event) => {
					if (onChange) {
						const editorData = editor.getData();
						// TODO find a way to save an empty value editor
						if (editorData !== content && !editor.isUndoingRedoing) {
							handleEditorChange(event, editor);
						} else {
							editor.isUndoingRedoing = false;
						}
					}
				});

				editor.guid = uuid();

				editor.onItemChange = onChange;

				editor.commands.get("tableCellBorderStyle").on("change:value", (evt, propertyName, newValue, oldValue) => {
					editor.execute("tableCellBorderWidth", {
						value: newValue ? "1px" : "",
					});

					editor.execute("tableCellBorderColor", {
						value: newValue ? "#000000" : "",
					});
				});

				editor.editing.view.document.on("keydown", (evt, data) => {
					if (data.ctrlKey && (data.keyCode === 89 || data.keyCode === 90)) {
						handleUndoRedo(data.keyCode === 90);
						data.preventDefault();
						evt.stop();
					}
				});

				editor.editing.view.document.on("mouseup", (evt, data) => {
					const insertTableCommand = editor.commands.get("insertTable");
					if (!insertTableCommand.isEnabled) {
						let toolbarPropertiesOpen = false;
						const editorToolbarBoundingRec = editor.ui.element.querySelector(".ck.ck-editor__top").getBoundingClientRect();
						let editorTableToolbarBoundingRec = null;
						document.querySelectorAll(".ck.ck-balloon-panel").forEach((balloon) => {
							if (balloon && balloon.offsetParent !== null) {
								editorTableToolbarBoundingRec = balloon.getBoundingClientRect();
								toolbarPropertiesOpen = true;
							}
						});

						if (
							toolbarPropertiesOpen &&
							!editor.ui.element.querySelector(".ck.ck-editor__main").style.paddingTop &&
							Math.abs(editorTableToolbarBoundingRec.top - editorToolbarBoundingRec.top) < 40
						) {
							editor.ui.element.querySelector(".ck.ck-editor__main").style.paddingTop = "48px";
							editor.plugins.get("ContextualBalloon").view.top += 48;
							editor.plugins.get("ContextualBalloon").view.element.style.zIndex = 800;
						}
					} else {
						editor.ui.element.querySelector(".ck.ck-editor__main").style.paddingTop = "";
					}
				});

				const clipboardPlugin = editor.plugins.get("ClipboardPipeline");
				if (clipboardPlugin != null) {
					clipboardPlugin.on("inputTransformation", (evt, data) => {
						let content = editor.data.processor.toData(data.content);
						content = content.replace(/font-size:[^;']*(;)?/gi, "").replace(/font-family:[^;']*(;)?/gi, "");
						data.content = editor.data.htmlProcessor.toView(content);
					});
				}

				editor.ui.focusTracker.on("change:isFocused", (event) => {
					if (!editor.ui.focusTracker.isFocused) {
						editor.ui.element.querySelector(".ck.ck-editor__main").style.paddingTop = "";
					}
				});

				editorReference.current = editor;

				// the CKE CSS resets actually break the toolbars when in React/Material. We can look for a better solution, but for now, brute force fix.
				forEach((elem) => {
					if (elem) {
						elem.classList.remove("ck-reset_all");
					}
				}, document.getElementsByClassName("ck-reset_all"));
				forEach((elem) => {
					if (elem) {
						elem.classList.remove("ck-reset");
					}
				}, document.getElementsByClassName("ck-reset"));

				// Prevent hidden file input accessibility errors
				forEach((element) => {
					if (element && element.tagName === "INPUT" && element.getAttribute("type") === "file") {
						element.setAttribute("title", t("app:editor.tooltips.selectFile"));
						if (element.previousSibling && element.previousSibling.tagName === "BUTTON") {
							const labeledBy = element.previousSibling.getAttribute("aria-labelledby");
							if (labeledBy) {
								element.setAttribute("aria-labelledby", labeledBy);
							}
						}
					}
				}, document.getElementsByClassName("ck-hidden"));

				// set up data and pointer to this editor, for undo functions
				editor.initHistoryLength = editor.model.document.history._operations.length;
				if (updatedEditorContentHtml) {
					editor.setData(updatedEditorContentHtml);
				}
				if (typeof setEditorsData === "function") {
					setEditorsData(name, editor);
				}
				if (focus) {
					editor.editing.view.focus();
				}
			})
			.catch((err) => {
				console.log(err);
				console.error(err.stack);
			});
	};

	const destroyEditor = (editor, clearState) => {
		if (editor) {
			try {
				editor.destroy();
			} catch (e) {
				console.log("Error when destroying the editor.", e);
			}
		}

		if (clearState) {
			editorReference.current = null;
		}
	};

	useEffect(() => {
		if (!disabled) {
			if (loadAsync) {
				setTimeout(initEditor);
			} else {
				initEditor();
			}
		} else {
			destroyEditor(editorReference.current, true);
		}

		return () => {
			destroyEditor(editorReference.current);
		};
	}, [disabled]);

	useEffect(() => {
		if (loading && editorReference.current) {
			setLoading(false);
		}
	}, [editorReference.current]);

	// CKEditor is injecting 0 margins to paragraphs... sometimes. This removes it when rendering as HTML.
	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) return;
			if (node.name === "a" && node.attribs.href) {
				if (node.attribs.class && node.attribs.class.indexOf("inlineFile") !== -1) {
					// Link to the new document details
					if (node.attribs.href.indexOf("/document/") === 0) {
						node.attribs.href = `/home${node.attribs.href}`;
					}
				} else if (node.attribs.class && node.attribs.class.indexOf("inlineLink") !== -1) {
					if (node.attribs.href.indexOf("/goal/") === 0) {
						node.attribs.href = `/home${node.attribs.href}`;
					}
				} else {
					node.attribs.href = validateLinkHref(node.attribs.href);
				}
			} else if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (
				node.attribs.style === "margin-top: 0; margin-bottom: 0;" ||
				(node.attribs.style && node.attribs.style.indexOf("font-size") >= 0)
			) {
				// eslint-disable-next-line consistent-return
				return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
			}
		},
	};

	return (
		<>
			<Box className="genericEditor" mt={mt}>
				{title && title.length && (
					<InputLabel htmlFor={name} label={title} size={labelSize} useEllipsis={labelEllipsis} lines={labelLines} bottomSpacing />
				)}
				{ckEditorArguments && (
					<div key={`${name}-${disabled}`} className={classes.editor}>
						{loading && staticToolbar && <div className={clsx(classes.loadingIndicator, "ck", "ck-toolbar")} />}
						<div
							id={name}
							className={clsx({
								[classes.preloadContent]: loadAsync,
							})}
							ref={editorContainer}
							data-cy={`editor-${name}`}
						>
							{Parser(content || "<p></p>", parserOptions)}
						</div>
					</div>
				)}
			</Box>
		</>
	);
};

export default GenericEditor;
