import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, TableCell } from "@mui/material";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import { blackColor, primaryColor, grayColor } from "atlas/assets/jss/shared";
import tableDataStyle from "assets/jss/components/tableDataStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";

const publicUserImageUrl =
	"data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M12 1.25c5.937 0 10.75 4.813 10.75 10.75a10.729 10.729 0 0 1-3.968 8.342A10.708 10.708 0 0 1 12 22.75C6.063 22.75 1.25 17.937 1.25 12S6.063 1.25 12 1.25zm3.063 9.856c1.41-3.81-2.103-5.769-5.4-3.459a.75.75 0 0 1-.43.136c-.595 0-.84 1.361-.307 3.394a.75.75 0 0 1-.726.94c-.013 0 .011-.025-.016.084a1.614 1.614 0 0 0-.034.4v.032c0 .373.274 1.217.25 1.217a.75.75 0 0 1 .726.564c.2.781.623 1.488 1.216 2.035a.75.75 0 0 1 .241.551v.333c0 .586-.09.97-.48 1.41-.502.568-1.403.963-2.849 1.23l.041-.007A9.207 9.207 0 0 0 12 21.25a9.207 9.207 0 0 0 4.746-1.309l-.016-.004c-1.43-.264-2.331-.659-2.834-1.227-.39-.44-.48-.825-.48-1.41v-.333a.75.75 0 0 1 .237-.547 4.183 4.183 0 0 0 1.187-2.007.75.75 0 0 1 .727-.563s.25-.785.25-1.217v-.035c0-.177-.007-.29-.031-.397-.026-.112 0-.084-.02-.084a.75.75 0 0 1-.703-1.01zM12 2.75a9.25 9.25 0 0 0-6.199 16.116 4.583 4.583 0 0 1 1.196-.37c1.131-.21 1.746-.48 1.983-.747.088-.1.103-.164.103-.416v-.018a5.683 5.683 0 0 1-1.281-2.099c-.434-.216-.72-.68-.915-1.28-.146-.45-.237-1.017-.237-1.303v-.026c-.001-.285.013-.5.077-.762.098-.4.295-.738.592-.957-.497-2.458-.049-4.422 1.666-4.593 4.15-2.738 8.974-.2 7.71 4.624.278.223.462.555.552.945.058.25.07.454.07.74v.03c0 .31-.08.862-.223 1.31-.19.599-.485 1.064-.931 1.277a5.683 5.683 0 0 1-1.246 2.057v.022c0 .252.015.316.103.416.237.268.852.537 1.985.747.417.078.82.211 1.2.394A9.25 9.25 0 0 0 12 2.75z'%3E%3C/path%3E%3C/svg%3E";

const useStyles = makeStyles({
	cell: {
		...tableDataStyle.cell,
	},
	headCell: {
		"& div": {
			fontWeight: "600 !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
			whiteSpace: "nowrap",
		},
	},
	deleteHead: {
		"& span": {
			display: "none",
		},
	},
	activeHead: {
		textAlign: "center",
	},
	noResults: {
		padding: "16px",
	},
	link: {
		textDecoration: "none",
		marginLeft: "8px",
	},
	activeButton: {
		margin: 0,
		padding: "0 8px",
		height: "24px",
		"& .active": {},
		"& .inactive": {},
		"& .sendConfirmation": {},
	},
	avatar: {
		fontWeight: "600",
		marginRight: "8px",
	},
	hideTableActions: {
		"& .MuiTableRow-footer p": {
			paddingRight: "16px",
		},
		"& .MuiTablePagination-actions": {
			display: "none",
		},
	},
	subscribersListTable: {
		"& .MuiTablePagination-actions > .Mui-disabled": {
			color: `${grayColor[0]} !important`,
		},
	},
	mobileTable: {
		"& thead": {
			display: "none",
		},
		"& tbody > tr": {
			display: "grid",
			"& td:not(:last-child)": {
				borderColor: "transparent",
			},
		},
	},
});

const SubscribersList = (props) => {
	const { subscribers, meetingTypes, paginationProps, listLoading, handleSort, handleActiveClick, handleDeleteClick } = props;
	const { t } = useTranslation("subscribers");
	const classes = useStyles();

	const subscribersTableHeadings = [
		{
			id: "userEmail",
			disablePadding: false,
			label: t("list.header.emailAddress"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				const { row } = cellProps;
				if (row.public) {
					row.userImageUrl = publicUserImageUrl;
					row.name = row.userEmail;
				}
				return (
					<TableCell key={`emailAddress-${row.id.join("-")}-link-cell`} className={classes.cell}>
						<div title={row.name} style={{ display: "flex", alignItems: "center" }}>
							<GenericAvatar
								className={classes.avatar}
								imageUrl={row.userImageUrl}
								name={row.name}
								initials={`${(row.firstName || "").substr(0, 1)}${(row.lastName || "").substr(0, 1)}`}
								backgroundNumber={(0 % GENERIC_AVATAR_BACKGROUNDS) + 1}
							/>
							<Typography>{row.userEmail}</Typography>
						</div>
					</TableCell>
				);
			},
		},
		{
			id: "meetingTypes",
			disablePadding: false,
			label: t("list.header.meetingTypes"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				const { row, rowIndex } = cellProps;
				return (
					<TableCell className={classes.cell}>
						{row.meetingTypes.map((meetingTypeId) => {
							const meetingType = meetingTypes.find((meetingType) => meetingType.id === meetingTypeId);
							return <div key={`meeting-types-names-${meetingTypeId}-${rowIndex}`}>{`${meetingType ? meetingType.name : ""}`}</div>;
						})}
					</TableCell>
				);
			},
		},
		{
			id: "public",
			disablePadding: false,
			label: t("list.header.public"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				const { row } = cellProps;
				return (
					<TableCell key={`public-${row.id.join("-")}-cell`} className={classes.cell}>
						<div>{`${isMobile ? t("list.cell.public") + ":" : ""} ${t(`list.cell.${row.public ? "yes" : "no"}`)}`}</div>
					</TableCell>
				);
			},
		},
		{
			id: "active",
			disablePadding: false,
			label: t("list.header.active"),
			headClassName: classes.activeHead,
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				const { row } = cellProps;
				return (
					<TableCell key={`active-${row.id.join("-")}-cell`} className={classes.cell}>
						{row.public ? (
							<ButtonWithTooltip
								className={clsx(classes.activeButton, {
									[classes.active]: row.active,
									[classes.inactive]: !row.public && !row.active,
									[classes.sendConfirmation]: row.public && !row.active,
								})}
								variant="outlined"
								primary
								title={t(`tooltips.${row.active ? "active" : row.public ? "sendConfirmation" : "inactive"}`)}
								onClick={() => {
									handleActiveClick(row);
								}}
								dataCy={`active-${row.id.join("-")}-button`}
							>
								{t(`buttons.${row.active ? "active" : row.public ? "sendConfirmation" : "inactive"}`)}
							</ButtonWithTooltip>
						) : (
							<StyledSwitch
								classes={{
									label: classes.switchLabel,
									stateLabel: classes.switchInstructions,
								}}
								inline
								label=""
								title={t(`tooltips.${row.active ? "active" : "inactive"}`)}
								size="small"
								objectToUpdate={row}
								fieldToUpdate="active"
								onLabel={t(`buttons.${row.active ? "active" : "inactive"}`)}
								offLabel={" "}
								onChange={() => handleActiveClick(row)}
								data-cy="subscription-active"
							/>
						)}
					</TableCell>
				);
			},
		},
		{
			id: "delete-subscription",
			disablePadding: false,
			label: "Delete",
			retainCase: true,
			headClassName: classes.deleteHead,
			rowColor: blackColor[1],
			component: (cellProps) => {
				const { row } = cellProps;
				return (
					<TableCell className={classes.cell}>
						<AccessibleIconButton
							onClick={() => {
								handleDeleteClick(row);
							}}
							iconName="remove"
							iconColor={primaryColor[1]}
							tooltipText={t("tooltips.delete")}
							dataCy={`alert-close-${row.id.join("-")}`}
							smallIcon
						/>
					</TableCell>
				);
			},
		},
	];

	return subscribers ? (
		subscribers.length > 0 ? (
			<div
				className={clsx(classes.subscribersListTable, {
					[classes.hideTableActions]: paginationProps.count < paginationProps.rowsPerPage,
					[classes.mobileTable]: isMobile,
				})}
			>
				<TableData
					isLoading={listLoading}
					showToolbar={false}
					headCells={subscribersTableHeadings}
					rows={subscribers}
					selectProperty="id"
					defaultOrderBy="emailAddress"
					checkedAction={undefined}
					rowClickAction={() => {}}
					sortAction={handleSort}
					overrideClasses={classes}
					withoutMultiSelect
					paginationProps={paginationProps}
					data-cy="subscribers-list"
				/>
			</div>
		) : (
			<div className={classes.noResults}>
				<Box>
					<Typography variant="h3">{t("list.noResults.title")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("list.noResults.description")}</Typography>
				</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default SubscribersList;
