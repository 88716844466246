import React, { useState, useRef, useCallback, useEffect } from "react";
import {
	DndContext,
	useSensor,
	useSensors,
	MeasuringStrategy,
	PointerSensor,
	KeyboardSensor,
	DragOverlay,
	rectIntersection,
	defaultDropAnimation,
} from "@dnd-kit/core";
import { createPortal } from "react-dom";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";

import { reorderAgendaBuilderAgendaItems } from "redux/agendaBuilder/actions";
import { reorderMinutesBuilderMeetingItems } from "redux/meeting/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCollisionDetection } from "utils/dragAndDrop";
import DropPlaceholder from "atlas/components/DragAndDrop/DropPlaceholder";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import { List } from "@mui/material";
import { isHeading } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { ITEM_TYPES, HEADINGS, MEETING_ITEMS, DECISIONS } from "utils/enums/ItemTypes";
import TOCHeading from "./TOCHeading";
import TOCMeetingItem from "./TOCMeetingItem";
import TOCDecision from "./TOCDecision";
import { useWidthDown } from "atlas/utils/useWidth";
import { findItemByID } from "views/MeetingEditor/functions/utils";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import makeStyles from "@mui/styles/makeStyles";
import { handleKeyDown } from "./CommonComponents/tocUtils";
import cloneDeep from "lodash/cloneDeep";
import DragPlaceholder from "components/SharedComponents/DragPlaceholder";

const useStyles = makeStyles(() => ({
	dragging: {
		cursor: " grabbing",
		cursor: "-moz-grabbing",
		cursor: " -webkit-grabbing",
		"&:active": {
			cursor: " grabbing",
			cursor: "-moz-grabbing",
			cursor: " -webkit-grabbing",
		},
	},
}));

const measuring = {
	droppable: {
		strategy: MeasuringStrategy.Always,
	},
};

const adjustTranslate = ({ transform }) => {
	return {
		...transform,
		y: transform.y - 25,
	};
};

const dropAnimationConfig = {
	keyframes({ transform }) {
		return [
			{ opacity: 1, transform: CSS.Transform.toString(transform.initial) },
			{
				opacity: 0,
				transform: CSS.Transform.toString({
					...transform.final,
					x: transform.final.x + 5,
					y: transform.final.y + 5,
				}),
			},
		];
	},
	easing: "ease-out",
	sideEffects({ active }) {
		active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
			duration: defaultDropAnimation.duration,
			easing: defaultDropAnimation.easing,
		});
	},
};

const TOCItems = ({
	isAgenda = false,
	isMinutes = false,
	isMeetingTemplate = false,
	outlineItems,
	isStaffUser,
	showContent,
	isClosed,
	toggleTableOfContent,
	isPdfSidebar,
	buttons,
	selected,
	active,
	setActive,
	telemetryPage,
	setDataForAttachmentDocument,
	openGoalsPopup,
	setDataForOutlineItem,
	selectedId,
	isReorderable,
	renderControl,
}) => {
	const classes = useStyles();
	const [items, setItems] = useState(() => outlineItems);
	const [draggedId, setDraggedId] = useState(null);
	const [droppedId, setDroppedId] = useState(null);
	const dragRef = useRef(null);
	const widthDownMd = useWidthDown("md");
	const dragDimensionsRef = useRef({});

	const dispatch = useDispatch();

	useEffect(() => {
		setItems(outlineItems);
	}, [outlineItems]);

	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const { presenting } = useSelector((state) => state.meetingsReducer);
	const { firstDeleteButtonIndex } = useSelector((state) => state.newCopyAndMoveReducer);

	const isValidPosition = useCallback(
		(index, itemType, subHeading, currentIndex, numberOfChildren) => {
			if (index < 0 || index >= items.length || (index > currentIndex && index <= currentIndex + numberOfChildren)) {
				return false; // Outside of the list or within it's own children
			}

			let valid = false;
			const item = items[index];
			const headingType = isAgenda
				? ITEM_TYPES.AGENDA_HEADING
				: isMinutes
					? ITEM_TYPES.MINUTES_HEADING
					: isMeetingTemplate
						? ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING
						: null;

			const _itemType = isAgenda
				? ITEM_TYPES.AGENDA_ITEM
				: isMinutes
					? ITEM_TYPES.MINUTES_ITEM
					: isMeetingTemplate
						? ITEM_TYPES.MEETING_TEMPLATE_MINUTES_ITEM
						: null;
			switch (itemType) {
				case ITEM_TYPES.AGENDA_HEADING:
				case ITEM_TYPES.MINUTES_HEADING:
				case ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING:
					if (!subHeading) {
						// Top-level headings
						if (index === items.length - 1 || (item.itemType === headingType && item.fields.Indent.Value === 0)) {
							// The bottom or the same position as an existing heading are valid
							valid = true;
						}
					} else {
						// Sub-headings
						if (
							index > 0 &&
							(index === items.length - 1 ||
								item.itemType === headingType ||
								(item.itemType === _itemType && isHeading(items, item.attributes?.relationshipGuid)))
						) {
							// The bottom or the same position as an existing heading/sub-heading/top-level and not the top item are valid
							valid = true;
						}
					}
					break;

				case ITEM_TYPES.AGENDA_ITEM:
				case ITEM_TYPES.MINUTES_ITEM:
					// Items
					if (index > 0 && (index === items.length - 1 || item.itemType === headingType || item.itemType === _itemType)) {
						// The bottom or the same position as an existing heading/sub-heading/item and not the top are valid
						valid = true;
					}
					break;

				case ITEM_TYPES.RECOMMENDATION:
				case ITEM_TYPES.MOTION:
					// Items
					if (index > 0) {
						// Any non-top position is valid
						valid = true;
					}
					break;
			}

			return valid;
		},
		[items],
	);

	const getNumberOfChildren = useCallback(
		(startIndex, itemType, subHeading, deleteItem = false) => {
			let count = 0;
			for (let index = startIndex + 1; index < items.length; index++) {
				const item = items[index];
				switch (itemType) {
					case ITEM_TYPES.AGENDA_HEADING:
					case ITEM_TYPES.MINUTES_HEADING:
						if (!subHeading) {
							const headingType = isAgenda ? ITEM_TYPES.AGENDA_HEADING : ITEM_TYPES.MINUTES_HEADING;
							// Top-level headings
							if (item.itemType !== headingType || item.fields.Indent.Value > 0) {
								count++;
							} else {
								return count;
							}
						} else {
							// Sub-headings
							const headingType = isAgenda ? ITEM_TYPES.AGENDA_HEADING : ITEM_TYPES.MINUTES_HEADING;
							if (item.itemType !== headingType) {
								if (deleteItem) {
									const childItems = items.filter((item) => {
										return item.attributes.relationshipGuid == items[startIndex].guid;
									});
									count = childItems.length;
								} else if (item.attributes.relationshipGuid !== items[startIndex].attributes.relationshipGuid) {
									// If the item is not a child of the current main heading
									count++;
								}
							} else {
								return count;
							}
						}
						break;

					case ITEM_TYPES.AGENDA_ITEM:
					case ITEM_TYPES.MINUTES_ITEM:
						// Items
						const headingType = isAgenda ? ITEM_TYPES.RECOMMENDATION : ITEM_TYPES.MOTION;
						if (item.itemType === headingType) {
							count++;
						} else {
							return count;
						}
						break;
				}
			}

			return count;
		},
		[items],
	);

	const getAvailablePosition = useCallback(
		(itemIndex, itemType, subHeading, startIndex, step) => {
			const numberOfChildren = getNumberOfChildren(itemIndex, itemType, subHeading);
			let nextPosition = null;
			for (let index = startIndex; index >= 0 && index < items.length; index += step) {
				if (isValidPosition(index, itemType, subHeading, itemIndex, numberOfChildren)) {
					nextPosition = index;
					break;
				}
			}

			return {
				currentIndex: itemIndex,
				nextPosition,
				numberToMove: nextPosition !== null ? numberOfChildren + 1 : 0,
				nextPositionBlockSize:
					nextPosition !== null
						? getNumberOfChildren(nextPosition, items[nextPosition].itemType, items[nextPosition].fields.Indent.Value > 0) + 1
						: 0,
			};
		},
		[items],
	);

	const setContentAreaOverflow = (value) => {
		let contentArea = document.getElementById("content-area");
		while (contentArea) {
			contentArea.style.overflow = value;
			const element = contentArea;
			setTimeout(() => {
				// Ensure that the scroll position goes back to the top
				element.scrollTop = 0;
			}, 10);
			contentArea = contentArea.parentElement;
		}
	};

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);

		// Get the dimensions of the active element
		const element = document.getElementById(`outline-${active.id}`);
		if (element) {
			const rect = element.getBoundingClientRect();
			dragDimensionsRef.current = { width: rect.width, height: rect.height };
		}

		// Prevent unwanted text-selection and scrolling
		document.body.style.userSelect = "none";
		document.body.classList.add(classes.dragging);
		setContentAreaOverflow("hidden");
	};

	const getOverallTabIndex = (index) => {
		const ifItemCopied = items.findIndex((item) => item.copied);
		if (isAgenda || isMinutes || isReorderable) {
			return 0;
		} else if (renderControl?.selectItems) {
			return 0;
		} else if (renderControl?.reviewItems && ifItemCopied !== -1 && firstDeleteButtonIndex && firstDeleteButtonIndex === index) {
			return 0;
		}

		if (renderControl?.reviewItems && ifItemCopied === -1) {
			document.getElementById("cont")?.focus();
		}
		return -1;
	};

	const handleDragMove = useCallback(
		(e) => {
			const { active, over } = e;

			const activeIndex = items.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = items.findIndex((item) => item.guid === overId);
			if (overId === "toc-footer") {
				setDroppedId(overId);
			} else if (activeIndex >= 0 && overIndex >= 0 && overId !== active.id) {
				const moveData = getAvailablePosition(
					activeIndex,
					items[activeIndex].itemType,
					items[activeIndex].fields.Indent.Value > 0,
					overIndex,
					-1,
				);
				setDroppedId(moveData.nextPosition != null ? items[moveData.nextPosition].guid : null);
			} else {
				setDroppedId(null);
			}
		},
		[items],
	);

	const endDrag = () => {
		setDraggedId(null);
		setDroppedId(null);

		document.body.style.userSelect = null;
		document.body.classList.remove(classes.dragging);
		setContentAreaOverflow(null);
	};

	const getNextAvailablePosition = useCallback(
		(itemToMove, moveUp = true) => {
			const currentIndex = items.findIndex((item) => item.guid === itemToMove.guid);
			const step = moveUp ? -1 : 1;

			return getAvailablePosition(currentIndex, itemToMove.itemType, itemToMove.fields.Indent.Value > 0, currentIndex + step, step);
		},
		[items],
	);

	const moveItem = useCallback(
		(item, moveUp = true, data) => {
			const moveData = data || getNextAvailablePosition(item, moveUp);
			// Check if the move is valid
			if (moveData.nextPosition != null) {
				// Move items and children
				let reorderedItems = [...items];
				const movedItems = reorderedItems.splice(moveData.currentIndex, moveData.numberToMove);
				reorderedItems.splice(
					moveData.nextPosition < moveData.currentIndex
						? moveData.nextPosition
						: moveData.nextPosition - moveData.numberToMove + moveData.nextPositionBlockSize,
					0,
					...movedItems,
				);

				setItems(reorderedItems);
				if (isAgenda) {
					dispatch(reorderAgendaBuilderAgendaItems(reorderedItems));
				} else {
					dispatch(reorderMinutesBuilderMeetingItems(reorderedItems));
				}
			}
		},
		[items],
	);

	const handleDragEnd = useCallback(
		(e) => {
			const { active, over } = e;

			if (isAgenda) {
				telemetryAddEvent("Agenda builder reorder - outline");
			} else {
				telemetryAddEvent("Minutes builder reorder - outline");
			}

			const activeItem = items.find((item) => item.guid === active.id);
			const activeIndex = items.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = items.findIndex((item) => item.guid === overId);
			const availablePosition = getAvailablePosition(
				activeIndex,
				items[activeIndex].itemType,
				items[activeIndex].fields.Indent.Value > 0,
				overId === "toc-footer" ? items.length - 1 : overIndex,
				-1,
			);
			moveItem(activeItem, undefined, availablePosition);

			endDrag();
		},
		[items],
	);

	const handleDragCancel = (e) => {
		endDrag();
	};

	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) return;
			if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (node.name === "p") {
				node.attribs.style = "margin-top: 0; margin-bottom: 0;";
			}
			if (node.name === "a" && node.attribs.href.indexOf("/document/") >= 0 && node.attribs.href.indexOf("/home/document/") < 0) {
				node.attribs.href = node.attribs.href.replace("/document/", "/home/document/");
			}
		},
	};

	const parserOptionsForPdf = (options) => ({
		replace: (node) => {
			if (!node.attribs) return;
			if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (node.name === "p") {
				node.attribs.style = "margin-top: 0; margin-bottom: 0;";
			}
			if (isPdfSidebar) {
				if (options && options.tooltip === true) {
					if (node.name === "span") {
						node.attribs.style = "color:white";
					}
				}
				if (node.name === "a" && node.attribs) {
					if (isStaffUser && node.attribs.class && node.attribs.class.indexOf("inlineFile closed") !== -1) {
						return <></>;
					} else {
						return (
							<a className="" target="" style={{ textDecoration: "none", color: "inherit" }}>
								{node.children[0].data}
							</a>
						);
					}
				}
				if (node.name === "br") {
					return <> </>;
				}
			}
		},
	});

	const findParentById = (guid) => findItemByID(guid, items);

	const getDragComponent = useCallback(() => {
		let component = null;
		const filteredItems = items.filter((item) => !item.deleted);
		if (draggedId) {
			const item = filteredItems.find((item) => item.guid === draggedId);
			const childCount = filteredItems.filter((child) => child.attributes.relationshipGuid === draggedId).length;
			const attachmentCount = item?.attachments?.length || 0;
			const ghostItemCount = childCount === 0 ? attachmentCount : childCount;
			if (item) {
				component = (
					<div style={{ position: "relative" }}>
						<span>{renderTocItem(item, true)}</span>
						{ghostItemCount && ghostItemCount > 0 ? (
							<>
								{Array.from({ length: ghostItemCount })
									.slice(0, 3)
									.map((_, index) => (
										<span className={"ghost" + index} />
									))}
							</>
						) : null}
					</div>
				);
			}
		}

		return component;
	}, [items, draggedId]);

	const isLastChildItem = (item) => {
		const findParentUntilNoAttributes = (child) => {
			const parent = findParentById(child.attributes.relationshipGuid);

			if (parent && Object.keys(parent.attributes).length === 0) {
				return parent;
			} else if (!parent) {
				return item;
			}

			return findParentUntilNoAttributes(parent);
		};

		const lastParent = findParentUntilNoAttributes(item);

		if (lastParent) {
			const children = items.filter((child) => child.attributes.relationshipGuid === lastParent.guid);

			if (!children.length) return true;
			const lastChild = getLastDescendant(children[children.length - 1]);
			return lastChild.guid === item.guid;
		} else {
			const children = items.filter((child) => child.attributes.relationshipGuid === item.guid);
			if (children.length === 0 && (item.fields.Closed || item.fields.Consent || item.fields.PublicComment)) {
				return true;
			}
		}

		return false;
	};

	const getLastDescendant = (item) => {
		const children = items.filter((child) => child.attributes.relationshipGuid === item.guid);

		if (!children.length) {
			return item;
		}

		return getLastDescendant(children[children.length - 1]);
	};

	const checkIfItemIsDraggedIdOrItsDescendants = (item) => {
		const children = items.filter((child) => child.attributes.relationshipGuid === item.guid);
		if (!children.length) {
			return false;
		}

		return children.some((child) => checkIfItemIsDraggedIdOrItsDescendants(child));
	};

	const renderTocItem = (item, clone = false, index, canShowLastItemStyle = true) => {
		if (item.deleted) {
			return null;
		}

		const isConsentSection = Boolean(item?.fields?.Consent?.Value);
		const isPublicCommentSection = Boolean(item?.fields?.PublicComment?.Value);
		const isMemberOnlySection = Boolean(item?.fields?.Closed?.Value);

		if (HEADINGS.includes(item.itemType)) {
			return (
				<TOCHeading
					isSmallDevice={widthDownMd}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					isMemberOnlySection={isMemberOnlySection}
					isClosedMeeting={isClosed}
					toggleTableOfContent={toggleTableOfContent}
					isSubHeading={Boolean(item.attributes.relationshipGuid)}
					key={item.guid}
					section={item}
					parserOptions={isPdfSidebar ? parserOptionsForPdf : parserOptions}
					button={buttons}
					selected={selected}
					setActive={setActive}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					openGoalsPopup={openGoalsPopup}
					setDataForOutlineItem={setDataForOutlineItem}
					isPdfSidebar={isPdfSidebar}
					selectedId={selectedId}
					canDrag={isReorderable}
					canDrop={isReorderable}
					id={item.guid}
					isLastChildItem={isLastChildItem(item) && canShowLastItemStyle}
					isActive={(active?.id || active) === item.guid}
					moveItem={moveItem}
					getNextAvailablePosition={getNextAvailablePosition}
					isAgenda={isAgenda}
					isMinutes={isMinutes}
					presenting={presenting}
					clone={clone}
					index={index}
					renderControl={renderControl}
					getOverallTabIndex={getOverallTabIndex}
				/>
			);
		}

		if (MEETING_ITEMS.includes(item.itemType)) {
			showContent(isMemberOnlySection);
			return (
				<TOCMeetingItem
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					isMemberOnlySection={isMemberOnlySection}
					isClosedMeeting={isClosed}
					isSmallDevice={widthDownMd}
					toggleTableOfContent={toggleTableOfContent}
					key={item.guid}
					item={item}
					parserOptions={isPdfSidebar ? parserOptionsForPdf : parserOptions}
					button={buttons}
					selected={selected}
					setActive={setActive}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					setDataForOutlineItem={setDataForOutlineItem}
					isPdfSidebar={isPdfSidebar}
					selectedId={selectedId}
					openGoalsPopup={openGoalsPopup}
					canDrag={isReorderable}
					canDrop={isReorderable}
					id={item.guid}
					isLastChildItem={isLastChildItem(item) && canShowLastItemStyle}
					isActive={(active?.id || active) === item.guid}
					moveItem={moveItem}
					getNextAvailablePosition={getNextAvailablePosition}
					isAgenda={isAgenda}
					isMinutes={isMinutes}
					presenting={presenting}
					clone={clone}
					index={index}
					renderControl={renderControl}
					getOverallTabIndex={getOverallTabIndex}
				/>
			);
		}

		if (DECISIONS.includes(item.itemType)) {
			const parent = findParentById(item.attributes.relationshipGuid);
			showContent(isMemberOnlySection, true);
			return (
				<TOCDecision
					isSmallDevice={widthDownMd}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					isMemberOnlySection={isMemberOnlySection}
					toggleTableOfContent={toggleTableOfContent}
					isHeadingAction={parent && HEADINGS.includes(parent.itemType)}
					key={item.guid}
					resolution={item}
					parserOptions={isPdfSidebar ? parserOptionsForPdf : parserOptions}
					isClosedMeeting={isClosed}
					button={buttons}
					selected={selected}
					setActive={setActive}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					setDataForOutlineItem={setDataForOutlineItem}
					isPdfSidebar={isPdfSidebar}
					selectedId={selectedId}
					openGoalsPopup={openGoalsPopup}
					canDrag={isReorderable}
					canDrop={isReorderable}
					id={item.guid}
					isLastChildItem={isLastChildItem(item) && canShowLastItemStyle}
					isActive={(active?.id || active) === item.guid}
					moveItem={moveItem}
					getNextAvailablePosition={getNextAvailablePosition}
					isAgenda={isAgenda}
					isMinutes={isMinutes}
					presenting={presenting}
					clone={clone}
					index={index}
					renderControl={renderControl}
					getOverallTabIndex={getOverallTabIndex}
				/>
			);
		}

		return null;
	};

	const movedItemPlaceholder = (
		<div
			className="toc-moved-item-placeholder"
			style={{
				height: `${dragDimensionsRef.current?.height || 50}px`,
				width: `${dragDimensionsRef.current?.width || 500}px`,
			}}
		/>
	);

	const getItems = (items, droppedId) => {
		const domElements = [];
		const filteredItems = isReorderable ? cloneDeep(items).filter((item) => !checkIfItemIsDraggedIdOrItsDescendants(item)) : items;
		filteredItems.map((item, index) => {
			var nextIndex = index + 1;
			var nextItemIsBeingDroppedOver = nextIndex < items.length && droppedId === items[nextIndex].guid;
			const element = draggedId === item.guid ? movedItemPlaceholder : renderTocItem(item, false, index, !nextItemIsBeingDroppedOver);
			if (isReorderable) {
				if (droppedId === item.guid) {
					// Apply any heading outline styles to the placeholder
					const previousItem = index > 0 ? items[index - 1] : item; // The placeholder is displayed above the droppedId item, so check the to see if the previous item is the last child
					const isConsentSection = Boolean(previousItem?.fields?.Consent?.Value);
					const isPublicCommentSection = Boolean(previousItem?.fields?.PublicComment?.Value);
					const isMemberOnlySection = Boolean(previousItem?.fields?.Closed?.Value);
					const isLastItem = isLastChildItem(previousItem);

					domElements.push(
						<Droppable
							dropComponent={DropPlaceholder}
							key={`placeholder-before-${item.guid}`}
							dropId={`${item.guid}-placeholder`}
							component="li"
							useIsOverStyle={false}
							className={clsx({
								"member-only-section": isMemberOnlySection,
								"consent-section-outline": isConsentSection,
								"public-comment-section-outline": isPublicCommentSection,
								"member-only-section-last-child-item": isLastItem && isMemberOnlySection,
								"consent-section-last-child-item": isLastItem && isConsentSection,
								"public-comment-section-last-child-item": isLastItem && isPublicCommentSection,
							})}
						/>,
					);
				}
				domElements.push(element);
			} else {
				domElements.push(element);
			}
			return element || <div key={`item${item.guid}`}>{item.fields.Name.Value}</div>;
		});

		if (droppedId === "toc-footer") {
			domElements.push(
				<Droppable
					dropComponent={DropPlaceholder}
					key={`placeholder-before-toc-footer`}
					dropId={`toc-footer-placeholder`}
					component="li"
				/>,
			);
		}

		domElements.push(<DragPlaceholder canDrop />);
		return domElements;
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
			measuring={measuring}
			onDragStart={handleDragStart}
			onDragMove={handleDragMove}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}
		>
			<List component="ul" disablePadding id="item-outline-list" data-cy="item-outline-list" onKeyDown={handleKeyDown}>
				{getItems(outlineItems, droppedId)}
			</List>
			{createPortal(
				<DragOverlay
					dropAnimation={dropAnimationConfig}
					modifiers={[adjustTranslate]}
					style={{ height: `${dragDimensionsRef.current?.height || 50}px`, width: `${dragDimensionsRef.current?.width || 500}px` }}
				>
					<DragPresentation ref={dragRef}>{getDragComponent()}</DragPresentation>
				</DragOverlay>,
				document.body,
			)}
		</DndContext>
	);
};

export default TOCItems;
