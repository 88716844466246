import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { v4 as uuid } from "uuid";
import { debounce } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import makeStyles from "@mui/styles/makeStyles";
import { Container, List } from "@mui/material";
import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import MinutesItemTypesEnum from "utils/enums/MinutesItemTypes";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import LiveMeetingItem from "./LiveMeetingItem";
import { findItemByID } from "views/MeetingEditor/functions/utils";
import {
	addMotion,
	setActive,
	setSelected,
	timestampItem,
	updateMinutesItems,
	reorderMinutesBuilderMeetingItems,
	addMinutesItem,
	deleteMinutesItem,
	restoreMinutesItem,
	setMinutesEditorMinutesItems,
} from "redux/meeting/actions";
import { adoptPublishPreviousMinutes } from "redux/liveMeeting/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { timeStampExists } from "utils/videoTimeStamping";
import SingletonEditor from "components/Editor/SingletonEditor";
import LiveMeetingHeader from "./LiveMeetingHeader";
import LiveMeetingRequestToSpeak from "views/LiveMeeting/components/LiveMeetingRequestToSpeak";
import AdoptPublishErrorDialog from "components/Dialogs/AdoptPublishErrorDialog";
import { GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED } from "redux/policyGovernance/types";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import { getCollisionDetection } from "utils/dragAndDrop";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { ITEM_TYPES, HEADINGS } from "utils/enums/ItemTypes";
import { createPortal } from "react-dom";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import DropPlaceholder from "atlas/components/DragAndDrop/DropPlaceholder";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import { isHeading, prepareAttachmentDuplication } from "utils/meetingElement";
import notifierMessage from "utils/notifierMessage";
import { focusEditor, scrollToActive } from "./utilities";

const useEditorStyles = makeStyles(() => ({
	editorFieldHide: {
		visibility: "hidden",
	},
	loadingIndicator: {
		height: "49.4px",
		width: "100%",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		left: "0",
		top: "0",
		boxSizing: "border-box",
	},
	editorContentSmUp: {
		minWidth: "450px",
		boxSizing: "border-box",
		overflowX: "hidden",
		overflowY: "auto",
		"-webkit-transform": "translate3d(0, 0, 0)",
		width: (props) => (props.showOutline && !props.showSplitPane ? "calc(100% - 265px)" : "100%"),
		marginLeft: (props) => (props.showOutline && !props.showSplitPane ? "265px" : "0"),
		height: (props) => (props.showSplitPane ? `calc(100vh - ${props.dynamicHeightOffset}px)` : undefined),
		overflow: (props) => (props.showSplitPane ? "auto" : undefined),
	},
	contentContainer: {
		maxWidth: "7.5in !important",
		paddingTop: "8px !important",
		paddingBottom: "8px !important",
	},
	dragging: {
		cursor: " grabbing",
		cursor: "-moz-grabbing",
		cursor: " -webkit-grabbing",
		"&:active": {
			cursor: " grabbing",
			cursor: "-moz-grabbing",
			cursor: " -webkit-grabbing",
		},
	},
}));

const tocFooterDropId = "toc-footer";

const MinutesEditor = (props) => {
	const {
		editorToolbarRef,
		showSignIn,
		handleUpdateMotion,
		queueFileUploads,
		addPolicy,
		invalidFileExtension,
		addGoals,
		votingData,
		onlineVoters,
		votingSettings,
		adoptUpdating,
		showOutline,
		showSplitPane,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const widthDownLg = useWidthDown("lg");
	const { t } = useTranslation("meetings");
	const { t: tMinutesMenu } = useTranslation("meetingMenu");
	const scrollToSelected = useRef(false);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const client = useSelector((state) => state.appReducer.signalR?.client);
	const {
		minutesItems,
		agendaItems,
		meeting,
		meeting: { rollCall, otherRollCallTypes } = {},
		additionalUsers,
		presenting,
		selected,
		header,
		footer,
		requestsToSpeak,
		active,
		focus,
		field: activeField,
	} = useSelector((state) => state.meetingsReducer);
	const policyAttachmentsMinutes = useSelector((state) => state.policyGovernanceReducer.policyAttachmentsMinutes) || [];
	const multiplePolicyData = useSelector((state) => state.policyGovernanceReducer.multiplePolicyData);
	const [editorFields, _setEditorFields] = useState([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [triggerEditorChange, setTriggerEditorChange] = useState(false);
	const [adoptPublishError, setAdoptPublishError] = useState(null);
	const [deletedFieldIdx, setDeletedFieldIdx] = useState(-1);
	const [policyAttachments, setPolicyAttachments] = useState({});
	const [menuAnchor, setMenuAnchor] = useState({});
	const [editorDynamicHeightOffset, setEditorDyamicHeightOffset] = useState(0);
	const editorScrollableContainer = useRef(null);
	const editorFieldsRef = useRef(editorFields);
	const [elementRefsSet, setElementRefsSet] = useState(null);
	const elementsRef = useRef([]);
	const elementsRefStatus = useRef({ rerender: true, status: [] }); // Used to determine if all expected element refs have been set
	const classes = useEditorStyles({
		showOutline: showOutline && !widthDownLg,
		showSplitPane,
		dynamicHeightOffset: editorDynamicHeightOffset,
	});

	useEffect(() => {
		initializeFields(minutesItems);
	}, []);

	useEffect(() => {
		//if outline has been ONLY reordered then just a length check wont help, it wont reinitialize the fields.
		//We need to also check if the order of the items has changed based on a soft check for the item array as a point of data.
		initializeFields(minutesItems);
	}, [JSON.stringify(minutesItems)]);

	const checkIfMeetingStarted = useCallback(() => {
		if (meeting && meeting.startTimeStamp) {
			const meetingStartTimeStamp = meeting.startTimeStamp;
			const currentTimeStamp = Math.floor(Date.now() / 1000);
			return meetingStartTimeStamp < currentTimeStamp;
		}
		return false;
	}, [meeting]);

	const hasMeetingStarted = checkIfMeetingStarted();

	const setEditorFields = (data) => {
		editorFieldsRef.current = data;
		_setEditorFields(data);
	};

	const getDefaultPublicCommentHeading = (items) =>
		items.find((item) => !item.deleted && item.itemType === AgendaItemTypesEnum().HEADING.value && item.fields.PublicComment.Value);

	const getMinutesItem = (minutesItem) =>
		minutesItems.find((item) => minutesItem.guid === item.guid || minutesItem.headingGuid === item.guid) || { attachments: [] };

	const afterElementRefSet = useCallback((index) => {
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status[index] = true;

			if (!elementsRefStatus.current.status.find((value) => !value)) {
				elementsRefStatus.current.rerender = false;
				setElementRefsSet({}); //Set to a new object reference just to trigger a re-render
			}
		}
	}, []);

	const getParentData = useCallback(
		(item, itemType, subHeading, fields) => {
			const itemIndex = minutesItems.findIndex((current) => current.guid === item.guid);

			// Get the item that the new one will be inserted after
			let previousItem = null;
			if (itemType === ITEM_TYPES.MINUTES_HEADING && !subHeading) {
				// A new heading goes after the previous heading
				if (item) {
					for (let index = 0; index < minutesItems.length; index++) {
						const currentItem = minutesItems[index];
						if (
							(!previousItem && currentItem.guid === item.guid) ||
							(previousItem && (currentItem.itemType !== ITEM_TYPES.MINUTES_HEADING || currentItem.fields.Indent.Value > 0))
						) {
							previousItem = currentItem;
						} else if (previousItem) {
							break;
						}
					}
				}
			} else if (itemType === ITEM_TYPES.MINUTES_HEADING && subHeading) {
				// A new sub-heading the previous heading/item and any child items
				for (let index = 0; index < minutesItems.length; index++) {
					const currentItem = minutesItems[index];
					if (
						(!previousItem && currentItem.guid === item.guid) ||
						(previousItem && (currentItem.itemType === ITEM_TYPES.MOTION || currentItem.itemType === ITEM_TYPES.MINUTES_ITEM))
					) {
						previousItem = currentItem;
					} else if (previousItem) {
						break;
					}
				}
			} else {
				// A new sub-heading/item/recommendation goes after the previous heading/item and any recommendations
				for (let index = 0; index < minutesItems.length; index++) {
					const currentItem = minutesItems[index];
					if ((!previousItem && currentItem.guid === item.guid) || (previousItem && currentItem.itemType === ITEM_TYPES.MOTION)) {
						previousItem = currentItem;
					} else if (previousItem) {
						break;
					}
				}
			}

			// Get the fields insertion index
			let fieldsInsertIndex = 1; // The insert location is index + 2, so this inserts after the header
			if (previousItem) {
				fieldsInsertIndex =
					fields.findIndex((field) => {
						return field.name === previousItem.guid;
					}) + 2;
			}

			// Get the items insertion index
			let itemsInsertIndex = 0; // Insert at the start of the list
			if (previousItem) {
				// Insert after the previous item
				itemsInsertIndex =
					minutesItems.findIndex((item) => {
						return item.guid === previousItem.guid;
					}) + 1;
			}

			// Get the parent
			let parentItem = null;
			if (itemsInsertIndex > 0 && (itemType !== ITEM_TYPES.MINUTES_HEADING || subHeading)) {
				for (let index = itemsInsertIndex - 1; index >= 0; index--) {
					const currentItem = minutesItems[index];
					if (
						(itemType === ITEM_TYPES.MINUTES_HEADING &&
							subHeading &&
							currentItem.itemType === ITEM_TYPES.MINUTES_HEADING &&
							currentItem.fields.Indent.Value === 0) ||
						(itemType === ITEM_TYPES.MINUTES_ITEM && currentItem.itemType === ITEM_TYPES.MINUTES_HEADING) ||
						(itemType === ITEM_TYPES.MOTION && currentItem.itemType !== ITEM_TYPES.MOTION)
					) {
						// Set parent - If the parent has been found we can stop searching for both
						parentItem = currentItem;
						break;
					}
				}
			}

			return { itemIndex, fieldsInsertIndex, itemsInsertIndex, parentItem };
		},
		[minutesItems],
	);

	const getNumberOfChildren = useCallback(
		(startIndex, itemType, subHeading, deleteItem = false) => {
			let count = 0;
			for (let index = startIndex + 1; index < minutesItems.length; index++) {
				const item = minutesItems[index];
				switch (itemType) {
					case ITEM_TYPES.MINUTES_HEADING:
						if (!subHeading) {
							// Top-level headings
							if (item.itemType !== ITEM_TYPES.MINUTES_HEADING || item.fields.Indent.Value > 0) {
								count++;
							} else {
								return count;
							}
						} else {
							// Sub-headings
							if (item.itemType !== ITEM_TYPES.MINUTES_HEADING) {
								if (deleteItem) {
									const childItems = minutesItems.filter((item) => {
										return item.attributes.relationshipGuid == minutesItems[startIndex].guid;
									});
									count = childItems.length;
								} else if (item.attributes.relationshipGuid !== minutesItems[startIndex].attributes.relationshipGuid) {
									// If the item is not a child of the current main heading
									count++;
								}
							} else {
								return count;
							}
						}
						break;

					case ITEM_TYPES.MINUTES_ITEM:
						// Items
						if (item.itemType === ITEM_TYPES.RECOMMENDATION) {
							count++;
						} else {
							return count;
						}
						break;
				}
			}

			return count;
		},
		[minutesItems],
	);

	const createNewItem = useCallback(
		(item, itemType, subHeading = false, duplicate = false, consentAction = false) => {
			switch (itemType) {
				case ITEM_TYPES.MINUTES_HEADING:
					telemetryAddEvent("Minutes builder add section");
					break;

				case ITEM_TYPES.MINUTES_ITEM:
					telemetryAddEvent("Minutes builder add item");
					break;

				case ITEM_TYPES.MOTION:
					telemetryAddEvent("Minutes builder add motion");
					break;
			}

			const newFields = [...editorFieldsRef.current];
			const { itemIndex, fieldsInsertIndex, itemsInsertIndex, parentItem } = getParentData(item, itemType, subHeading, newFields);
			const numberOfChildren = getNumberOfChildren(itemIndex, itemType, subHeading);
			const newItemGuid = uuid();
			let sourceItem = cloneDeep(item);
			if (duplicate) {
				sourceItem = prepareAttachmentDuplication(sourceItem);
			}
			let consentActionItem = null;
			if (consentAction) {
				consentActionItem = {
					guid: newItemGuid,
					itemType: 7,
					fields: {
						Name: {
							Value: "<p>Consent Action</p>",
						},
					},
					deleted: false,
				};
			}

			const fieldsToAdd = [
				{
					guid: newItemGuid,
					name: newItemGuid,
					content: duplicate ? sourceItem.fields?.Name?.Value || "" : consentAction ? "<p>Consent Action<p>" : "",
					toolbar: parentItem?.fields?.Closed?.Value ? "simpleMO" : "simple",
					isMemberOnly: Boolean(parentItem?.fields?.Closed?.Value),
					deleted: false,
				},
				{
					guid: newItemGuid,
					name: `${newItemGuid}-text`,
					content: duplicate ? sourceItem.fields?.Text?.Value || "" : "",
					toolbar: parentItem?.fields?.Closed?.Value ? "agendaItemTextMO" : "agendaItemText",
					isMemberOnly: Boolean(parentItem?.fields?.Closed?.Value),
					deleted: !duplicate || !sourceItem.fields?.Text?.Value || consentAction,
				},
			];
			const itemsToAdd = [
				{
					sourceItem: duplicate ? sourceItem : consentAction ? consentActionItem : undefined,
					itemType,
					subHeading,
					newItemGuid,
					parentItem,
					parentGuid: parentItem?.guid,
				},
			];

			// If we are duplicating an item, also duplicate it's children
			if (duplicate && numberOfChildren > 0) {
				const guidMap = { [sourceItem.guid]: newItemGuid };
				for (let index = itemIndex + 1; index <= itemIndex + numberOfChildren; index++) {
					const childItem = prepareAttachmentDuplication(cloneDeep(minutesItems[index]));
					const newChildItemGuid = uuid();
					guidMap[childItem.guid] = newChildItemGuid;
					fieldsToAdd.push(
						{
							guid: newChildItemGuid,
							name: newChildItemGuid,
							content: childItem.fields?.Name?.Value || "",
							toolbar: childItem?.fields?.Closed?.Value ? "simpleMO" : "simple",
							isMemberOnly: Boolean(childItem?.fields?.Closed?.Value),
							deleted: false,
						},
						{
							guid: newChildItemGuid,
							name: `${newChildItemGuid}-text`,
							content: childItem.fields?.Text?.Value || "",
							toolbar: childItem?.fields?.Closed?.Value ? "agendaItemTextMO" : "agendaItemText",
							isMemberOnly: Boolean(childItem?.fields?.Closed?.Value),
							deleted: !childItem.fields?.Text?.Value,
						},
					);
					itemsToAdd.push({
						sourceItem: childItem,
						itemType: childItem.itemType,
						subHeading: childItem.fields?.Indent?.Value === 1,
						newItemGuid: newChildItemGuid,
						parentItem,
						parentGuid: guidMap[childItem.attributes?.relationshipGuid],
					});
				}
			}

			newFields.splice(fieldsInsertIndex, 0, ...fieldsToAdd);
			elementsRef.current.splice(fieldsInsertIndex, 0, ...fieldsToAdd.map(() => null));
			elementsRefStatus.current.rerender = true;
			setEditorFields(newFields);

			dispatch(addMinutesItem(itemsToAdd, newItemGuid, itemsInsertIndex, parentItem));
			if (!consentAction) {
				focusOnEditor(newItemGuid);
			}
			handleMenu();
		},
		[minutesItems],
	);

	const toggleBooleanField = useCallback(
		(item, field) => {
			dispatch(
				setMinutesEditorMinutesItems(
					[{ nonEditor: true, fieldName: item.guid, name: field, fieldData: !Boolean(item?.fields?.[field]?.Value) }],
					editorFieldsRef.current,
				),
			);
			// If closed is toggled, we need to re-render the editor to update the attachment icons
			let itemsToRefresh = [];
			if (field === "Closed") {
				//const newFields = [...editorFieldsRef.current];
				const itemIndex = minutesItems.findIndex((current) => current.guid === item.guid);
				const numberOfChildren = getNumberOfChildren(
					itemIndex,
					item.itemType,
					HEADINGS.includes(item.itemType) && item.fields.Indent.Value > 0,
					true,
				);
				itemsToRefresh = minutesItems.slice(itemIndex, itemIndex + numberOfChildren + 1).map((item) => item.guid);
			}

			if (field === "Consent" && item.fields.Consent.Value) {
				const itemIndex = minutesItems.findIndex((current) => current.guid === item.guid);
				let hasMotion = false;
				for (let i = itemIndex + 1; i < minutesItems.length; i += 1) {
					const currentItem = minutesItems[i];
					if (currentItem.itemType === ITEM_TYPES.MINUTES_HEADING) break;
					if (currentItem.itemType === ITEM_TYPES.MOTION && currentItem.attributes.relationshipGuid === item.guid) hasMotion = true;
				}
				if (!hasMotion) {
					createNewItem(item, ITEM_TYPES.MOTION, false, false, true);
				}
			}

			initializeFields(minutesItems, itemsToRefresh);
			handleMenu();
		},
		[minutesItems],
	);

	const addItem = (item) => createNewItem(item, ITEM_TYPES.MINUTES_ITEM);

	const addHeading = (item) => createNewItem(item, ITEM_TYPES.MINUTES_HEADING);

	const addSubHeading = (item) => createNewItem(item, ITEM_TYPES.MINUTES_HEADING, true);

	const toggleMembersOnly = (item) => toggleBooleanField(item, "Closed");

	const toggleConsent = (item) => toggleBooleanField(item, "Consent");

	const togglePublicComment = (item) => toggleBooleanField(item, "PublicComment");

	const duplicateItem = (item) => createNewItem(item, item.itemType, item.fields.Indent.Value === 1, true);

	const getItemOptions = useCallback(
		({ item, isHeading, isSubHeading, isMemberOnlyHeading, isConsentHeading, isPublicCommentHeading, showVotingStatus, isResolution }) => {
			// Item menu options
			const menuOptions = [];

			if (!hasMeetingStarted) return undefined;

			if (hasMeetingStarted) {
				menuOptions.push({
					label: t("meetingMenu:duplicate"),
					actionFunction: () => duplicateItem(item),
					"data-cy": "duplicate",
				});

				if (getNextAvailablePosition(item, true).nextPosition !== null) {
					menuOptions.push({
						label: t("meetingMenu:moveUp"),
						actionFunction: () => moveItem(item, true),
						"data-cy": "move-up",
					});
				}
				if (getNextAvailablePosition(item, false).nextPosition !== null) {
					menuOptions.push({
						label: t("meetingMenu:moveDown"),
						actionFunction: () => moveItem(item, false),
						"data-cy": "move-down",
					});
				}
			}

			if (isResolution) {
				if (showVotingStatus) {
					menuOptions.push({
						key: "reset-vote",
						label: t("voting.buttons.resetVote"),
						"data-cy": "reset-vote",
					});
				}

				// menuOptions.push({
				// 	label: t("meetingMenu:copyToAgenda"),
				// 	actionFunction: () => {},
				// 	separator: true,
				// 	"data-cy": "copy-to-agenda",
				// });

				// menuOptions.push({
				// 	label: t("meetingMenu:moveToAgenda"),
				// 	actionFunction: () => {},
				// 	"data-cy": "move-to-agenda",
				// });
			}

			if (isResolution || hasMeetingStarted) {
				menuOptions.push({
					label: t("meetingMenu:delete"),
					actionFunction: () => deleteItem(item),
					"data-cy": "delete",
				});
			}

			if (isHeading && !isSubHeading && hasMeetingStarted) {
				menuOptions.push({
					label: isMemberOnlyHeading ? t("meetingMenu:setPublicSection") : t("meetingMenu:setMemberOnlySection"),
					actionFunction: () => toggleMembersOnly(item),
					separator: true,
					"data-cy": "members-only",
				});
				menuOptions.push({
					label: isConsentHeading ? t("meetingMenu:transformSection") : t("meetingMenu:transformSectionConsent"),
					actionFunction: () => toggleConsent(item),
					"data-cy": "consent",
				});
				menuOptions.push({
					label: isPublicCommentHeading ? t("meetingMenu:transformSection") : t("meetingMenu:transformSectionPublicComment"),
					actionFunction: () => togglePublicComment(item),
					"data-cy": "public-comment",
				});
			}

			return menuOptions;
		},
		[minutesItems, meeting],
	);

	const addItemMenuOptions = useMemo(
		() => [
			{
				button: tMinutesMenu("addItem"),
				label: tMinutesMenu("addItemMenu"),
				actionFunction: addItem,
				"data-cy": "add-minutes-item",
			},
			{
				button: tMinutesMenu("addHeader"),
				label: tMinutesMenu("addHeaderMenu"),
				actionFunction: addHeading,
				"data-cy": "add-minutes-heading",
			},
			{
				button: tMinutesMenu("addSubHeading"),
				label: tMinutesMenu("addSubHeadingMenu"),
				actionFunction: addSubHeading,
				"data-cy": "add-sub-heading",
			},
		],
		[minutesItems],
	);

	//#region DND Setup
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const dragRef = useRef(null);
	const dragDimensionsRef = useRef({});
	const [draggedId, setDraggedId] = useState(null);
	const [droppedId, setDroppedId] = useState(null);

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);

		// Get the dimensions of the active element
		const element = document.getElementById(`meeting-item-${active.id}`);
		if (element) {
			const rect = element.getBoundingClientRect();
			dragDimensionsRef.current = { width: rect.width, height: rect.height };
		}

		// Prevent unwanted text-selection and scrolling
		document.body.style.userSelect = "none";
		document.body.classList.add(classes.dragging);
		setContentAreaOverflow("hidden");
	};

	const handleDragMove = useCallback(
		(e) => {
			const { active, over } = e;

			const activeIndex = minutesItems.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = minutesItems.findIndex((item) => item.guid === overId);

			if (overId === tocFooterDropId) {
				setDroppedId(overId);
			} else if (activeIndex >= 0 && overIndex >= 0 && overId !== active.id) {
				const moveData = getAvailablePosition(
					activeIndex,
					minutesItems[activeIndex].itemType,
					minutesItems[activeIndex].fields.Indent.Value > 0,
					overIndex,
					-1,
				);

				setDroppedId(moveData.nextPosition != null ? minutesItems[moveData.nextPosition].guid : null);
			} else {
				setDroppedId(null);
			}
		},
		[minutesItems],
	);

	const handleDragEnd = useCallback(
		(e) => {
			const { active, over } = e;

			telemetryAddEvent("Minutes builder reorder - body");

			const activeItem = minutesItems.find((item) => item.guid === active.id);
			const activeIndex = minutesItems.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = minutesItems.findIndex((item) => item.guid === overId);
			moveItem(
				activeItem,
				undefined,
				getAvailablePosition(
					activeIndex,
					minutesItems[activeIndex].itemType,
					minutesItems[activeIndex].fields.Indent.Value > 0,
					overId === tocFooterDropId ? minutesItems.length - 1 : overIndex,
					-1,
				),
			);

			endDrag();
		},
		[minutesItems],
	);

	const handleDragCancel = (e) => {
		endDrag();
	};

	const endDrag = () => {
		setDraggedId(null);
		setDroppedId(null);

		document.body.style.userSelect = null;
		document.body.classList.remove(classes.dragging);
		setContentAreaOverflow(null);
	};

	const getAvailablePosition = useCallback(
		(itemIndex, itemType, subHeading, startIndex, step) => {
			const numberOfChildren = getNumberOfChildren(itemIndex, itemType, subHeading);
			let nextPosition = null;
			for (let index = startIndex; index >= 0 && index < minutesItems.length; index += step) {
				if (isValidPosition(index, itemType, subHeading, itemIndex, numberOfChildren)) {
					nextPosition = index;
					break;
				}
			}

			return {
				currentIndex: itemIndex,
				nextPosition,
				numberToMove: nextPosition !== null ? numberOfChildren + 1 : 0,
				nextPositionBlockSize:
					nextPosition !== null
						? getNumberOfChildren(nextPosition, minutesItems[nextPosition].itemType, minutesItems[nextPosition].fields.Indent.Value > 0) + 1
						: 0,
			};
		},
		[minutesItems],
	);

	const isValidPosition = useCallback(
		(index, itemType, subHeading, currentIndex, numberOfChildren) => {
			if (index < 0 || index >= minutesItems.length || (index > currentIndex && index <= currentIndex + numberOfChildren)) {
				return false; // Outside of the list or within it's own children
			}

			let valid = false;
			const item = minutesItems[index];
			switch (itemType) {
				case ITEM_TYPES.MINUTES_HEADING:
					if (!subHeading) {
						// Top-level headings
						if (index === minutesItems.length - 1 || (item.itemType === ITEM_TYPES.MINUTES_HEADING && item.fields.Indent.Value === 0)) {
							// The bottom or the same position as an existing heading are valid
							valid = true;
						}
					} else {
						// Sub-headings
						if (
							index > 0 &&
							(index === minutesItems.length - 1 ||
								item.itemType === ITEM_TYPES.MINUTES_HEADING ||
								(item.itemType === ITEM_TYPES.MINUTES_ITEM && isHeading(minutesItems, item.attributes?.relationshipGuid)))
						) {
							// The bottom or the same position as an existing heading/sub-heading/top-level and not the top item are valid
							valid = true;
						}
					}
					break;

				case ITEM_TYPES.MINUTES_ITEM:
					// Items
					if (
						index > 0 &&
						(index === minutesItems.length - 1 || item.itemType === ITEM_TYPES.MINUTES_HEADING || item.itemType === ITEM_TYPES.MINUTES_ITEM)
					) {
						// The bottom or the same position as an existing heading/sub-heading/item and not the top are valid
						valid = true;
					}
					break;

				case ITEM_TYPES.MOTION:
					// Items
					if (index > 0) {
						// Any non-top position is valid
						valid = true;
					}
					break;
			}

			return valid;
		},
		[minutesItems],
	);

	const getDragComponent = useCallback(() => {
		let component = null;
		if (draggedId) {
			const item = minutesItems.find((item) => item.guid === draggedId);
			if (item) {
				const isConsentHeading = item && item.fields && item.fields.Consent && item.fields.Consent.Value;
				const isPublicCommentHeading = item && item.fields && item.fields.PublicComment && item.fields.PublicComment.Value;
				const isMemberOnlyHeading = item && item.fields && item.fields.Closed.Value;
				const parent = findItemByID(item.attributes.relationshipGuid, minutesItems);

				component = (
					<LiveMeetingItem
						readOnly
						key={item.guid}
						item={item}
						isClosedMeeting={meeting.closed}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isHeading={item.itemType === MinutesItemTypesEnum().HEADING.value}
						isItem={item.itemType === MinutesItemTypesEnum().ITEM.value}
						isResolution={item.itemType === MinutesItemTypesEnum().RESOLUTION.value}
						isHeadingAction={parent && parent.itemType === MinutesItemTypesEnum().HEADING.value}
						isSubHeading={Boolean(item.attributes.relationshipGuid)}
						meeting={meeting}
						elementsRef={elementsRef}
					/>
				);
			}
		}

		return component;
	}, [minutesItems, draggedId]);

	const setContentAreaOverflow = (value) => {
		let contentArea = document.getElementById("content-area");
		while (contentArea) {
			contentArea.style.overflow = value;
			const element = contentArea;
			setTimeout(() => {
				// Ensure that the scroll position goes back to the top
				element.scrollTop = 0;
			}, 10);
			contentArea = contentArea.parentElement;
		}
	};

	const moveItem = useCallback(
		(item, moveUp = true, data) => {
			const moveData = data || getNextAvailablePosition(item, moveUp);
			const nextPosition = moveUp ? moveData.nextPosition : moveData.nextPosition - 1;
			// Check if the move is valid
			if (nextPosition != null) {
				// Move items and children
				let reorderedItems = [...minutesItems];
				const movedItems = reorderedItems.splice(moveData.currentIndex, moveData.numberToMove);
				reorderedItems.splice(
					nextPosition < moveData.currentIndex ? nextPosition : nextPosition - moveData.numberToMove + moveData.nextPositionBlockSize,
					0,
					...movedItems,
				);

				initializeFields(reorderedItems);
				dispatch(reorderMinutesBuilderMeetingItems(reorderedItems));
			}

			handleMenu();
		},
		[minutesItems],
	);

	const deleteItem = useCallback(
		(item) => {
			switch (item.itemType) {
				case ITEM_TYPES.MINUTES_HEADING:
					telemetryAddEvent("Minutes builder delete section");
					break;

				case ITEM_TYPES.MINUTES_ITEM:
					telemetryAddEvent("Minutes builder delete item");
					break;

				case ITEM_TYPES.MOTION:
					telemetryAddEvent("Minutes builder delete motion");
					break;
			}

			const itemIndex = minutesItems.findIndex((current) => current.guid === item.guid);
			const numberOfChildren = getNumberOfChildren(
				itemIndex,
				item.itemType,
				HEADINGS.includes(item.itemType) && item.fields.Indent.Value > 0,
			);

			// Delete the item
			dispatch(deleteMinutesItem(item.guid));

			let itemTypeString;
			switch (item.itemType) {
				case ITEM_TYPES.MINUTES_HEADING:
					itemTypeString = item.fields?.Indent?.Value > 0 ? "deleteSubHeading" : "deleteSection";
					break;

				case ITEM_TYPES.MOTION:
					itemTypeString = "deleteMotion";
					break;

				default:
					itemTypeString = "deleteMinutesItem";
					break;
			}

			let option = notifierMessage(
				t(`snackbar.success.${itemTypeString}`, { count: numberOfChildren }),
				"success",
				() => restoreItem(item),
				10000,
			);
			dispatch(setSnackbarOptions(option));

			handleMenu();
		},
		[minutesItems],
	);

	const restoreItem = useCallback(
		(deletedItem) => {
			if (deletedItem) {
				const newFields = [...editorFieldsRef.current];

				setEditorFields(newFields);

				dispatch(restoreMinutesItem(deletedItem.guid));
			}
		},
		[minutesItems],
	);

	const getNextAvailablePosition = useCallback(
		(itemToMove, moveUp = true) => {
			const currentIndex = minutesItems.findIndex((item) => item.guid === itemToMove.guid);
			const step = moveUp ? -1 : 1;

			return getAvailablePosition(currentIndex, itemToMove.itemType, itemToMove.fields.Indent.Value > 0, currentIndex + step, step);
		},
		[minutesItems],
	);

	const initializeFields = (items, itemsToRefresh = []) => {
		const newFields = [];

		// Header
		newFields.push({
			guid: "toc-header",
			name: "toc-header",
			content: header,
			toolbar: "header",
			deleted: false,
		});
		items &&
			items.forEach((item) => {
				if (!item.deleted) {
					newFields.push({
						guid: item.guid,
						name: item.guid,
						content: item.fields.Name.Value,
						toolbar: item.fields.Closed.Value ? "simpleMO" : "simple",
						isMemberOnly: item.fields.Closed.Value,
						deleted: false,
						refresh: itemsToRefresh.includes(item.guid),
					});

					newFields.push({
						guid: item.guid,
						name: `${item.guid}-text`,
						content: item.fields.Text.Value || "",
						toolbar: item.fields.Closed.Value ? "agendaItemTextMO" : "agendaItemText",
						isMemberOnly: item.fields.Closed.Value,
						deleted: item.fields.Text.Value === null,
						refresh: itemsToRefresh.includes(item.guid),
					});
				}
			});

		// Footer
		newFields.push({
			guid: "toc-footer",
			name: "toc-footer",
			content: footer,
			toolbar: "footer",
			deleted: false,
		});
		setEditorFields(newFields);
	};
	//#endregion

	const addRequestsToSpeak = (filteredItems, headingData, defaultPublicCommentHeading) => {
		const { item, numberOfChildren } = headingData;

		if (item) {
			const agendaItem = getMinutesItem(item);

			requestsToSpeak
				.filter(
					(requestToSpeak) =>
						requestToSpeak.headingGuid === agendaItem.guid ||
						(requestToSpeak.headingGuid.length === 0 &&
							defaultPublicCommentHeading &&
							defaultPublicCommentHeading.guid === agendaItem.guid),
				)
				.forEach((requestToSpeak, index) => {
					const requestToSpeakFilteredItem = filteredItems.filter((filteredItem) => filteredItem.guid === requestToSpeak.guid);
					if (requestToSpeakFilteredItem == null || requestToSpeakFilteredItem.length === 0) {
						filteredItems.push({
							...requestToSpeak,
							order: numberOfChildren + index + 1,
							fields: {
								Consent: {
									Value: agendaItem.fields.Consent.Value,
								},
								PublicComment: {
									Value: agendaItem.fields.PublicComment.Value,
								},
								Closed: {
									Value: agendaItem.fields.Closed.Value,
								},
							},
						});
					}
				});
		}
	};

	const handleEditorInitialized = () => {
		setEditorInitializing(false);
	};

	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();

		dispatch(updateMinutesItems(fieldData, editorFieldsRef.current));
		editor.ui.update();
	};

	const focusOnEditor = (fieldName) => {
		dispatch(setActive(fieldName, true));
	};

	// TODO Find the reason why this gets triggered twice when dispatching - Debounce is only there to prevent 2nd call. Temporary band-aid...
	const handleFocusChange = debounce(
		useCallback((fieldName, fieldElement, editor) => {
			if (fieldName && editor?.ui?.focusTracker?.isFocused) {
				dispatch(setActive(fieldName));
			}
		}, []),
		100,
	);

	const handleSelect = useCallback(
		(e, options) => {
			const updatedSelected = typeof e === "string" ? e : e.target.value;
			dispatch(setSelected(updatedSelected));

			scrollToSelected.current = Boolean(options && options.scroll);

			const minutesItem = minutesItems.find((item) => item.guid === updatedSelected);

			client.liveMeetingHub.updateLiveMeeting(meeting.id, presenting, minutesItem ? minutesItem.agendaItemGuid : updatedSelected);
			if (presenting && meeting.startTimeStamp <= Math.floor(Date.now() / 1000) && !timeStampExists(minutesItem)) {
				dispatch(timestampItem(minutesItem));
			}
		},
		[minutesItems, meeting?.id, meeting?.startTimeStamp, presenting],
	);

	const updateEditorFields = (itemGuid, isDeleting = false) => {
		const newEditorFields = [...editorFieldsRef.current];
		const editorFieldText = newEditorFields.find((field) => field.name === `${itemGuid}-text`);
		if (editorFieldText) {
			editorFieldText.deleted = isDeleting;
			setEditorFields(newEditorFields);
			setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
		}
	};

	const handleAddMotion = useCallback((item) => {
		const newFields = [...editorFieldsRef.current];
		const newItemGuid = uuid();

		const index = newFields.findIndex((field) => {
			return field.name === item.guid;
		});

		newFields.splice(
			index + 2,
			0,
			{
				guid: newItemGuid,
				name: newItemGuid,
				content: "",
				toolbar: "simple",
				deleted: false,
			},
			{
				guid: newItemGuid,
				name: `${newItemGuid}-text`,
				content: item.fields.Text.Value || "",
				toolbar: "itemText",
				deleted: item.fields.Text.Value === null,
			},
		);

		setEditorFields(newFields);

		dispatch(addMotion(item, newItemGuid));
		focusOnEditor(newItemGuid);

		enqueueSnackbar(t("motions.snackbar.added"));
	}, []);

	const addItemText = useCallback((itemGuid) => {
		updateEditorFields(itemGuid);
		if (itemGuid) {
			dispatch(setActive(itemGuid.indexOf("-text") < 0 ? `${itemGuid}-text` : itemGuid, true));
		}
	}, []);

	const removeItemText = useCallback((itemGuid) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === `${itemGuid}-text`);
		setDeletedFieldIdx(idx);
		updateEditorFields(itemGuid, true);
	}, []);

	const handleDeletedFieldIndex = () => {
		setDeletedFieldIdx(-1);
	};

	const handleUndoRedoRoot = (itemGuid, isUndo, isDeletion) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === itemGuid);
		if (idx < 0) return;
		const newFields = [...editorFieldsRef.current];
		newFields[idx].deleted = isUndo ? !isDeletion : isDeletion;
		setEditorFields(newFields);
		setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
	};

	const handleAdoptPublishPreviousMinutes = useCallback((previousMeetingId) => {
		const guid = uuid();

		dispatch(adoptPublishPreviousMinutes(previousMeetingId, guid))
			.then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t("approveMeetingDialog.success.minutes"));
				} else if (res && res.body && res.body.pdf && res.body.pdf.errors) {
					setAdoptPublishError(previousMeetingId);
				}
			})
			.catch(() => {
				setAdoptPublishError(previousMeetingId);
			});
	}, []);

	const findPolicyAttribute = (relationshipGuid) => {
		for (const key in policyAttachments) {
			if (key === relationshipGuid) {
				if (Object.values(policyAttachments[key]).every((data) => data.removed)) {
					return false;
				}
				return true;
			}
		}
		return false;
	};

	const getItems = (minutesItems, droppedId) => {
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;
		let videoExists = false;
		let isPolicyMotion = false;
		if (meeting.purchasedBoxcast && meeting.broadcasts) {
			// future story to allow timestamping against mutiple videos
			if (meeting.broadcasts.length > 0 && meeting.broadcasts[0].youtubeId) {
				videoExists = true;
			}
		} else if (meeting?.youtubeLink && meeting?.youtubeLink != null) {
			videoExists = true;
		}

		const defaultPublicCommentHeading = getDefaultPublicCommentHeading(agendaItems);
		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};
		minutesItems.forEach((item) => {
			filteredItems.push(item);
			if (!item.deleted) {
				if (item.itemType === MinutesItemTypesEnum().HEADING.value) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
					addRequestsToSpeak(filteredItems, previous.heading, defaultPublicCommentHeading);
				} else if (previous.heading.item && item.itemType === MinutesItemTypesEnum().ITEM.value) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});

		addRequestsToSpeak(agendaItems, previous.heading, defaultPublicCommentHeading); // Add requests to the last heading if needed
		const lastIndex = filteredItems.length - 1;
		let elementsIndex = 0;
		let domElements = [];

		// Header

		const headerElement = (
			<LiveMeetingHeader
				afterElementRefSet={afterElementRefSet}
				key={"toc-header"}
				isHeader
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
			/>
		);
		domElements.push(headerElement);
		elementsIndex++;
		filteredItems.map((minutesItem) => {
			if (!minutesItem.deleted) {
				const originalUnfilteredIndex = minutesItems.findIndex((i) => i.guid === minutesItem.guid);
				if (droppedId === minutesItem.guid) {
					domElements.push(
						<Droppable
							dropComponent={DropPlaceholder}
							key={`placeholder-before-${minutesItem.guid}`}
							dropId={`${minutesItem.guid}-placeholder`}
							component="li"
						></Droppable>,
					);
				}
				const item = getMinutesItem(minutesItem);
				isConsentHeading = minutesItem && minutesItem.fields && minutesItem.fields.Consent && minutesItem.fields.Consent.Value;
				isPublicCommentHeading =
					(minutesItem && minutesItem.fields && minutesItem.fields.PublicComment && minutesItem.fields.PublicComment.Value) ||
					(item && item.fields && item.fields.PublicComment && item.fields.PublicComment.Value);
				isMemberOnlyHeading =
					(item && item.fields && item.fields.Closed.Value) || (minutesItem && minutesItem.fields && minutesItem.fields.Closed.Value);
				isPolicyMotion = minutesItem.itemType === 8 && findPolicyAttribute(minutesItem?.attributes?.relationshipGuid);
				let element;
				if (typeof minutesItem.topic === "string") {
					// Request to speak
					element = (
						<LiveMeetingRequestToSpeak
							key={minutesItem.guid}
							requestToSpeak={minutesItem}
							isMemberOnlyHeading={isMemberOnlyHeading}
							isConsentHeading={isConsentHeading}
							isPublicCommentHeading={isPublicCommentHeading}
							addBottomBorder={originalUnfilteredIndex === lastIndex}
							selected={selected}
							handleSelect={handleSelect}
							isMinutesV2
						/>
					);
				} else {
					const parent = findItemByID(minutesItem.attributes.relationshipGuid, minutesItems);
					const editorFieldText = editorFieldsRef.current.find((field) => field.name === `${minutesItem.guid}-text`);
					const policyData = [];
					if (multiplePolicyData) {
						for (const key in multiplePolicyData) {
							if (key === minutesItem.attributes.relationshipGuid) {
								Object.values(multiplePolicyData[key]).forEach((attachment) => {
									if (!attachment.removed) {
										policyData.push(attachment);
									}
								});
								break;
							}
						}
					}

					element = (
						<LiveMeetingItem
							canDrag={hasMeetingStarted}
							canDrop={hasMeetingStarted}
							key={minutesItem.guid}
							item={minutesItem}
							isClosedMeeting={meeting.closed}
							isMemberOnlyHeading={isMemberOnlyHeading}
							isConsentHeading={isConsentHeading}
							isPublicCommentHeading={isPublicCommentHeading}
							isHeading={minutesItem.itemType === MinutesItemTypesEnum().HEADING.value}
							isItem={minutesItem.itemType === MinutesItemTypesEnum().ITEM.value}
							isResolution={minutesItem.itemType === MinutesItemTypesEnum().RESOLUTION.value}
							isHeadingAction={parent && parent.itemType === MinutesItemTypesEnum().HEADING.value}
							isSubHeading={Boolean(minutesItem.attributes.relationshipGuid)}
							addBottomBorder={originalUnfilteredIndex === lastIndex}
							videoExists={videoExists}
							meeting={meeting}
							rollCall={rollCall}
							otherRollCallTypes={otherRollCallTypes}
							additionalUsers={additionalUsers}
							minutesItems={minutesItems}
							menuAnchor={menuAnchor.guid === item.guid ? menuAnchor : undefined}
							handleMenu={handleMenu}
							addItemMenuOptions={addItemMenuOptions}
							selected={
								selected &&
								(selected.startsWith(minutesItem.guid) ||
									minutesItem.attachments?.find((attachment) => selected.startsWith(attachment.guid)))
									? selected
									: undefined
							}
							votingData={votingData?.itemInProgress?.guid === minutesItem.guid ? votingData : undefined}
							onlineVoters={onlineVoters}
							votingSettings={votingSettings}
							adoptUpdating={adoptUpdating}
							presenting={presenting}
							active={active?.indexOf(minutesItem.guid) >= 0 ? active : undefined}
							policyData={policyData.length > 0 ? policyData : undefined}
							handleSelect={handleSelect}
							elementsRef={elementsRef}
							elementsIndex={elementsIndex}
							removeText={removeItemText}
							addText={addItemText}
							editorFieldTextDeleted={editorFieldText?.deleted}
							forceUpdate={minutesItem.forceUpdate}
							showSignIn={showSignIn}
							handleUpdateMotion={handleUpdateMotion}
							handleAddMotion={handleAddMotion}
							adoptPublishPreviousMinutes={handleAdoptPublishPreviousMinutes}
							isPolicyMotion={isPolicyMotion}
							signalRClient={client}
							afterElementRefSet={afterElementRefSet}
							showMenuOptions={menuAnchor.guid === item.guid}
							menuOptions={getItemOptions}
							showMenu
						/>
					);
					elementsIndex += 2;
				}

				// We now always create the text element, it is just hidden or shown sometimes
				domElements.push(element);
				return element || <div key={`item${minutesItem.guid}`}>{minutesItem.fields.Name.Value}</div>;
			}
		});

		if (droppedId === tocFooterDropId) {
			domElements.push(
				<Droppable
					dropComponent={DropPlaceholder}
					key={`placeholder-before-${tocFooterDropId}`}
					dropId={tocFooterDropId}
					component="li"
				></Droppable>,
			);
		}

		// Footer
		const footerElement = (
			<LiveMeetingHeader
				afterElementRefSet={afterElementRefSet}
				key={"toc-footer"}
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
			/>
		);
		domElements.push(footerElement);
		elementsIndex++;

		// Initialize the elements ref status
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status = [];
			for (let index = 0; index <= elementsIndex; index++) {
				elementsRefStatus.current[index] = false;
			}
		}

		return domElements;
	};

	useEffect(() => {
		if (window.editor) {
			handleFieldChange(window.editor);
		}
	}, [triggerEditorChange]);

	useEffect(() => {
		if (active && focus) {
			focusEditor(active);
		} else {
			scrollToActive(active, activeField);
		}
	}, [active, focus, activeField]);

	useEffect(() => {
		setUnpublishedPolicyAttachments(minutesItems);
	}, [minutesItems]);

	useEffect(() => {
		if (minutesItems.length > 0 && Object.keys(policyAttachments).length > 0) {
			minutesItems.forEach((item) => {
				if (policyAttachments[item.guid]) {
					setRemovePropertyForAttachments(item.attachments);
				}
			});
		}
	}, [[...minutesItems]]);

	useEffect(() => {
		const newFields = [];

		// Header
		newFields.push({
			guid: "toc-header",
			name: "toc-header",
			content: header,
			toolbar: "minutesHeader",
			deleted: false,
		});
		minutesItems.forEach((item) => {
			if (!item.deleted) {
				newFields.push({
					guid: item.guid,
					name: item.guid,
					content: item.fields.Name.Value,
					toolbar: item.fields.Closed.Value ? "simpleMO" : "simple",
					isMemberOnly: item.fields.Closed.Value,
					deleted: false,
				});

				newFields.push({
					guid: item.guid,
					name: `${item.guid}-text`,
					content: item.fields.Text.Value || "",
					toolbar: item.fields.Closed.Value ? "minutesItemTextMO" : "minutesItemText",
					isMemberOnly: item.fields.Closed.Value,
					deleted: item.fields.Text.Value === null,
				});
			}
		});

		// Footer
		newFields.push({
			guid: "toc-footer",
			name: "toc-footer",
			content: footer,
			toolbar: "header",
			deleted: false,
		});
		setEditorFields(newFields);
	}, [minutesItems?.length]);

	useEffect(() => {
		let attachmentData = {};
		if (policyAttachmentsMinutes && policyAttachmentsMinutes.length > 0) {
			attachmentData = setSourcePolicyAttachments(policyAttachmentsMinutes);
			attachmentData && setPolicyAttachments((prev) => ({ ...prev, ...attachmentData }));
		}
	}, [policyAttachmentsMinutes]);

	useEffect(() => {
		if (policyAttachments) {
			dispatch({
				type: GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED,
				payload: policyAttachments,
			});
		}
	}, [policyAttachments]);

	useEffect(() => {
		if (editorScrollableContainer.current) {
			// Calculate the offset from the top of the page
			let offsetTop = 0;
			let offsetParent = editorScrollableContainer.current;
			while (offsetParent) {
				offsetTop += offsetParent.offsetTop;
				offsetParent = offsetParent.offsetParent;
			}

			setEditorDyamicHeightOffset(offsetTop);
		}
	});

	const handleMenu = useCallback((e, guid, options) => {
		setMenuAnchor(e ? { ...options, anchor: e.currentTarget, guid } : {});
	}, []);

	const setUnpublishedPolicyAttachments = (minutesItems) => {
		let attachmentData = {};
		minutesItems.forEach((item) => {
			if (item.attachments && item.attachments.length > 0) {
				let itemData = setSourcePolicyAttachments(item.attachments);
				attachmentData = { ...attachmentData, ...itemData };
			}
		});
		attachmentData && setPolicyAttachments((prev) => ({ ...prev, ...attachmentData }));
	};

	const setRemovePropertyForAttachments = (attachments) => {
		let tempObj = policyAttachments;
		attachments.forEach((attachment) => {
			let parentObj = tempObj[attachment.itemGuid];
			if (parentObj[attachment.guid]) {
				if (attachment.removed) {
					parentObj[attachment.guid].removed = true;
				} else {
					parentObj[attachment.guid].removed = false;
				}
			}
		});
		setPolicyAttachments(tempObj);
	};

	const setSourcePolicyAttachments = (policyAttachmentsMinutes) => {
		let policyData = {};
		policyAttachmentsMinutes.forEach((attachment) => {
			let tempObj = policyAttachments[attachment.itemGuid] || {};
			if (attachment.isPolicy && attachment?.sourcePolicyGuid) {
				let key = attachment["guid"];
				let tempArray = policyData[attachment.itemGuid] || {};
				policyData[attachment.itemGuid] = { ...tempArray, ...tempObj, [key]: attachment };
			}
		});
		return policyData;
	};

	if (!minutesItems || !agendaItems || !meeting) {
		return <CircularProgressIndicator />;
	}

	return (
		<>
			{editorInitializing && <CircularProgressIndicator />}
			<div
				className={clsx("flex", "direction-column", "agenda-editor-content", {
					["agenda-editor-content-xs"]: !widthUpSm,
					[classes.editorContentSmUp]: widthUpSm,
					//[classes.editorFieldHide]: editorInitializing,
				})}
				ref={editorScrollableContainer}
			>
				<span id="cont" />
				<Container id={"new-editor-toc-header"} maxWidth="lg" className={classes.contentContainer}>
					<DndContext
						sensors={sensors}
						collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
						onDragStart={handleDragStart}
						onDragMove={handleDragMove}
						onDragEnd={handleDragEnd}
						onDragCancel={handleDragCancel}
					>
						<List component="ul" disablePadding classes={{ root: "agenda-items-container" }} id="item-list">
							{editorFields.length > 0 && getItems(minutesItems, droppedId)}
						</List>
						{createPortal(
							<DragOverlay
								style={{ height: `${dragDimensionsRef.current?.height || 50}px`, width: `${dragDimensionsRef.current?.width || 500}px` }}
							>
								<DragPresentation ref={dragRef}>{getDragComponent()}</DragPresentation>
							</DragOverlay>,
							document.body,
						)}
					</DndContext>
				</Container>
				{editorFields.length > 0 && elementsRef.current.length > 0 && (
					<SingletonEditor
						fields={editorFields}
						fieldsRefs={elementsRef}
						editorToolbarRef={editorToolbarRef}
						fieldRefsSet={elementRefsSet}
						queueFileUploads={queueFileUploads}
						addPolicy={addPolicy}
						addGoals={addGoals}
						invalidFileExtension={invalidFileExtension}
						onFieldChange={handleFieldChange}
						onEditorInitialized={handleEditorInitialized}
						onFocusChange={handleFocusChange}
						onUndoRedoRoot={handleUndoRedoRoot}
						onDeletedFieldIndex={handleDeletedFieldIndex}
						deletedFieldIdx={deletedFieldIdx}
						meetingId={meeting.id}
						features={{
							id: "MOA",
							label: t("inlineFile.features.MOA.featureLabel"),
							className: "closed",
							anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
							tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
						}}
					/>
				)}
				{adoptPublishError > 0 && (
					<AdoptPublishErrorDialog
						meetingId={adoptPublishError}
						handleCancel={() => {
							setAdoptPublishError(null);
						}}
					/>
				)}
			</div>
		</>
	);
};

export default MinutesEditor;
