import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { infoColor } from "atlas/assets/jss/shared";

const searchAndTreeDialogStyle = () => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
		"& .MuiDialogContent-root": {
			padding: "0",
		},
	},
	content: {
		display: "flex",
		flexDirection: "column",
		flexShrink: "1",
		gap: "16px",
		"& > div:not([data-search])": {
			paddingInline: "24px",
		},
		"& > div[data-search]": {
			paddingLeft: "8px",
		},
	},
	tabs: {
		"& .MuiTab-root": {
			...typographyStyle.tab,
			color: infoColor,
			minWidth: "auto",
			minHeight: "auto",
			height: "40px",
			padding: "8px 0",
			margin: "0 16px",
			"&.Mui-selected": {
				...typographyStyle.tab,
				color: infoColor,
			},
		},
		"& .MuiTabs-indicator": {
			backgroundColor: infoColor,
		},
	},
	searchContainerNoTabs: {
		paddingTop: "16px",
	},
	searchContainerLoading: {
		marginTop: "16px",
	},
	dataContainer: {
		flexGrow: "1",
		overflow: "auto",
		paddingBottom: "16px",
		maxHeight: "250px",
	},
});

export default searchAndTreeDialogStyle;
