import {
	GET_MEETING_TEMPLATES_PENDING,
	GET_MEETING_TEMPLATES_FULFILLED,
	GET_MEETING_TEMPLATE_PENDING,
	GET_MEETING_TEMPLATE_FULFILLED,
	GET_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING,
	GET_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED,
	GET_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING,
	GET_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED,
	UPDATE_MEETING_TEMPLATE,
	UPDATE_MEETING_TEMPLATE_AGENDA_ITEMS,
	UPDATE_MEETING_TEMPLATE_AGENDA_PUBLIC_RELEASE_DELAY,
	UPDATE_MEETING_TEMPLATE_AGENDA_SHOW_CONFIDENTIAL_ITEM_LABEL,
	UPDATE_MEETING_TEMPLATE_AGENDA_HIDE_RECOMMENDATIONS_ON_PUBLIC,
	UPDATE_MEETING_TEMPLATE_MINUTES_ITEMS,
	ADD_MEETING_TEMPLATE_AGENDA_MEETING_ITEM,
	DELETE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM,
	REORDER_MEETING_TEMPLATE_AGENDA_ITEMS,
	UPDATE_MEETING_TEMPLATE_VOTING_ROLL_CALL,
	PERSIST_MEETING_TEMPLATE_PENDING,
	PERSIST_MEETING_TEMPLATE_FULFILLED,
	PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING,
	PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED,
	PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED,
	PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING,
	PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED,
	PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED,
	CLEAR_MEETING_TEMPLATE_STATUS,
	SET_ACTIVE,
	SET_CUSTOM_NUMBERING_OPTION,
	UPDATE_MINUTES_INCLUDES_MINUTES_ATTACHMENT,
	UPDATE_SHOW_CLOSED_ITEM_TITLE,
	UPDATE_ALWAYS_USE_RECORDED_VOTE,
	UPDATE_PUBLISHED_DRAFT_MINUTES,
	UPDATE_SIGNATURE_PENDING,
	UPDATE_SIGNATURE_FULFILLED,
} from "./types";
import { addMinutes, format } from "date-fns";
import request from "superagent";
import { API_HOST } from "config/env";

export const getMeetingTemplates = () => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: GET_MEETING_TEMPLATES_PENDING,
			payload: request
				.get(`${API_HOST}/api/meetingtypes?adminOnly=true`)
				.withCredentials()
				.then((response) => response.body)
				.then((json) => {
					dispatch({
						type: GET_MEETING_TEMPLATES_FULFILLED,
						payload: json,
					});
					resolve(json);
				})
				.catch((exception) => {
					reject("Error getting agenda items");
					// TODO Create exception handlers
					// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				}),
		}),
	);
};

export const clearMeetingTemplates = () => (dispatch) => {
	dispatch({
		type: GET_MEETING_TEMPLATES_FULFILLED,
		payload: undefined,
	});
};

export const getMeetingTemplate = (id) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: GET_MEETING_TEMPLATE_PENDING,
			payload: request
				.get(`${API_HOST}/api/meetingtype/${id}`)
				.withCredentials()
				.then((response) => response.body)
				.then((json) => {
					dispatch({
						type: GET_MEETING_TEMPLATE_FULFILLED,
						payload: json,
					});
					resolve(json);
				})
				.catch((exception) => {
					reject("Error getting meeting template");
					// TODO Create exception handlers
					// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				}),
		}),
	);
};

export const getMeetingTemplateAgendaItems = (id) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: GET_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING,
			payload: request
				.get(`${API_HOST}/api/meetingtype/${id}/agendaitems`)
				.withCredentials()
				.then((response) => response.body)
				.then((json) => {
					dispatch({
						type: GET_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED,
						payload: { ...json, id },
					});
					resolve(json);
				})
				.catch((exception) => {
					reject("Error getting agenda items");
				}),
		}),
	);
};

export const getMeetingTemplateMinutesItems = (id) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: GET_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING,
			payload: request
				.get(`${API_HOST}/api/meetingtype/${id}/minutesitems`)
				.withCredentials()
				.then((response) => response.body)
				.then((json) => {
					dispatch({
						type: GET_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED,
						payload: { ...json, id },
					});
					resolve(json);
				})
				.catch((exception) => {
					reject("Error getting minutes items");
				}),
		}),
	);
};

export const setMeetingTemplate = (meetingTemplate) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE,
		payload: meetingTemplate,
	});

export const setMeetingTemplateAgendaItems = (fieldData, editorFields) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE_AGENDA_ITEMS,
		fieldData,
		editorFields,
	});

export const setMeetingTemplateAgendaPublicReleaseDelay = (publicReleaseDelayData) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE_AGENDA_PUBLIC_RELEASE_DELAY,
		payload: publicReleaseDelayData,
	});

export const setMeetingTemplateAgendaShowConfidentialLabel = (showClosedItemDescriptionData) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE_AGENDA_SHOW_CONFIDENTIAL_ITEM_LABEL,
		payload: showClosedItemDescriptionData,
	});

export const setMeetingTemplateAgendaHideRecommendations = (hideRecommendationsOnPublic) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE_AGENDA_HIDE_RECOMMENDATIONS_ON_PUBLIC,
		payload: Boolean(hideRecommendationsOnPublic),
	});

export const setMeetingTemplateMinutesItems = (fieldData, editorFields) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE_MINUTES_ITEMS,
		fieldData,
		editorFields,
	});

export const addMeetingItem = (sourceItem, itemType, subHeading, newItemGuid, itemsInsertIndex, parentItem) => (dispatch) =>
	dispatch({
		type: ADD_MEETING_TEMPLATE_AGENDA_MEETING_ITEM,
		payload: {
			sourceItem,
			itemType,
			subHeading,
			newItemGuid,
			itemsInsertIndex,
			parentItem,
		},
	});

export const deleteMeetingItem = (guid) => (dispatch) =>
	dispatch({
		type: DELETE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM,
		payload: guid,
	});

export const reorderMeetingTemplateAgendaItems = (reorderedItems) => (dispatch) =>
	dispatch({
		type: REORDER_MEETING_TEMPLATE_AGENDA_ITEMS,
		payload: reorderedItems,
	});

export const setMeetingTemplateVotingAndRollCall = (votingAndRollCall) => (dispatch) =>
	dispatch({
		type: UPDATE_MEETING_TEMPLATE_VOTING_ROLL_CALL,
		payload: votingAndRollCall,
	});

export const setMinutesIncludesMinutesAttachment = (minutesIncludesMinutesAttachment) => (dispatch) =>
	dispatch({
		type: UPDATE_MINUTES_INCLUDES_MINUTES_ATTACHMENT,
		payload: minutesIncludesMinutesAttachment,
	});

export const setShowClosedItemTitle = (closedItemTitle) => (dispatch) =>
	dispatch({
		type: UPDATE_SHOW_CLOSED_ITEM_TITLE,
		payload: closedItemTitle,
	});

export const setAlwaysUseRecordedVote = (alwaysUseRecordedVote) => (dispatch) =>
	dispatch({
		type: UPDATE_ALWAYS_USE_RECORDED_VOTE,
		payload: alwaysUseRecordedVote,
	});

export const setPublishedDraftMinutes = (onPublishShowDraftMinutes) => (dispatch) =>
	dispatch({
		type: UPDATE_PUBLISHED_DRAFT_MINUTES,
		payload: onPublishShowDraftMinutes,
	});

export const setMinutesSignature = (signature) => (dispatch, getState) => {
	const state = getState();
	let payload = {
		signatures: signature,
	};
	const {
		meetingTemplatesReducer: { meetingTemplate },
	} = state;

	return new Promise((resolve, reject) => {
		dispatch({
			type: UPDATE_SIGNATURE_PENDING,
			payload: request
				.put(`${API_HOST}/api/meetingtype/${meetingTemplate.id}/minutesitems`)
				.withCredentials()
				.send(payload)
				.then((response) => {
					const { status } = response;
					if (status === 200) {
						dispatch({
							type: UPDATE_SIGNATURE_FULFILLED,
							payload: signature,
						});
						resolve(true);
					} else {
						resolve(false);
					}
				})

				.catch((err) => {
					reject(err);
				}),
		});
	});
};
export const persistMeetingTemplate = (progressGuid) => (dispatch, getState) => {
	const state = getState();
	const {
		meetingTemplatesReducer: { meetingTemplate },
	} = state;

	// Update the start and end time
	const newStartTime = new Date(
		1970,
		1,
		1,
		meetingTemplate.period && meetingTemplate.period === 1
			? meetingTemplate.hour === 12
				? 0
				: meetingTemplate.hour
			: meetingTemplate.hour === 12
				? meetingTemplate.hour
				: meetingTemplate.hour + 12,
		meetingTemplate.minute * 60,
		0,
	);
	meetingTemplate.startTime = format(newStartTime, "HH:mm:ss");
	meetingTemplate.endTime =
		meetingTemplate.duration > 0 ? format(addMinutes(newStartTime, meetingTemplate.duration * 60), "HH:mm:ss") : null;
	meetingTemplate.progressGuid = progressGuid;

	return new Promise((resolve, reject) =>
		dispatch({
			type: PERSIST_MEETING_TEMPLATE_PENDING,
			payload: request
				.put(`${API_HOST}/api/meetingtype/${meetingTemplate.id}`)
				.withCredentials()
				.send(meetingTemplate)
				.then((response) => {
					if (response.status === 200) {
						dispatch({
							type: PERSIST_MEETING_TEMPLATE_FULFILLED,
							payload: response.body,
						});
						resolve(true);
					} else {
						reject(false);
					}
				}),
		}),
	);
};

export const persistMeetingTemplateAgendaItems = (t, dateFormat) => (dispatch, getState) => {
	const state = getState();
	const {
		meetingTemplatesReducer: {
			meetingTemplate,
			agenda: { persistObject },
		},
	} = state;

	return new Promise((resolve, reject) => {
		if (
			persistObject.header ||
			persistObject.footer ||
			(persistObject.publicReleaseDelay >= -1 && persistObject.publicReleaseDelay <= 7) ||
			persistObject.showClosedItemDescription != null ||
			(persistObject.items && persistObject.items.length > 0) ||
			(persistObject.itemIdsToDelete && persistObject.itemIdsToDelete.length > 0) ||
			persistObject.customNumbering
		) {
			dispatch({
				type: PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING,
				payload: request
					.put(`${API_HOST}/api/meetingtype/${meetingTemplate.id}/agendaitems`)
					.withCredentials()
					.timeout({
						response: 300000, // Wait 30 seconds for the server to start sending,
						deadline: 350000, // but allow 35 seconds for the data to finish sending.
					})
					.send(persistObject)
					.then((response) => {
						if (response.status === 200) {
							dispatch({
								type: PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED,
								t,
								dateFormat,
								clearStatus: () =>
									dispatch({
										type: CLEAR_MEETING_TEMPLATE_STATUS,
									}),
							});
							resolve(true);
						} else {
							dispatch({
								type: PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED,
								t,
								persistObject,
							});
							resolve(false);
						}
					})
					.catch((err) => {
						if (err.status !== 403) {
							dispatch({
								type: PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED,
								t,
								persistObject,
							});
						}
						reject(err);
					}),
				t,
			});
		} else {
			dispatch({
				type: PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING,
				noChanges: true,
				t,
			});
			resolve(true);
		}
	});
};

export const persistMeetingTemplateMinutesItems = (t, dateFormat) => (dispatch, getState) => {
	const state = getState();
	const {
		meetingTemplatesReducer: {
			meetingTemplate,
			minutes: { persistObject },
		},
	} = state;

	return new Promise((resolve, reject) => {
		if (
			persistObject.header ||
			persistObject.footer ||
			(persistObject.items && persistObject.items.length > 0) ||
			(persistObject.itemIdsToDelete && persistObject.itemIdsToDelete.length > 0) ||
			persistObject.votingAndRollCall ||
			persistObject.includeMinutesAttachments !== null ||
			persistObject.showClosedItemTitle != null ||
			persistObject.alwaysUseRecordedVote != null ||
			persistObject.onPublishShowDraftMinutes != null
		) {
			dispatch({
				type: PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING,
				payload: request
					.put(`${API_HOST}/api/meetingtype/${meetingTemplate.id}/minutesitems`)
					.withCredentials()
					.timeout({
						response: 300000, // Wait 30 seconds for the server to start sending,
						deadline: 350000, // but allow 35 seconds for the data to finish sending.
					})
					.send(persistObject)
					.then((response) => {
						const {
							status,
							body: { rollCallMapping, signaturesSaved },
						} = response;
						if (status === 200) {
							dispatch({
								type: PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED,
								t,
								dateFormat,
								rollCallMapping,
								signaturesSaved,
								clearStatus: () =>
									dispatch({
										type: CLEAR_MEETING_TEMPLATE_STATUS,
									}),
							});
							resolve(true);
						} else {
							dispatch({
								type: PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED,
								t,
								persistObject,
							});
							resolve(false);
						}
					})
					.catch((err) => {
						if (err.status !== 403) {
							dispatch({
								type: PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED,
								t,
								persistObject,
							});
						}
						reject(err);
					}),
				t,
			});
		} else {
			dispatch({
				type: PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING,
				noChanges: true,
				t,
			});
			resolve(true);
		}
	});
};

export const setActive = (guid, focus, field) => (dispatch) =>
	dispatch({
		type: SET_ACTIVE,
		payload: {
			guid,
			focus,
			field,
		},
	});

export const setCustomNumberingOption = (customNumbering) => (dispatch) => {
	dispatch({
		type: SET_CUSTOM_NUMBERING_OPTION,
		payload: { customNumbering },
	});
};
