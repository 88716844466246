import React, { useState } from "react";
import Box from "@mui/material/Box";
import Icon from "atlas/components/Icon/Icon";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	warningContainer: {
		maxWidth: "100%",
		display: "flex",
		alignItems: "center",
		gap: "15px",
		border: "2px solid #EAA14B",
		borderRadius: "4px",
		minHeight: "48px",
		padding: "5px 5px 5px 0px",
	},
	memberOnlyWarningContainer: {
		maxWidth: "100%",
		display: "flex",
		gap: "15px",
		border: "2px solid #EAA14B",
		borderRadius: "4px",
		minHeight: "48px",
		padding: "5px 5px 5px 0px",
	},
	dismissButton: {
		"& .MuiButtonBase-root": {
			marginTop: "0px",
		},
	},
}));

const Warning = (props) => {
	const { warnings } = props;

	const [dismissed, setDismissed] = useState(false);
	const classes = useStyles();

	const handleDismiss = () => {
		setDismissed(true);
	};

	const renderGeneralWarning = (text, index) => (
		<Box key={index} sx={{ paddingTop: "30px" }}>
			<Box className={classes.warningContainer}>
				<Box sx={{ paddingLeft: "15px", paddingTop: "5px" }}>
					<Icon name="warning-icon" />
				</Box>
				<Box aria-label={text}>
					<div dangerouslySetInnerHTML={{ __html: text }} />
				</Box>
			</Box>
		</Box>
	);

	const renderMemberOnlyWarning = (text, index) => (
		<Box key={index} sx={{ paddingTop: "30px" }}>
			<Box className={classes.memberOnlyWarningContainer}>
				<Box sx={{ paddingLeft: "15px", paddingTop: "15px" }}>
					<Icon name="warning-icon" />
				</Box>
				<Box aria-label={text} style={{ overflowY: "auto", maxHeight: "90px", padding: "12px 0", width: "100%" }}>
					<div dangerouslySetInnerHTML={{ __html: text }} />
				</Box>
				<Box sx={{ fontWeight: "600", fontSize: "32px", fontColor: "#455D82", maxWidth: "80px" }}>
					<Button style={{ marginTop: "0px", padding: "0 10px" }} onClick={handleDismiss}>
						Dismiss
					</Button>
				</Box>
			</Box>
		</Box>
	);

	const getWarnings = () => {
		if (Array.isArray(warnings)) {
			return warnings.map((text, index) => renderGeneralWarning(text, index));
		}

		return Object.keys(warnings).map((key) => {
			const warningMessages = warnings[key];
			if (key === "generalWarning") {
				return warningMessages.map((text, index) => renderGeneralWarning(text, index));
			}
			if (key === "memberOnlyWarning" && !dismissed) {
				return warningMessages.map((text, index) => renderMemberOnlyWarning(text, index));
			}
			return null;
		});
	};

	return <>{getWarnings()}</>;
};

export default Warning;
