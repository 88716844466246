/* eslint-disable no-param-reassign */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthDown } from "atlas/utils/useWidth";
import TOCHeading from "./TOCHeading";
import { StaffUserContext } from "../PdfViewerNew/PdfWithSideBar";
import TOCItems from "./TOCItems";
import clsx from "clsx";

export const TOP_DROP_PLACEHOLDER = "top";

const TableOfContents = (props) => {
	const {
		items,
		isClosed,
		buttons,
		toggleTableOfContent = () => {},
		telemetryPage,
		selected,
		active,
		setActive,
		isPdfSidebar,
		setDataForAttachmentDocument,
		openGoalsPopup,
		setDataForOutlineItem,
		selectedId,
		showClosedItemDescription,
		isReorderable = false,
		isAgenda = false,
		isMinutes = false,
		renderControl,
		className,
	} = props;

	const { t } = useTranslation();
	const widthDownMd = useWidthDown("md");

	if (!items) {
		return <CircularProgressIndicator />;
	}

	const isStaffUser = useContext(StaffUserContext);
	const showContent = (isMemberOnlySection, isRecommendation) => {
		if (isPdfSidebar) {
			if ((isStaffUser && isMemberOnlySection && isRecommendation) || (isStaffUser && isMemberOnlySection && !showClosedItemDescription)) {
				return false;
			}
			return true;
		}
		return true;
	};

	return (
		<List className={clsx(`toc-list ${className}`)}>
			{!isPdfSidebar && (
				<TOCHeading
					section={{
						guid: "toc-header",
						fields: {
							Indent: { Value: "0" },
							Order: { Value: " " },
							Name: { Value: t("agendaMenu:meetingHeader") },
						},
					}}
					notReallyAnItem
					isSmallDevice={widthDownMd}
					canDrop={isReorderable}
				/>
			)}
			<TOCItems
				outlineItems={items}
				isReorderable={isReorderable}
				showContent={showContent}
				isClosed={isClosed}
				toggleTableOfContent={toggleTableOfContent}
				isPdfSidebar={isPdfSidebar}
				buttons={buttons}
				selected={selected}
				active={active}
				setActive={setActive}
				telemetryPage={telemetryPage}
				setDataForAttachmentDocument={setDataForAttachmentDocument}
				openGoalsPopup={openGoalsPopup}
				setDataForOutlineItem={setDataForOutlineItem}
				selectedId={selectedId}
				isStaffUser={isStaffUser}
				isAgenda={isAgenda}
				isMinutes={isMinutes}
				renderControl={renderControl}
			/>
			{!isPdfSidebar && (
				<TOCHeading
					section={{
						guid: "toc-footer",
						fields: {
							Indent: { Value: "0" },
							Order: { Value: " " },
							Name: { Value: t("agendaMenu:meetingFooter") },
						},
					}}
					notReallyAnItem
					isSmallDevice={widthDownMd}
					canDrop={isReorderable}
				/>
			)}
		</List>
	);
};

export default TableOfContents;
