import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { addFolder } from "redux/policyGovernance/actions";
import { renamePolicyFolder } from "redux/policyGovernance/actions";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";
import ellipsedTruncateText from "utils/ellipsedTruncateText";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
}));

const AddFolderDialog = (props) => {
	const { show = true, id = 0, type, onClose, afterAddFolder, showSignIn, isAddPopup, document, afterRename, isEllipsed = false } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [folderData, setFolderData] = useState({
		code: "",
		title: "",
	});
	const [adding, setAdding] = useState(false);
	const classes = useStyles();
	const onlySpacesRegex = /^\s*$/;

	const handleAddFolder = useCallback(() => {
		setAdding(true);
		dispatch(addFolder(id, type, folderData))
			.then((res) => {
				onClose();
				let title = `${res.code} ${res.title}`;
				displaySuccessNotification(t("policy.successMsg.createSection", { title: title }), dispatch);
				afterAddFolder(res);
			})
			.catch((err) => {
				if (err.status === 500) {
					handleCancel();
					displayErrorNotification(t("policy.errorMsg.createSection", dispatch));
				} else {
					handleCancel();
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
			});
	}, [folderData]);

	const validateInput = (title) => {
		if (title.length < 1 || onlySpacesRegex.test(title)) return true;
	};

	useEffect(() => {
		if (!isAddPopup) {
			setFolderData(document);
		}
	}, [isAddPopup, document]);

	const handleRenameForPolicyFolder = () => {
		setAdding(true);
		dispatch(
			renamePolicyFolder(document.guid, {
				code: folderData.code,
				title: folderData.title,
			}),
		)
			.then((res) => {
				let title = res.title;
				let code = res.code;
				displaySuccessNotification(t("policy.successMsg.renameSection", { code, title }), dispatch);
				onClose();
				Object.assign(document, res);
				afterRename(res);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification(t("policy.errorMsg.renameSection"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("addFolderDialog", { titleForPolicy: "" });
	const title = folderData?.title;
	const dialog = isAddPopup
		? {
				title: i18n.titleForPolicy,
				primaryTitle: t("app:buttons.create"),
				primaryAction: handleAddFolder,
				secondaryTitle: t("app:buttons.cancel"),
				secondaryAction: handleCancel,
		  }
		: {
				title: t("addFolderDialog.titleForRenameDialog", { title }),
				primaryTitle: "Rename",
				primaryAction: handleRenameForPolicyFolder,
				secondaryTitle: t("app:buttons.cancel"),
				secondaryAction: handleCancel,
		  };

	const onChangeFunc = (key, value) => {
		if (key === "title" && value === " ") {
			return;
		}
		setFolderData((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	return (
		<GenericDialog
			ellipsedTruncatedText={ellipsedTruncateText(dialog.title)}
			isEllipsed={isEllipsed}
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={validateInput(folderData.title) || adding}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={adding}
			clickAwayDisabled={adding}
			closeIcon={<Icon name="close" />}
			data-cy="add-folder-dialog"
		>
			<OutlinedInput
				id="code"
				label={t("addFolderDialog.labels.code")}
				placeholder={t("addFolderDialog.placeholders.code")}
				value={folderData.policyCode ? folderData.policyCode : folderData.code}
				onChange={(e) => onChangeFunc("code", e.target.value)}
				noDefaultClassName
				fullWidth
				autoFocus
				size="small"
				data-cy="code"
			/>
			<OutlinedInput
				id="name"
				label={t("addFolderDialog.labels.name")}
				placeholder={t("addFolderDialog.placeholders.name")}
				value={folderData.title}
				onChange={(e) => onChangeFunc("title", e.target.value)}
				noDefaultClassName
				fullWidth
				size="small"
				data-cy="name"
			/>
		</GenericDialog>
	);
};

export default AddFolderDialog;
